// 'use strict';
// (function () {
//   const adLogin = {
//     templateUrl: 'app/auth/login/login.html',
//     controller
//   };

//   controller.$inject = ['$log', 'adalAuthenticationService', '$scope', 'Auth'];

//   function controller($log, ADAL, scope, Auth) {
//     const self = this;

//     self.user = {};

//     self.login = function () {
//       ADAL.login();
//     };

//     $log.log(ADAL);
//     // ADAL.updateDataFromCache().then(data => {
//     //   $log.log(data);
//     // });

//     // can pass data to callback
//     scope.$on('adal:loginSuccess', () => {
//       const adUid = ADAL.userInfo.profile;
//       $log.log(adUid);

//       Auth.$signInWithCustomToken(adUid)
//         .then(firebaseUser => {
//           $log.log('Signed in as:', firebaseUser.uid);
//         }).catch(error => {
//           $log.error('Authentication failed:', error);
//         });
//     });
//   }

//   angular
//     .module('app')
//     .component('adLogin', adLogin);
// })();
"use strict";

var cov_1gmo0dabj9 = function () {
  var path = "/workdir/src/app/common/auth/login/ad-login.js",
      hash = "baa2700018bfef771608de2a3a67d32d3774f3c5",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/workdir/src/app/common/auth/login/ad-login.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();