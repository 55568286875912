'use strict';

var cov_1a8ltglgmi = function () {
  var path = '/workdir/src/services/timesheets.js',
      hash = '6a9f1be9af20cdcbdb01cae8fc02a2bcefe455d7',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/services/timesheets.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 18
        },
        end: {
          line: 5,
          column: 91
        }
      },
      '2': {
        start: {
          line: 7,
          column: 20
        },
        end: {
          line: 9,
          column: 5
        }
      },
      '3': {
        start: {
          line: 11,
          column: 20
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '4': {
        start: {
          line: 18,
          column: 20
        },
        end: {
          line: 18,
          column: 30
        }
      },
      '5': {
        start: {
          line: 19,
          column: 18
        },
        end: {
          line: 19,
          column: 77
        }
      },
      '6': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '7': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 31
        }
      },
      '8': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 28
        }
      },
      '9': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 27
        }
      },
      '10': {
        start: {
          line: 28,
          column: 4
        },
        end: {
          line: 28,
          column: 19
        }
      },
      '11': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 32,
          column: 56
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 33,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'Timesheets',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 21
          }
        },
        loc: {
          start: {
            line: 4,
            column: 33
          },
          end: {
            line: 29,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: 'employees',
        decl: {
          start: {
            line: 17,
            column: 13
          },
          end: {
            line: 17,
            column: 22
          }
        },
        loc: {
          start: {
            line: 17,
            column: 25
          },
          end: {
            line: 26,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 20,
            column: 35
          },
          end: {
            line: 20,
            column: 36
          }
        },
        loc: {
          start: {
            line: 20,
            column: 46
          },
          end: {
            line: 22,
            column: 7
          }
        },
        line: 20
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 22,
            column: 9
          },
          end: {
            line: 22,
            column: 10
          }
        },
        loc: {
          start: {
            line: 22,
            column: 18
          },
          end: {
            line: 24,
            column: 7
          }
        },
        line: 22
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1a8ltglgmi.s[0]++;
(function () {
  cov_1a8ltglgmi.f[0]++;

  // defines function Projects
  function Timesheets($http, $q) {
    cov_1a8ltglgmi.f[1]++;

    var token = (cov_1a8ltglgmi.s[1]++, 'Bearer 8CE9F9EFD6944367A9E3A5289AC02ED3238E824FCEB842328B7186B7E77A1F2B');

    var service = (cov_1a8ltglgmi.s[2]++, {
      employees: employees
    });

    var options = (cov_1a8ltglgmi.s[3]++, {
      headers: {
        Authorization: token
      }
    });

    function employees() {
      cov_1a8ltglgmi.f[2]++;

      var defer = (cov_1a8ltglgmi.s[4]++, $q.defer());
      var url = (cov_1a8ltglgmi.s[5]++, 'https://api.ebillity.com/restservice/thirdparty/employees');
      cov_1a8ltglgmi.s[6]++;
      $http.get(url, options).then(function (success) {
        cov_1a8ltglgmi.f[3]++;
        cov_1a8ltglgmi.s[7]++;

        defer.resolve(success);
      }, function (error) {
        cov_1a8ltglgmi.f[4]++;
        cov_1a8ltglgmi.s[8]++;

        defer.reject(error);
      });
      cov_1a8ltglgmi.s[9]++;
      return defer.promise;
    }

    cov_1a8ltglgmi.s[10]++;
    return service;
  }

  cov_1a8ltglgmi.s[11]++;
  angular.module('app').factory('Timesheets', ['$http', '$q', Timesheets]);
})();