'use strict';

var cov_1fbmrkojyl = function () {
  var path = '/workdir/src/services/files.js',
      hash = 'eb7ff6775b9df88049d817dc0dd0a9d7028243b5',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/services/files.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 73,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 20
        },
        end: {
          line: 7,
          column: 5
        }
      },
      '2': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 19
        }
      },
      '3': {
        start: {
          line: 12,
          column: 20
        },
        end: {
          line: 12,
          column: 30
        }
      },
      '4': {
        start: {
          line: 13,
          column: 25
        },
        end: {
          line: 13,
          column: 73
        }
      },
      '5': {
        start: {
          line: 14,
          column: 19
        },
        end: {
          line: 14,
          column: 40
        }
      },
      '6': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 19,
          column: 9
        }
      },
      '7': {
        start: {
          line: 17,
          column: 25
        },
        end: {
          line: 17,
          column: 80
        }
      },
      '8': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 48
        }
      },
      '9': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 23,
          column: 9
        }
      },
      '10': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 32
        }
      },
      '11': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 41,
          column: 9
        }
      },
      '12': {
        start: {
          line: 26,
          column: 18
        },
        end: {
          line: 26,
          column: 20
        }
      },
      '13': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 39,
          column: 9
        }
      },
      '14': {
        start: {
          line: 29,
          column: 12
        },
        end: {
          line: 29,
          column: 72
        }
      },
      '15': {
        start: {
          line: 30,
          column: 12
        },
        end: {
          line: 30,
          column: 18
        }
      },
      '16': {
        start: {
          line: 32,
          column: 12
        },
        end: {
          line: 32,
          column: 46
        }
      },
      '17': {
        start: {
          line: 33,
          column: 12
        },
        end: {
          line: 33,
          column: 18
        }
      },
      '18': {
        start: {
          line: 35,
          column: 12
        },
        end: {
          line: 35,
          column: 63
        }
      },
      '19': {
        start: {
          line: 36,
          column: 12
        },
        end: {
          line: 36,
          column: 18
        }
      },
      '20': {
        start: {
          line: 38,
          column: 12
        },
        end: {
          line: 38,
          column: 21
        }
      },
      '21': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 26
        }
      },
      '22': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 27
        }
      },
      '23': {
        start: {
          line: 46,
          column: 20
        },
        end: {
          line: 46,
          column: 30
        }
      },
      '24': {
        start: {
          line: 47,
          column: 25
        },
        end: {
          line: 47,
          column: 59
        }
      },
      '25': {
        start: {
          line: 48,
          column: 19
        },
        end: {
          line: 48,
          column: 64
        }
      },
      '26': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 53,
          column: 9
        }
      },
      '27': {
        start: {
          line: 51,
          column: 25
        },
        end: {
          line: 51,
          column: 80
        }
      },
      '28': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 52,
          column: 48
        }
      },
      '29': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 57,
          column: 9
        }
      },
      '30': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 32
        }
      },
      '31': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 66,
          column: 9
        }
      },
      '32': {
        start: {
          line: 60,
          column: 23
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '33': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 63
        }
      },
      '34': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 27
        }
      },
      '35': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 72,
          column: 65
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 73,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'Files',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 16
          }
        },
        loc: {
          start: {
            line: 3,
            column: 43
          },
          end: {
            line: 69,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: 'put',
        decl: {
          start: {
            line: 11,
            column: 13
          },
          end: {
            line: 11,
            column: 16
          }
        },
        loc: {
          start: {
            line: 11,
            column: 28
          },
          end: {
            line: 43,
            column: 5
          }
        },
        line: 11
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 16,
            column: 21
          },
          end: {
            line: 16,
            column: 22
          }
        },
        loc: {
          start: {
            line: 16,
            column: 33
          },
          end: {
            line: 19,
            column: 7
          }
        },
        line: 16
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 21,
            column: 21
          },
          end: {
            line: 21,
            column: 22
          }
        },
        loc: {
          start: {
            line: 21,
            column: 33
          },
          end: {
            line: 23,
            column: 7
          }
        },
        line: 21
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 25,
            column: 18
          },
          end: {
            line: 25,
            column: 19
          }
        },
        loc: {
          start: {
            line: 25,
            column: 27
          },
          end: {
            line: 41,
            column: 7
          }
        },
        line: 25
      },
      '6': {
        name: 'putString',
        decl: {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 45,
            column: 22
          }
        },
        loc: {
          start: {
            line: 45,
            column: 36
          },
          end: {
            line: 68,
            column: 5
          }
        },
        line: 45
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 50,
            column: 21
          },
          end: {
            line: 50,
            column: 22
          }
        },
        loc: {
          start: {
            line: 50,
            column: 33
          },
          end: {
            line: 53,
            column: 7
          }
        },
        line: 50
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 55,
            column: 21
          },
          end: {
            line: 55,
            column: 22
          }
        },
        loc: {
          start: {
            line: 55,
            column: 33
          },
          end: {
            line: 57,
            column: 7
          }
        },
        line: 55
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 59,
            column: 18
          },
          end: {
            line: 59,
            column: 19
          }
        },
        loc: {
          start: {
            line: 59,
            column: 27
          },
          end: {
            line: 66,
            column: 7
          }
        },
        line: 59
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 27,
            column: 8
          },
          end: {
            line: 39,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 30,
            column: 18
          }
        }, {
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 33,
            column: 18
          }
        }, {
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 36,
            column: 18
          }
        }, {
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 38,
            column: 21
          }
        }],
        line: 27
      },
      '1': {
        loc: {
          start: {
            line: 65,
            column: 21
          },
          end: {
            line: 65,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 65,
            column: 21
          },
          end: {
            line: 65,
            column: 39
          }
        }, {
          start: {
            line: 65,
            column: 43
          },
          end: {
            line: 65,
            column: 61
          }
        }],
        line: 65
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0, 0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1fbmrkojyl.s[0]++;
(function () {
  cov_1fbmrkojyl.f[0]++;

  function Files($q, Storage, $log, Users) {
    cov_1fbmrkojyl.f[1]++;

    var service = (cov_1fbmrkojyl.s[1]++, {
      put: put,
      putString: putString
    });

    cov_1fbmrkojyl.s[2]++;
    return service;

    function put(uid, file) {
      cov_1fbmrkojyl.f[2]++;

      var defer = (cov_1fbmrkojyl.s[3]++, $q.defer());
      var storageRef = (cov_1fbmrkojyl.s[4]++, Storage.getStorage(Users.currentUser().$id, uid));
      var task = (cov_1fbmrkojyl.s[5]++, storageRef.$put(file));
      // on progress
      cov_1fbmrkojyl.s[6]++;
      task.$progress(function (snapshot) {
        cov_1fbmrkojyl.f[3]++;

        var progress = (cov_1fbmrkojyl.s[7]++, snapshot.bytesTransferred / snapshot.totalBytes * 100);
        cov_1fbmrkojyl.s[8]++;
        $log.log('Upload is ' + progress + '% done');
      });
      // on complete
      cov_1fbmrkojyl.s[9]++;
      task.$complete(function (snapshot) {
        cov_1fbmrkojyl.f[4]++;
        cov_1fbmrkojyl.s[10]++;

        defer.resolve(snapshot);
      });
      // on error
      cov_1fbmrkojyl.s[11]++;
      task.$error(function (error) {
        cov_1fbmrkojyl.f[5]++;

        var msg = (cov_1fbmrkojyl.s[12]++, '');
        cov_1fbmrkojyl.s[13]++;
        switch (error.code) {
          case 'storage/unauthorized':
            cov_1fbmrkojyl.b[0][0]++;
            cov_1fbmrkojyl.s[14]++;

            msg = 'User does not have permission to access the object.';
            cov_1fbmrkojyl.s[15]++;
            break;
          case 'storage/canceled':
            cov_1fbmrkojyl.b[0][1]++;
            cov_1fbmrkojyl.s[16]++;

            msg = 'User canceled the upload.';
            cov_1fbmrkojyl.s[17]++;
            break;
          case 'storage/unknown':
            cov_1fbmrkojyl.b[0][2]++;
            cov_1fbmrkojyl.s[18]++;

            msg = ' Unknown error occurred, Please try later.';
            cov_1fbmrkojyl.s[19]++;
            break;
          default:
            cov_1fbmrkojyl.b[0][3]++;
            cov_1fbmrkojyl.s[20]++;

            msg = '';
        }
        cov_1fbmrkojyl.s[21]++;
        defer.reject(msg);
      });
      cov_1fbmrkojyl.s[22]++;
      return defer.promise;
    }

    function putString(uid, string) {
      cov_1fbmrkojyl.f[6]++;

      var defer = (cov_1fbmrkojyl.s[23]++, $q.defer());
      var storageRef = (cov_1fbmrkojyl.s[24]++, Storage.getStorage('service', uid));
      var task = (cov_1fbmrkojyl.s[25]++, storageRef.$putString(string, 'data_url', {}));
      // on progress
      cov_1fbmrkojyl.s[26]++;
      task.$progress(function (snapshot) {
        cov_1fbmrkojyl.f[7]++;

        var progress = (cov_1fbmrkojyl.s[27]++, snapshot.bytesTransferred / snapshot.totalBytes * 100);
        cov_1fbmrkojyl.s[28]++;
        $log.log('Upload is ' + progress + '% done');
      });
      // on complete
      cov_1fbmrkojyl.s[29]++;
      task.$complete(function (snapshot) {
        cov_1fbmrkojyl.f[8]++;
        cov_1fbmrkojyl.s[30]++;

        defer.resolve(snapshot);
      });
      // on error
      cov_1fbmrkojyl.s[31]++;
      task.$error(function (error) {
        cov_1fbmrkojyl.f[9]++;

        var errors = (cov_1fbmrkojyl.s[32]++, {
          'storage/unauthorized': 'User does not have permission to access the object.',
          'storage/canceled': 'User canceled the upload.',
          'storage/unknown': 'Unknown error occurred, Please try later.'
        });
        cov_1fbmrkojyl.s[33]++;
        defer.reject((cov_1fbmrkojyl.b[1][0]++, errors[error.code]) || (cov_1fbmrkojyl.b[1][1]++, 'Something broke.'));
      });
      cov_1fbmrkojyl.s[34]++;
      return defer.promise;
    }
  }

  cov_1fbmrkojyl.s[35]++;
  angular.module('app').factory('Files', ['$q', 'Storage', '$log', 'Users', Files]);
})();