'use strict';

var cov_rivcece8c = function () {
  var path = '/workdir/src/app/service/helpers/service-rate-select.component.js',
      hash = 'ad4bc97a6ee9a86db4e085c635987eb2487cab17',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/helpers/service-rate-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 28
        },
        end: {
          line: 14,
          column: 3
        }
      },
      '2': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 42
        }
      },
      '3': {
        start: {
          line: 19,
          column: 17
        },
        end: {
          line: 19,
          column: 21
        }
      },
      '4': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 43,
          column: 6
        }
      },
      '5': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '6': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 27
        }
      },
      '7': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 26,
          column: 9
        }
      },
      '8': {
        start: {
          line: 25,
          column: 10
        },
        end: {
          line: 25,
          column: 34
        }
      },
      '9': {
        start: {
          line: 46,
          column: 2
        },
        end: {
          line: 48,
          column: 55
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 49,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 18,
            column: 11
          },
          end: {
            line: 18,
            column: 21
          }
        },
        loc: {
          start: {
            line: 18,
            column: 38
          },
          end: {
            line: 44,
            column: 3
          }
        },
        line: 18
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 19
          },
          end: {
            line: 21,
            column: 20
          }
        },
        loc: {
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 43,
            column: 5
          }
        },
        line: 21
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 44
          },
          end: {
            line: 22,
            column: 45
          }
        },
        loc: {
          start: {
            line: 22,
            column: 53
          },
          end: {
            line: 27,
            column: 7
          }
        },
        line: 22
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 24,
            column: 8
          },
          end: {
            line: 26,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 8
          },
          end: {
            line: 26,
            column: 9
          }
        }, {
          start: {
            line: 24,
            column: 8
          },
          end: {
            line: 26,
            column: 9
          }
        }],
        line: 24
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_rivcece8c.s[0]++;
(function () {
  cov_rivcece8c.f[0]++;

  var serviceRateSelect = (cov_rivcece8c.s[1]++, {
    bindings: {
      ngModel: '=',
      required: '='
    },
    template: '\n    <select name="" id="" class="form-control" ng-model="$ctrl.ngModel">\n      <option ng-repeat="rate in $ctrl.rates" ng-value="rate" ng-selected="$ctrl.ngModel == rate">{{rate.name}} - {{rate.rate | currency}}</option>\n    </select>  \n    ',
    controller: controller
  });

  cov_rivcece8c.s[2]++;
  controller.$inject = ['ClientSettings'];

  function controller(ClientSettings) {
    cov_rivcece8c.f[1]++;

    var self = (cov_rivcece8c.s[3]++, this);

    cov_rivcece8c.s[4]++;
    self.$onInit = function () {
      cov_rivcece8c.f[2]++;
      cov_rivcece8c.s[5]++;

      ClientSettings.serviceRates().$loaded(function (rates) {
        cov_rivcece8c.f[3]++;
        cov_rivcece8c.s[6]++;

        self.rates = rates;
        cov_rivcece8c.s[7]++;
        if (!self.ngModel) {
          cov_rivcece8c.b[0][0]++;
          cov_rivcece8c.s[8]++;

          self.ngModel = rates[0];
        } else {
          cov_rivcece8c.b[0][1]++;
        }
      });

      // // TODO: form validation required
      // self.rates = [{
      //   description: 'Standard Service Time',
      //   notes: 'Hourly Rate for Service during normal business hours',
      //   rate: 120
      // }, {
      //   description: 'Overtime Service Work',
      //   notes: 'Hourly Rate for Service done after business hours',
      //   rate: 140
      // }, {
      //   description: 'Holiday Service Work',
      //   notes: 'Hourly Rate for Service done during holidays',
      //   rate: 240
      // }];
    };
  }

  cov_rivcece8c.s[9]++;
  angular.module('dc-service').component('serviceRateSelect', serviceRateSelect);
})();