// angular.module('app')
// .directive('fbSrc', ['$log', function ($log) {
//   // Used to embed images stored in Firebase

//   /*
//   Required attributes:
//     fp-src (The name of an image stored in Firebase)
//   */
//   return {
//     link: function (scope, elem, attrs) {
//       var safename = attrs.fpSrc.replace(/\.|\#|\$|\[|\]|-|\//g, "");
//       var dataRef = new Firebase( [scope.firebaseUrl, 'images', safename].join('/') );
//       elem.attr('alt', attrs.fpSrc);
//       dataRef.once('value', function (snapshot) {
//         var image = snapshot.val();
//         if (!image) {
//           $log.log('It appears the image ' + attrs.fpSrc + ' does not exist.');
//         }else{
//           elem.attr('src', image.data);
//         }
//       });
//     },
//     restrict: 'A'
//   };
// }]);
"use strict";

var cov_jxty2j0lf = function () {
  var path = "/workdir/src/directives/fp-src.js",
      hash = "9702d8ffc6fcca8f46818b2e03e6bae9ba9bd334",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/workdir/src/directives/fp-src.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();