'use strict';

var cov_21zfhmob03 = function () {
  var path = '/workdir/src/app/components/project-helpers/project-name.js',
      hash = 'a0951ead4528fe07f58b49c7c7ccb9bebf952a6d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/components/project-helpers/project-name.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 46
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '5': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 26,
          column: 7
        }
      },
      '6': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 25,
          column: 13
        }
      },
      '7': {
        start: {
          line: 23,
          column: 12
        },
        end: {
          line: 23,
          column: 45
        }
      },
      '8': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 24,
          column: 42
        }
      },
      '9': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '10': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '11': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 29
        }
      },
      '12': {
        start: {
          line: 39,
          column: 2
        },
        end: {
          line: 41,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 42,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 40
          },
          end: {
            line: 37,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 22
          },
          end: {
            line: 19,
            column: 23
          }
        },
        loc: {
          start: {
            line: 19,
            column: 38
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 16
          },
          end: {
            line: 22,
            column: 17
          }
        },
        loc: {
          start: {
            line: 22,
            column: 28
          },
          end: {
            line: 25,
            column: 11
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 29,
            column: 21
          },
          end: {
            line: 29,
            column: 22
          }
        },
        loc: {
          start: {
            line: 29,
            column: 27
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 29
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 20,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 20,
            column: 23
          }
        }, {
          start: {
            line: 20,
            column: 27
          },
          end: {
            line: 20,
            column: 54
          }
        }],
        line: 20
      },
      '2': {
        loc: {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }, {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }],
        line: 30
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_21zfhmob03.s[0]++;
(function () {
  cov_21zfhmob03.f[0]++;

  var projectName = (cov_21zfhmob03.s[1]++, {
    bindings: {
      uid: '<'
    },
    template: '\n      <span ng-if="$ctrl.name && !$ctrl.showLink">{{$ctrl.name}}</span>\n      <a ui-sref="app.project({projectID: $ctrl.number})" ng-if="$ctrl.name && $ctrl.showLink"><span>{{$ctrl.name}}</span>\n    ',
    controller: controller
  });

  cov_21zfhmob03.s[2]++;
  controller.$inject = ['Projects', '$attrs'];

  function controller(Projects, $attrs) {
    cov_21zfhmob03.f[1]++;

    var self = (cov_21zfhmob03.s[3]++, this);
    // $onChanges
    cov_21zfhmob03.s[4]++;
    self.$onChanges = function (prev, next) {
      cov_21zfhmob03.f[2]++;
      cov_21zfhmob03.s[5]++;

      if ((cov_21zfhmob03.b[1][0]++, next !== prev) && (cov_21zfhmob03.b[1][1]++, angular.isDefined(self.uid))) {
        cov_21zfhmob03.b[0][0]++;
        cov_21zfhmob03.s[6]++;

        Projects.$getProjectMetadata(self.uid).then(function (metadata) {
          cov_21zfhmob03.f[3]++;
          cov_21zfhmob03.s[7]++;

          self.name = metadata.projectName;
          cov_21zfhmob03.s[8]++;
          self.number = metadata.number;
        });
      } else {
        cov_21zfhmob03.b[0][1]++;
      }
    };
    // $postLink
    cov_21zfhmob03.s[9]++;
    self.$postLink = function () {
      cov_21zfhmob03.f[4]++;
      cov_21zfhmob03.s[10]++;

      if ('withLink' in $attrs) {
        cov_21zfhmob03.b[2][0]++;
        cov_21zfhmob03.s[11]++;

        self.showLink = true;
      } else {
        cov_21zfhmob03.b[2][1]++;
      }
    };
    // $onDestroy
    // self.$onDestroy = () => {
    // };
  }

  cov_21zfhmob03.s[12]++;
  angular.module('app').component('projectName', projectName);
})();