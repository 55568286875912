'use strict';

var cov_12rg0o38yi = function () {
  var path = '/workdir/src/app/common/auth/auth.routes.js',
      hash = '5a0394fa38623d4770bfa39b6e045a47329ec840',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/auth/auth.routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 72,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 14,
          column: 9
        }
      },
      '2': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 38
        }
      },
      '3': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '4': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 57
        }
      },
      '5': {
        start: {
          line: 28,
          column: 19
        },
        end: {
          line: 28,
          column: 51
        }
      },
      '6': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '7': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 77
        }
      },
      '8': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 35,
          column: 37
        }
      },
      '9': {
        start: {
          line: 41,
          column: 33
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '10': {
        start: {
          line: 42,
          column: 19
        },
        end: {
          line: 42,
          column: 65
        }
      },
      '11': {
        start: {
          line: 45,
          column: 28
        },
        end: {
          line: 53,
          column: 5
        }
      },
      '12': {
        start: {
          line: 46,
          column: 19
        },
        end: {
          line: 46,
          column: 52
        }
      },
      '13': {
        start: {
          line: 47,
          column: 21
        },
        end: {
          line: 47,
          column: 51
        }
      },
      '14': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 52,
          column: 11
        }
      },
      '15': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 75
        }
      },
      '16': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 55,
          column: 81
        }
      },
      '17': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 65,
          column: 7
        }
      },
      '18': {
        start: {
          line: 60,
          column: 19
        },
        end: {
          line: 60,
          column: 52
        }
      },
      '19': {
        start: {
          line: 61,
          column: 21
        },
        end: {
          line: 61,
          column: 51
        }
      },
      '20': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 64,
          column: 7
        }
      },
      '21': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 41
        }
      },
      '22': {
        start: {
          line: 68,
          column: 2
        },
        end: {
          line: 71,
          column: 32
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 72,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'routeConfig',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 39
          },
          end: {
            line: 15,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: 'returnTo',
        decl: {
          start: {
            line: 21,
            column: 11
          },
          end: {
            line: 21,
            column: 19
          }
        },
        loc: {
          start: {
            line: 21,
            column: 34
          },
          end: {
            line: 36,
            column: 3
          }
        },
        line: 21
      },
      '3': {
        name: 'run',
        decl: {
          start: {
            line: 39,
            column: 11
          },
          end: {
            line: 39,
            column: 14
          }
        },
        loc: {
          start: {
            line: 39,
            column: 29
          },
          end: {
            line: 66,
            column: 3
          }
        },
        line: 39
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 42,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        },
        loc: {
          start: {
            line: 42,
            column: 19
          },
          end: {
            line: 42,
            column: 65
          }
        },
        line: 42
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 45,
            column: 28
          },
          end: {
            line: 45,
            column: 29
          }
        },
        loc: {
          start: {
            line: 45,
            column: 42
          },
          end: {
            line: 53,
            column: 5
          }
        },
        line: 45
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 50,
            column: 15
          },
          end: {
            line: 50,
            column: 16
          }
        },
        loc: {
          start: {
            line: 50,
            column: 21
          },
          end: {
            line: 52,
            column: 9
          }
        },
        line: 50
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 59,
            column: 7
          },
          end: {
            line: 59,
            column: 8
          }
        },
        loc: {
          start: {
            line: 59,
            column: 21
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 59
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        }, {
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 30,
            column: 4
          },
          end: {
            line: 32,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 4
          },
          end: {
            line: 32,
            column: 5
          }
        }, {
          start: {
            line: 30,
            column: 4
          },
          end: {
            line: 32,
            column: 5
          }
        }],
        line: 30
      },
      '2': {
        loc: {
          start: {
            line: 42,
            column: 27
          },
          end: {
            line: 42,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 42,
            column: 27
          },
          end: {
            line: 42,
            column: 37
          }
        }, {
          start: {
            line: 42,
            column: 41
          },
          end: {
            line: 42,
            column: 64
          }
        }],
        line: 42
      },
      '3': {
        loc: {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }, {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }],
        line: 62
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_12rg0o38yi.s[0]++;
(function () {
  cov_12rg0o38yi.f[0]++;

  function routeConfig($stateProvider) {
    cov_12rg0o38yi.f[1]++;
    cov_12rg0o38yi.s[1]++;

    $stateProvider.state('auth', {
      component: 'auth',
      url: '/auth',
      abstract: true
    }).state('auth.login', {
      url: '/login',
      component: 'login',
      resolve: { returnTo: returnTo }
    });
  }

  // RESOLVE FUNCTION
  cov_12rg0o38yi.s[2]++;
  returnTo.$inject = ['$transition$'];

  // Builds returnTo resolve for redirect hook
  function returnTo($transition$) {
    cov_12rg0o38yi.f[2]++;
    cov_12rg0o38yi.s[3]++;

    if ($transition$.redirectedFrom() !== null) {
      cov_12rg0o38yi.b[0][0]++;
      cov_12rg0o38yi.s[4]++;

      // User was redirected to the login state
      // Return the original attempted target state
      return $transition$.redirectedFrom().targetState();
    } else {
      cov_12rg0o38yi.b[0][1]++;
    }

    var $state = (cov_12rg0o38yi.s[5]++, $transition$.router.stateService);
    // User was not redirected
    cov_12rg0o38yi.s[6]++;
    if ($transition$.from().name !== '') {
      cov_12rg0o38yi.b[1][0]++;
      cov_12rg0o38yi.s[7]++;

      return $state.target($transition$.from(), $transition$.params('from'));
    } else {
      cov_12rg0o38yi.b[1][1]++;
    }

    // Just return them to the home state
    cov_12rg0o38yi.s[8]++;
    return $state.target('app.main');
  }

  // RUN AUTH HOOK FUNCTION
  function run($transitions) {
    cov_12rg0o38yi.f[3]++;

    // Checks for auth required
    var requiresAuthCriteria = (cov_12rg0o38yi.s[9]++, {
      to: function to(state) {
        cov_12rg0o38yi.f[4]++;
        cov_12rg0o38yi.s[10]++;
        return Boolean((cov_12rg0o38yi.b[2][0]++, state.data) && (cov_12rg0o38yi.b[2][1]++, state.data.authRequired));
      }
    });

    cov_12rg0o38yi.s[11]++;
    var redirectToLogin = function redirectToLogin(transition) {
      cov_12rg0o38yi.f[5]++;

      var Auth = (cov_12rg0o38yi.s[12]++, transition.injector().get('Auth'));
      var $state = (cov_12rg0o38yi.s[13]++, transition.router.stateService);
      cov_12rg0o38yi.s[14]++;
      return Auth.requireAuthentication().catch(function () {
        cov_12rg0o38yi.f[6]++;
        cov_12rg0o38yi.s[15]++;

        return $state.target('auth.login', undefined, { location: false });
      });
    };
    // Register the transition Hook
    cov_12rg0o38yi.s[16]++;
    $transitions.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });

    cov_12rg0o38yi.s[17]++;
    $transitions.onBefore({
      to: 'auth.*'
    }, function (transition) {
      cov_12rg0o38yi.f[7]++;

      var Auth = (cov_12rg0o38yi.s[18]++, transition.injector().get('Auth'));
      var $state = (cov_12rg0o38yi.s[19]++, transition.router.stateService);
      cov_12rg0o38yi.s[20]++;
      if (Auth.isAuthenticated()) {
        cov_12rg0o38yi.b[3][0]++;
        cov_12rg0o38yi.s[21]++;

        return $state.target('app.main');
      } else {
        cov_12rg0o38yi.b[3][1]++;
      }
    });
  }

  cov_12rg0o38yi.s[22]++;
  angular.module('dc-auth').config(['$stateProvider', routeConfig]).run(['$transitions', run]);
})();

// adalAuthenticationServiceProvider

// // OFFICE 365 ADAL CONFIG
// adalAuthenticationServiceProvider.init({
//   instance: 'https://login.microsoftonline.com/',
//   tenant: '580e9bbc-998f-4c70-bd05-0131f2d2923d', // COMMON OR YOUR TENANT ID
//   clientId: '496dcec8-ec50-46c4-a41c-14e3cc7836d6', // This is your client ID
//   // redirectUri: 'https://tools.divconcontrols.com/auth', // This is your redirect URI
//   popUp: true
// });