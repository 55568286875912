'use strict';

var cov_1mbdbc9vl2 = function () {
  var path = '/workdir/src/app/service/invoice/invoice.component.js',
      hash = '0650b215a01dc99c1998ccda1a09bba60a1d4c8c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/invoice/invoice.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 94
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 40,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 28
        },
        end: {
          line: 21,
          column: 8
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 39,
          column: 8
        }
      },
      '7': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 24,
          column: 59
        }
      },
      '8': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 34,
          column: 13
        }
      },
      '9': {
        start: {
          line: 27,
          column: 24
        },
        end: {
          line: 27,
          column: 44
        }
      },
      '10': {
        start: {
          line: 28,
          column: 12
        },
        end: {
          line: 32,
          column: 14
        }
      },
      '11': {
        start: {
          line: 33,
          column: 12
        },
        end: {
          line: 33,
          column: 32
        }
      },
      '12': {
        start: {
          line: 43,
          column: 23
        },
        end: {
          line: 43,
          column: 56
        }
      },
      '13': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '14': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 29
        }
      },
      '15': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 47
        }
      },
      '16': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 54,
          column: 6
        }
      },
      '17': {
        start: {
          line: 51,
          column: 16
        },
        end: {
          line: 51,
          column: 40
        }
      },
      '18': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 19
        }
      },
      '19': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 53,
          column: 19
        }
      },
      '20': {
        start: {
          line: 56,
          column: 4
        },
        end: {
          line: 60,
          column: 6
        }
      },
      '21': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 59,
          column: 9
        }
      },
      '22': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 64,
          column: 6
        }
      },
      '23': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 29
        }
      },
      '24': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 67,
          column: 75
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 68,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 21
          }
        },
        loc: {
          start: {
            line: 15,
            column: 82
          },
          end: {
            line: 65,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 22
          },
          end: {
            line: 18,
            column: 23
          }
        },
        loc: {
          start: {
            line: 18,
            column: 28
          },
          end: {
            line: 40,
            column: 5
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 23,
            column: 9
          }
        },
        loc: {
          start: {
            line: 23,
            column: 18
          },
          end: {
            line: 35,
            column: 9
          }
        },
        line: 23
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 26,
            column: 101
          },
          end: {
            line: 26,
            column: 102
          }
        },
        loc: {
          start: {
            line: 26,
            column: 113
          },
          end: {
            line: 34,
            column: 11
          }
        },
        line: 26
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 36,
            column: 8
          },
          end: {
            line: 36,
            column: 9
          }
        },
        loc: {
          start: {
            line: 36,
            column: 14
          },
          end: {
            line: 38,
            column: 9
          }
        },
        line: 36
      },
      '6': {
        name: 'loadTicket',
        decl: {
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 42,
            column: 23
          }
        },
        loc: {
          start: {
            line: 42,
            column: 26
          },
          end: {
            line: 48,
            column: 5
          }
        },
        line: 42
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 44,
            column: 44
          },
          end: {
            line: 44,
            column: 45
          }
        },
        loc: {
          start: {
            line: 44,
            column: 54
          },
          end: {
            line: 47,
            column: 7
          }
        },
        line: 44
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 50,
            column: 19
          },
          end: {
            line: 50,
            column: 20
          }
        },
        loc: {
          start: {
            line: 50,
            column: 25
          },
          end: {
            line: 54,
            column: 5
          }
        },
        line: 50
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 56,
            column: 18
          },
          end: {
            line: 56,
            column: 19
          }
        },
        loc: {
          start: {
            line: 56,
            column: 24
          },
          end: {
            line: 60,
            column: 5
          }
        },
        line: 56
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 62,
            column: 22
          },
          end: {
            line: 62,
            column: 23
          }
        },
        loc: {
          start: {
            line: 62,
            column: 28
          },
          end: {
            line: 64,
            column: 5
          }
        },
        line: 62
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 30,
            column: 20
          },
          end: {
            line: 30,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 30,
            column: 20
          },
          end: {
            line: 30,
            column: 31
          }
        }, {
          start: {
            line: 30,
            column: 35
          },
          end: {
            line: 30,
            column: 39
          }
        }],
        line: 30
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1mbdbc9vl2.s[0]++;
(function () {
  cov_1mbdbc9vl2.f[0]++;

  var serviceInvoice = (cov_1mbdbc9vl2.s[1]++, {
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    },
    templateUrl: 'app/service/invoice/invoice.html',
    controller: controller
  });

  cov_1mbdbc9vl2.s[2]++;
  controller.$inject = ['$uibModal', '$stateParams', 'TicketService', 'FileService', 'Utils'];

  function controller($uibModal, $stateParams, TicketService, FileService, Utils) {
    cov_1mbdbc9vl2.f[1]++;

    var self = (cov_1mbdbc9vl2.s[3]++, this);

    cov_1mbdbc9vl2.s[4]++;
    self.signTicket = function () {
      cov_1mbdbc9vl2.f[2]++;

      var modalInstance = (cov_1mbdbc9vl2.s[5]++, $uibModal.open({
        component: 'signTicket'
      }));
      cov_1mbdbc9vl2.s[6]++;
      modalInstance.result.then(function (result) {
        cov_1mbdbc9vl2.f[3]++;
        cov_1mbdbc9vl2.s[7]++;

        self.ticket.signature = result.signature.dataUrl;
        // Data URL string
        cov_1mbdbc9vl2.s[8]++;
        FileService.putSignature(self.ticket.id + '/signature.png', result.signature.dataUrl).then(function (snapshot) {
          cov_1mbdbc9vl2.f[4]++;

          var img = (cov_1mbdbc9vl2.s[9]++, snapshot.downloadURL);
          cov_1mbdbc9vl2.s[10]++;
          self.ticket.signature = {
            image: img,
            name: (cov_1mbdbc9vl2.b[0][0]++, result.name) || (cov_1mbdbc9vl2.b[0][1]++, null),
            date: Utils.now()
          };
          cov_1mbdbc9vl2.s[11]++;
          self.ticket.$save();
        });
      }, function () {
        // dismissed

        cov_1mbdbc9vl2.f[5]++;
      });
    };

    function loadTicket() {
      cov_1mbdbc9vl2.f[6]++;

      var ticketId = (cov_1mbdbc9vl2.s[12]++, parseFloat($stateParams.ticketId));
      cov_1mbdbc9vl2.s[13]++;
      TicketService.$getByid(ticketId).then(function (ticket) {
        cov_1mbdbc9vl2.f[7]++;
        cov_1mbdbc9vl2.s[14]++;

        self.ticket = ticket;
        cov_1mbdbc9vl2.s[15]++;
        self.lineItems = self.ticket.lineItems;
      });
    }

    cov_1mbdbc9vl2.s[16]++;
    self.$onInit = function () {
      cov_1mbdbc9vl2.f[8]++;

      var d = (cov_1mbdbc9vl2.s[17]++, new Date().toISOString());
      cov_1mbdbc9vl2.s[18]++;
      self.now = d;
      cov_1mbdbc9vl2.s[19]++;
      loadTicket();
    };

    cov_1mbdbc9vl2.s[20]++;
    self.cancel = function () {
      cov_1mbdbc9vl2.f[9]++;
      cov_1mbdbc9vl2.s[21]++;

      self.dismiss({
        $value: self.original
      });
    };

    cov_1mbdbc9vl2.s[22]++;
    self.$onDestroy = function () {
      cov_1mbdbc9vl2.f[10]++;
      cov_1mbdbc9vl2.s[23]++;

      self.ticket.$destroy();
    };
  }

  cov_1mbdbc9vl2.s[24]++;
  angular.module('dc-service').component('serviceInvoice', serviceInvoice);
})();