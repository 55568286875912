'use strict';

var cov_2qtm9kxqtt = function () {
  var path = '/workdir/src/app/projects/project-priorities/project-priorities.js',
      hash = 'ac37fcfd4a3464ecdf35dd9ac27679b343c587a1',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/projects/project-priorities/project-priorities.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 55,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 28
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 43
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 16,
          column: 6
        }
      },
      '5': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 45
        }
      },
      '6': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 26
        }
      },
      '7': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 33
        }
      },
      '8': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 21
        }
      },
      '9': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '10': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '11': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 34
        }
      },
      '12': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 15
        }
      },
      '13': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 23
        }
      },
      '14': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '15': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '16': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 56
        }
      },
      '17': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 37
        }
      },
      '18': {
        start: {
          line: 30,
          column: 13
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '19': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 55
        }
      },
      '20': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 36
        }
      },
      '21': {
        start: {
          line: 33,
          column: 13
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '22': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 54
        }
      },
      '23': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 35,
          column: 35
        }
      },
      '24': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 49,
          column: 6
        }
      },
      '25': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '26': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 55
        }
      },
      '27': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 36
        }
      },
      '28': {
        start: {
          line: 42,
          column: 13
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '29': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 54
        }
      },
      '30': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 35
        }
      },
      '31': {
        start: {
          line: 45,
          column: 13
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '32': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 54
        }
      },
      '33': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 35
        }
      },
      '34': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 54,
          column: 55
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 55,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 37
          },
          end: {
            line: 50,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 19
          },
          end: {
            line: 12,
            column: 20
          }
        },
        loc: {
          start: {
            line: 12,
            column: 25
          },
          end: {
            line: 16,
            column: 5
          }
        },
        line: 12
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 19,
            column: 24
          },
          end: {
            line: 19,
            column: 25
          }
        },
        loc: {
          start: {
            line: 19,
            column: 30
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 19
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 26,
            column: 22
          },
          end: {
            line: 26,
            column: 23
          }
        },
        loc: {
          start: {
            line: 26,
            column: 28
          },
          end: {
            line: 37,
            column: 5
          }
        },
        line: 26
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 38,
            column: 21
          },
          end: {
            line: 38,
            column: 22
          }
        },
        loc: {
          start: {
            line: 38,
            column: 27
          },
          end: {
            line: 49,
            column: 5
          }
        },
        line: 38
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 27
      },
      '2': {
        loc: {
          start: {
            line: 30,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 30,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 30
      },
      '3': {
        loc: {
          start: {
            line: 33,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 33,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 33
      },
      '4': {
        loc: {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        }],
        line: 39
      },
      '5': {
        loc: {
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }],
        line: 42
      },
      '6': {
        loc: {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }],
        line: 45
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2qtm9kxqtt.s[0]++;
(function () {
  cov_2qtm9kxqtt.f[0]++;

  var projectPriorities = (cov_2qtm9kxqtt.s[1]++, {
    templateUrl: 'app/projects/project-priorities/project-priorities.html',
    controller: controller
  });

  cov_2qtm9kxqtt.s[2]++;
  controller.$inject = ['$state', 'Users'];

  function controller($state, Users) {
    cov_2qtm9kxqtt.f[1]++;

    var self = (cov_2qtm9kxqtt.s[3]++, this);
    cov_2qtm9kxqtt.s[4]++;
    self.$onInit = function () {
      cov_2qtm9kxqtt.f[2]++;
      cov_2qtm9kxqtt.s[5]++;

      self.roles = Users.currentUser().roles;
      cov_2qtm9kxqtt.s[6]++;
      self.state = $state;
      cov_2qtm9kxqtt.s[7]++;
      self.toggleVisible = false;
    };

    cov_2qtm9kxqtt.s[8]++;
    self.filter = '';
    cov_2qtm9kxqtt.s[9]++;
    self.toggleFilter = function () {
      cov_2qtm9kxqtt.f[3]++;
      cov_2qtm9kxqtt.s[10]++;

      if (self.filter === '') {
        cov_2qtm9kxqtt.b[0][0]++;
        cov_2qtm9kxqtt.s[11]++;

        self.filter = 'timestamp';
        cov_2qtm9kxqtt.s[12]++;
        return;
      } else {
        cov_2qtm9kxqtt.b[0][1]++;
      }
      cov_2qtm9kxqtt.s[13]++;
      self.filter = '';
    };
    cov_2qtm9kxqtt.s[14]++;
    self.rightSwipe = function () {
      cov_2qtm9kxqtt.f[4]++;
      cov_2qtm9kxqtt.s[15]++;

      if ($state.current.name === 'app.projects.priorities.databases') {
        cov_2qtm9kxqtt.b[1][0]++;
        cov_2qtm9kxqtt.s[16]++;

        $state.go('app.projects.priorities.submittals');
        cov_2qtm9kxqtt.s[17]++;
        self.priority = 'submittals';
      } else {
          cov_2qtm9kxqtt.b[1][1]++;
          cov_2qtm9kxqtt.s[18]++;
          if ($state.current.name === 'app.projects.priorities.graphics') {
            cov_2qtm9kxqtt.b[2][0]++;
            cov_2qtm9kxqtt.s[19]++;

            $state.go('app.projects.priorities.databases');
            cov_2qtm9kxqtt.s[20]++;
            self.priority = 'databases';
          } else {
              cov_2qtm9kxqtt.b[2][1]++;
              cov_2qtm9kxqtt.s[21]++;
              if ($state.current.name === 'app.projects.priorities.asbuilts') {
                cov_2qtm9kxqtt.b[3][0]++;
                cov_2qtm9kxqtt.s[22]++;

                $state.go('app.projects.priorities.graphics');
                cov_2qtm9kxqtt.s[23]++;
                self.priority = 'graphics';
              } else {
                cov_2qtm9kxqtt.b[3][1]++;
              }
            }
        }
    };
    cov_2qtm9kxqtt.s[24]++;
    self.leftSwipe = function () {
      cov_2qtm9kxqtt.f[5]++;
      cov_2qtm9kxqtt.s[25]++;

      if ($state.current.name === 'app.projects.priorities.submittals') {
        cov_2qtm9kxqtt.b[4][0]++;
        cov_2qtm9kxqtt.s[26]++;

        $state.go('app.projects.priorities.databases');
        cov_2qtm9kxqtt.s[27]++;
        self.priority = 'databases';
      } else {
          cov_2qtm9kxqtt.b[4][1]++;
          cov_2qtm9kxqtt.s[28]++;
          if ($state.current.name === 'app.projects.priorities.databases') {
            cov_2qtm9kxqtt.b[5][0]++;
            cov_2qtm9kxqtt.s[29]++;

            $state.go('app.projects.priorities.graphics');
            cov_2qtm9kxqtt.s[30]++;
            self.priority = 'graphics';
          } else {
              cov_2qtm9kxqtt.b[5][1]++;
              cov_2qtm9kxqtt.s[31]++;
              if ($state.current.name === 'app.projects.priorities.graphics') {
                cov_2qtm9kxqtt.b[6][0]++;
                cov_2qtm9kxqtt.s[32]++;

                $state.go('app.projects.priorities.asbuilts');
                cov_2qtm9kxqtt.s[33]++;
                self.priority = 'asbuilts';
              } else {
                cov_2qtm9kxqtt.b[6][1]++;
              }
            }
        }
    };
  }

  cov_2qtm9kxqtt.s[34]++;
  angular.module('app').component('projectPriorities', projectPriorities);
})();