'use strict';

var cov_2ed3rbpibw = function () {
  var path = '/workdir/src/app/common/helpers/start-from.filter.js',
      hash = 'ee49c74a18876bdf644885c230f03655e8e681f6',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/helpers/start-from.filter.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 10,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 7,
          column: 6
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 28
        }
      },
      '3': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 6,
          column: 32
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 58
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 10,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'filter',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 17
          }
        },
        loc: {
          start: {
            line: 3,
            column: 20
          },
          end: {
            line: 8,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 12
          }
        },
        loc: {
          start: {
            line: 4,
            column: 35
          },
          end: {
            line: 7,
            column: 5
          }
        },
        line: 4
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2ed3rbpibw.s[0]++;
(function () {
  cov_2ed3rbpibw.f[0]++;

  function filter() {
    cov_2ed3rbpibw.f[1]++;
    cov_2ed3rbpibw.s[1]++;

    return function (input, start) {
      cov_2ed3rbpibw.f[2]++;
      cov_2ed3rbpibw.s[2]++;

      start = Number(start); // parse to int
      cov_2ed3rbpibw.s[3]++;
      return input.slice(start);
    };
  }
  cov_2ed3rbpibw.s[4]++;
  angular.module('dc-common').filter('startFrom', filter);
})();