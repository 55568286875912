'use strict';

var cov_2a4ulwzvol = function () {
  var path = '/workdir/src/app/users/user.routes.js',
      hash = '4bd0b9bae4bb40a116359c9704f46a1b4dd19c17',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/user.routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 22,
          column: 9
        }
      },
      '2': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 27,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 28,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'routeConfig',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 39
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 3
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2a4ulwzvol.s[0]++;
(function () {
  cov_2a4ulwzvol.f[0]++;

  function routeConfig($stateProvider) {
    cov_2a4ulwzvol.f[1]++;
    cov_2a4ulwzvol.s[1]++;

    $stateProvider.state('users', {
      parent: 'app',
      url: '/users',
      template: '<ui-view></ui-view>',
      abstract: true
    }).state('users.list', {
      url: '/',
      component: 'userList'
    }).state('users.create', {
      url: '/create',
      component: 'createUser'
    }).state('users.schedule', {
      url: '/schedule',
      component: 'userSchedule'
    });
  }

  cov_2a4ulwzvol.s[2]++;
  angular.module('dc-user').config(['$stateProvider', routeConfig]);
})();