'use strict';

var cov_gcq2npz53 = function () {
  var path = '/workdir/src/directives/read-file.js',
      hash = 'ee14418299156b427d067c1ab9fa74a6ce7f837b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/directives/read-file.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 36,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 30,
          column: 6
        }
      },
      '2': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 28,
          column: 11
        }
      },
      '3': {
        start: {
          line: 18,
          column: 24
        },
        end: {
          line: 18,
          column: 42
        }
      },
      '4': {
        start: {
          line: 19,
          column: 23
        },
        end: {
          line: 19,
          column: 31
        }
      },
      '5': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 25,
          column: 11
        }
      },
      '6': {
        start: {
          line: 21,
          column: 12
        },
        end: {
          line: 21,
          column: 41
        }
      },
      '7': {
        start: {
          line: 22,
          column: 27
        },
        end: {
          line: 22,
          column: 39
        }
      },
      '8': {
        start: {
          line: 23,
          column: 12
        },
        end: {
          line: 23,
          column: 52
        }
      },
      '9': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 24,
          column: 19
        }
      },
      '10': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 26,
          column: 33
        }
      },
      '11': {
        start: {
          line: 27,
          column: 10
        },
        end: {
          line: 27,
          column: 38
        }
      },
      '12': {
        start: {
          line: 33,
          column: 2
        },
        end: {
          line: 35,
          column: 29
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 36,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'file',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 15
          }
        },
        loc: {
          start: {
            line: 3,
            column: 18
          },
          end: {
            line: 31,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 29
          },
          end: {
            line: 17,
            column: 30
          }
        },
        loc: {
          start: {
            line: 17,
            column: 38
          },
          end: {
            line: 28,
            column: 9
          }
        },
        line: 17
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 25,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 25,
            column: 11
          }
        }, {
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 25,
            column: 11
          }
        }],
        line: 20
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_gcq2npz53.s[0]++;
(function () {
  cov_gcq2npz53.f[0]++;

  function file() {
    cov_gcq2npz53.f[1]++;
    cov_gcq2npz53.s[1]++;

    return {
      restrict: 'E',
      template: '\n        <div class="input-group">\n          <label class="input-group-btn">\n            <span class="btn btn-primary">Browse&hellip; <input type="file" style="display: none;"></span>\n          </label>\n          <input type="text" class="form-control" value="{{name}}" readonly>\n        </div>\n      ',
      replace: true,
      require: 'ngModel',
      link: function link(scope, element, attrs, ngModel) {
        cov_gcq2npz53.s[2]++;

        element.on('change', function (event) {
          cov_gcq2npz53.f[2]++;

          var files = (cov_gcq2npz53.s[3]++, event.target.files);
          var file = (cov_gcq2npz53.s[4]++, files[0]);
          cov_gcq2npz53.s[5]++;
          if ('multiple' in attrs) {
            cov_gcq2npz53.b[0][0]++;
            cov_gcq2npz53.s[6]++;

            ngModel.$setViewValue(files);
            var length = (cov_gcq2npz53.s[7]++, files.length);
            cov_gcq2npz53.s[8]++;
            scope.name = length + ' Files Selected';
            cov_gcq2npz53.s[9]++;
            return;
          } else {
            cov_gcq2npz53.b[0][1]++;
          }
          cov_gcq2npz53.s[10]++;
          scope.name = file.name;
          cov_gcq2npz53.s[11]++;
          ngModel.$setViewValue(file);
        });
      }
    };
  }

  cov_gcq2npz53.s[12]++;
  angular.module('app').directive('file', file);
})();