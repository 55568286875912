'use strict';

var cov_17eaguixgr = function () {
  var path = '/workdir/src/app/components/date-picker-form/date-picker.js',
      hash = '309a7ba0f53ddbbad05a6fc7f404077268cdb17c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/components/date-picker-form/date-picker.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 17
        },
        end: {
          line: 13,
          column: 21
        }
      },
      '3': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 24
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 18,
          column: 6
        }
      },
      '5': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 33
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 28
        }
      },
      '7': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 23,
          column: 6
        }
      },
      '8': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 31,
          column: 6
        }
      },
      '9': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 21
        }
      },
      '10': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 37
        }
      },
      '11': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 30,
          column: 9
        }
      },
      '12': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 24
        }
      },
      '13': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 34,
          column: 44
        }
      },
      '14': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 38,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 39,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 12,
            column: 11
          },
          end: {
            line: 12,
            column: 21
          }
        },
        loc: {
          start: {
            line: 12,
            column: 38
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 16
          },
          end: {
            line: 16,
            column: 17
          }
        },
        loc: {
          start: {
            line: 16,
            column: 22
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 25,
            column: 17
          },
          end: {
            line: 25,
            column: 18
          }
        },
        loc: {
          start: {
            line: 25,
            column: 25
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 25
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 28,
            column: 15
          },
          end: {
            line: 28,
            column: 16
          }
        },
        loc: {
          start: {
            line: 28,
            column: 21
          },
          end: {
            line: 30,
            column: 7
          }
        },
        line: 28
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_17eaguixgr.s[0]++;
(function () {
  cov_17eaguixgr.f[0]++;

  var datePickerForm = (cov_17eaguixgr.s[1]++, {
    bindings: {
      date: '=',
      callback: '&'
    },
    templateUrl: 'app/components/date-picker-form/date-picker.html',
    controller: controller
  });

  function controller($log, $timeout) {
    cov_17eaguixgr.f[1]++;

    var self = (cov_17eaguixgr.s[2]++, this);

    cov_17eaguixgr.s[3]++;
    self.isOpen = false;
    cov_17eaguixgr.s[4]++;
    self.open = function () {
      cov_17eaguixgr.f[2]++;
      cov_17eaguixgr.s[5]++;

      self.isOpen = !self.isOpen;
    };
    cov_17eaguixgr.s[6]++;
    self.today = new Date();
    cov_17eaguixgr.s[7]++;
    self.dateOptions = {
      minDate: self.today,
      showWeeks: false
    };

    cov_17eaguixgr.s[8]++;
    self.toIso = function (date) {
      cov_17eaguixgr.f[3]++;
      cov_17eaguixgr.s[9]++;

      $log.log(date);
      cov_17eaguixgr.s[10]++;
      self.date = date.toISOString();
      cov_17eaguixgr.s[11]++;
      $timeout(function () {
        cov_17eaguixgr.f[4]++;
        cov_17eaguixgr.s[12]++;

        self.callback();
      });
    };
  }

  cov_17eaguixgr.s[13]++;
  controller.$inject = ['$log', '$timeout'];

  cov_17eaguixgr.s[14]++;
  angular.module('app').component('datePickerForm', datePickerForm);
})();