'use strict';

var cov_zffnmbchh = function () {
  var path = '/workdir/src/app/common/customer/helpers/customer-name.component.js',
      hash = '242fb77431810bdbeb867a525083adf500d36f2b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/customer/helpers/customer-name.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 51
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 23
        }
      },
      '5': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '7': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 31,
          column: 13
        }
      },
      '8': {
        start: {
          line: 25,
          column: 29
        },
        end: {
          line: 25,
          column: 39
        }
      },
      '9': {
        start: {
          line: 26,
          column: 12
        },
        end: {
          line: 26,
          column: 42
        }
      },
      '10': {
        start: {
          line: 27,
          column: 12
        },
        end: {
          line: 29,
          column: 13
        }
      },
      '11': {
        start: {
          line: 28,
          column: 14
        },
        end: {
          line: 28,
          column: 49
        }
      },
      '12': {
        start: {
          line: 30,
          column: 12
        },
        end: {
          line: 30,
          column: 27
        }
      },
      '13': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 42,
          column: 6
        }
      },
      '14': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '15': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 26
        }
      },
      '16': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 41,
          column: 7
        }
      },
      '17': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 29
        }
      },
      '18': {
        start: {
          line: 45,
          column: 2
        },
        end: {
          line: 47,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 48,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 42
          },
          end: {
            line: 43,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 19
          },
          end: {
            line: 21,
            column: 20
          }
        },
        loc: {
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 21
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 16
          },
          end: {
            line: 24,
            column: 17
          }
        },
        loc: {
          start: {
            line: 24,
            column: 24
          },
          end: {
            line: 31,
            column: 11
          }
        },
        line: 24
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 35,
            column: 21
          },
          end: {
            line: 35,
            column: 22
          }
        },
        loc: {
          start: {
            line: 35,
            column: 27
          },
          end: {
            line: 42,
            column: 5
          }
        },
        line: 35
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }, {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 27,
            column: 12
          },
          end: {
            line: 29,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 12
          },
          end: {
            line: 29,
            column: 13
          }
        }, {
          start: {
            line: 27,
            column: 12
          },
          end: {
            line: 29,
            column: 13
          }
        }],
        line: 27
      },
      '2': {
        loc: {
          start: {
            line: 27,
            column: 16
          },
          end: {
            line: 27,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 27,
            column: 16
          },
          end: {
            line: 27,
            column: 26
          }
        }, {
          start: {
            line: 27,
            column: 30
          },
          end: {
            line: 27,
            column: 48
          }
        }],
        line: 27
      },
      '3': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }],
        line: 36
      },
      '4': {
        loc: {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }],
        line: 39
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_zffnmbchh.s[0]++;
(function () {
  cov_zffnmbchh.f[0]++;

  var customerName = (cov_zffnmbchh.s[1]++, {
    bindings: {
      uid: '<'
    },
    template: '\n      <span class="customer" ng-if="$ctrl.customer && !$ctrl.showLink">{{$ctrl.customer}}</span>\n      <a ui-sref="customer({customerID: $ctrl.uid})" ng-if="$ctrl.customer && $ctrl.showLink"><span class="customer">{{$ctrl.customer}}</span>\n    ',
    controller: controller
  });

  cov_zffnmbchh.s[2]++;
  controller.$inject = ['$db', '$attrs', '$scope'];

  function controller($db, $attrs, scope) {
    cov_zffnmbchh.f[1]++;

    var self = (cov_zffnmbchh.s[3]++, this);
    cov_zffnmbchh.s[4]++;
    self.customer = '';

    // $onInit
    cov_zffnmbchh.s[5]++;
    self.$onInit = function () {
      cov_zffnmbchh.f[2]++;
      cov_zffnmbchh.s[6]++;

      if (angular.isDefined(self.uid)) {
        cov_zffnmbchh.b[0][0]++;
        cov_zffnmbchh.s[7]++;

        $db.customers.child(self.uid).once('value').then(function (data) {
          cov_zffnmbchh.f[3]++;

          var customer = (cov_zffnmbchh.s[8]++, data.val());
          cov_zffnmbchh.s[9]++;
          self.customer = customer.name;
          cov_zffnmbchh.s[10]++;
          if ((cov_zffnmbchh.b[2][0]++, self.short) && (cov_zffnmbchh.b[2][1]++, customer.shortName)) {
            cov_zffnmbchh.b[1][0]++;
            cov_zffnmbchh.s[11]++;

            self.customer = customer.shortName;
          } else {
            cov_zffnmbchh.b[1][1]++;
          }
          cov_zffnmbchh.s[12]++;
          scope.$apply();
        });
      } else {
        cov_zffnmbchh.b[0][1]++;
      }
    };
    // $postLink
    cov_zffnmbchh.s[13]++;
    self.$postLink = function () {
      cov_zffnmbchh.f[4]++;
      cov_zffnmbchh.s[14]++;

      if ('short' in $attrs) {
        cov_zffnmbchh.b[3][0]++;
        cov_zffnmbchh.s[15]++;

        self.short = true;
      } else {
        cov_zffnmbchh.b[3][1]++;
      }
      cov_zffnmbchh.s[16]++;
      if ('withLink' in $attrs) {
        cov_zffnmbchh.b[4][0]++;
        cov_zffnmbchh.s[17]++;

        self.showLink = true;
      } else {
        cov_zffnmbchh.b[4][1]++;
      }
    };
  }

  cov_zffnmbchh.s[18]++;
  angular.module('dc-customer').component('customerName', customerName);
})();