'use strict';

var cov_xgsi2c220 = function () {
  var path = '/workdir/src/app/users/helpers/user-image.component.js',
      hash = '1a1b782dd693406cb5571a7ca64c124d1e855d73',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/helpers/user-image.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 51
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 26,
          column: 7
        }
      },
      '6': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 25,
          column: 13
        }
      },
      '7': {
        start: {
          line: 22,
          column: 26
        },
        end: {
          line: 22,
          column: 36
        }
      },
      '8': {
        start: {
          line: 23,
          column: 12
        },
        end: {
          line: 23,
          column: 55
        }
      },
      '9': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 24,
          column: 27
        }
      },
      '10': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '11': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '12': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 29
        }
      },
      '13': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 38,
          column: 39
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 39,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 42
          },
          end: {
            line: 34,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 22
          },
          end: {
            line: 18,
            column: 23
          }
        },
        loc: {
          start: {
            line: 18,
            column: 38
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 16
          },
          end: {
            line: 21,
            column: 17
          }
        },
        loc: {
          start: {
            line: 21,
            column: 24
          },
          end: {
            line: 25,
            column: 11
          }
        },
        line: 21
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 29,
            column: 21
          },
          end: {
            line: 29,
            column: 22
          }
        },
        loc: {
          start: {
            line: 29,
            column: 27
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 29
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }, {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }],
        line: 19
      },
      '1': {
        loc: {
          start: {
            line: 19,
            column: 10
          },
          end: {
            line: 19,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 19,
            column: 10
          },
          end: {
            line: 19,
            column: 23
          }
        }, {
          start: {
            line: 19,
            column: 27
          },
          end: {
            line: 19,
            column: 54
          }
        }],
        line: 19
      },
      '2': {
        loc: {
          start: {
            line: 23,
            column: 25
          },
          end: {
            line: 23,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 23,
            column: 25
          },
          end: {
            line: 23,
            column: 30
          }
        }, {
          start: {
            line: 23,
            column: 34
          },
          end: {
            line: 23,
            column: 54
          }
        }],
        line: 23
      },
      '3': {
        loc: {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }, {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }],
        line: 30
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_xgsi2c220.s[0]++;
(function () {
  cov_xgsi2c220.f[0]++;

  var userImage = (cov_xgsi2c220.s[1]++, {
    bindings: {
      uid: '<',
      imgclass: '@'
    },
    template: '\n      <img ng-src="{{$ctrl.image}}" alt="{{$ctrl.alt}}" class="{{$ctrl.imgclass}}"/>\n    ',
    controller: controller
  });

  cov_xgsi2c220.s[2]++;
  controller.$inject = ['$db', '$scope', '$attrs'];

  function controller($db, scope, $attrs) {
    cov_xgsi2c220.f[1]++;

    var self = (cov_xgsi2c220.s[3]++, this);
    cov_xgsi2c220.s[4]++;
    self.$onChanges = function (prev, next) {
      cov_xgsi2c220.f[2]++;
      cov_xgsi2c220.s[5]++;

      if ((cov_xgsi2c220.b[1][0]++, next !== prev) && (cov_xgsi2c220.b[1][1]++, angular.isDefined(self.uid))) {
        cov_xgsi2c220.b[0][0]++;
        cov_xgsi2c220.s[6]++;

        $db.users.child(self.uid + '/profile/image').once('value').then(function (data) {
          cov_xgsi2c220.f[3]++;

          var image = (cov_xgsi2c220.s[7]++, data.val());
          cov_xgsi2c220.s[8]++;
          self.image = (cov_xgsi2c220.b[2][0]++, image) || (cov_xgsi2c220.b[2][1]++, 'images/default.png');
          cov_xgsi2c220.s[9]++;
          scope.$apply();
        });
      } else {
        cov_xgsi2c220.b[0][1]++;
      }
    };
    // $postLink
    cov_xgsi2c220.s[10]++;
    self.$postLink = function () {
      cov_xgsi2c220.f[4]++;
      cov_xgsi2c220.s[11]++;

      if ('userNameLink' in $attrs) {
        cov_xgsi2c220.b[3][0]++;
        cov_xgsi2c220.s[12]++;

        self.showLink = true;
      } else {
        cov_xgsi2c220.b[3][1]++;
      }
    };
  }

  cov_xgsi2c220.s[13]++;
  angular.module('dc-user').component('userImage', userImage);
})();