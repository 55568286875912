'use strict';

var cov_1fcm509la6 = function () {
  var path = '/workdir/src/app/service/helpers/ticket-number.component.js',
      hash = '36fbe1c3a3a381b07b0bb008320484a6fc9bff91',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/helpers/ticket-number.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 50,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 52
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 22,
          column: 7
        }
      },
      '5': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 44
        }
      },
      '6': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 20
        }
      },
      '7': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 28,
          column: 7
        }
      },
      '8': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 34
        }
      },
      '9': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 32,
          column: 6
        }
      },
      '10': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 28
        }
      },
      '11': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 38,
          column: 6
        }
      },
      '12': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 37,
          column: 7
        }
      },
      '13': {
        start: {
          line: 36,
          column: 8
        },
        end: {
          line: 36,
          column: 48
        }
      },
      '14': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 44,
          column: 6
        }
      },
      '15': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '16': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 29
        }
      },
      '17': {
        start: {
          line: 47,
          column: 2
        },
        end: {
          line: 49,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 50,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 46
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: 'padToFour',
        decl: {
          start: {
            line: 19,
            column: 13
          },
          end: {
            line: 19,
            column: 22
          }
        },
        loc: {
          start: {
            line: 19,
            column: 31
          },
          end: {
            line: 24,
            column: 5
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 26,
            column: 49
          },
          end: {
            line: 26,
            column: 50
          }
        },
        loc: {
          start: {
            line: 26,
            column: 58
          },
          end: {
            line: 28,
            column: 5
          }
        },
        line: 26
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 30,
            column: 19
          },
          end: {
            line: 30,
            column: 20
          }
        },
        loc: {
          start: {
            line: 30,
            column: 25
          },
          end: {
            line: 32,
            column: 5
          }
        },
        line: 30
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 34,
            column: 22
          },
          end: {
            line: 34,
            column: 23
          }
        },
        loc: {
          start: {
            line: 34,
            column: 38
          },
          end: {
            line: 38,
            column: 5
          }
        },
        line: 34
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 40,
            column: 21
          },
          end: {
            line: 40,
            column: 22
          }
        },
        loc: {
          start: {
            line: 40,
            column: 27
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 40
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 37,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 37,
            column: 7
          }
        }, {
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 37,
            column: 7
          }
        }],
        line: 35
      },
      '2': {
        loc: {
          start: {
            line: 35,
            column: 10
          },
          end: {
            line: 35,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 35,
            column: 10
          },
          end: {
            line: 35,
            column: 23
          }
        }, {
          start: {
            line: 35,
            column: 27
          },
          end: {
            line: 35,
            column: 54
          }
        }],
        line: 35
      },
      '3': {
        loc: {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }, {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }],
        line: 41
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1fcm509la6.s[0]++;
(function () {
  cov_1fcm509la6.f[0]++;

  var ticketNumber = (cov_1fcm509la6.s[1]++, {
    bindings: {
      uid: '<'
    },
    template: '\n    <span ng-if="!$ctrl.showLink">{{$ctrl.prefix}}{{$ctrl.ticketNumber}}</span>\n    <span ng-if="$ctrl.showLink"><a ui-sref="service.ticket({ticketId: $ctrl.ticketNumber})">{{$ctrl.prefix}}{{$ctrl.ticketNumber}}</a></span>\n    ',
    controller: controller
  });

  cov_1fcm509la6.s[2]++;
  controller.$inject = ['ClientSettings', '$attrs'];

  function controller(ClientSettings, $attrs) {
    cov_1fcm509la6.f[1]++;

    var self = (cov_1fcm509la6.s[3]++, this);

    function padToFour(number) {
      cov_1fcm509la6.f[2]++;
      cov_1fcm509la6.s[4]++;

      if (number <= 9999) {
        cov_1fcm509la6.b[0][0]++;
        cov_1fcm509la6.s[5]++;

        number = ('000' + number).slice(-4);
      } else {
        cov_1fcm509la6.b[0][1]++;
      }
      cov_1fcm509la6.s[6]++;
      return number;
    }

    cov_1fcm509la6.s[7]++;
    ClientSettings.billingCodes().$loaded().then(function (codes) {
      cov_1fcm509la6.f[3]++;
      cov_1fcm509la6.s[8]++;

      self.prefix = codes.tickets;
    });

    cov_1fcm509la6.s[9]++;
    self.$onInit = function () {
      cov_1fcm509la6.f[4]++;
      cov_1fcm509la6.s[10]++;

      self.showLink = false;
    };

    cov_1fcm509la6.s[11]++;
    self.$onChanges = function (prev, next) {
      cov_1fcm509la6.f[5]++;
      cov_1fcm509la6.s[12]++;

      if ((cov_1fcm509la6.b[2][0]++, next !== prev) && (cov_1fcm509la6.b[2][1]++, angular.isDefined(self.uid))) {
        cov_1fcm509la6.b[1][0]++;
        cov_1fcm509la6.s[13]++;

        self.ticketNumber = padToFour(self.uid);
      } else {
        cov_1fcm509la6.b[1][1]++;
      }
    };
    // $postLink
    cov_1fcm509la6.s[14]++;
    self.$postLink = function () {
      cov_1fcm509la6.f[6]++;
      cov_1fcm509la6.s[15]++;

      if ('withLink' in $attrs) {
        cov_1fcm509la6.b[3][0]++;
        cov_1fcm509la6.s[16]++;

        self.showLink = true;
      } else {
        cov_1fcm509la6.b[3][1]++;
      }
    };
  }

  cov_1fcm509la6.s[17]++;
  angular.module('dc-service').component('ticketNumber', ticketNumber);
})();