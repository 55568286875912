'use strict';

var cov_2fg2d5eh9o = function () {
  var path = '/workdir/src/app/projects/create-project/create-project.js',
      hash = '6efec16526fb6c7cd76b521b7e736c7f70209a9b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/projects/create-project/create-project.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 57,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 24
        },
        end: {
          line: 7,
          column: 3
        }
      },
      '2': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 81
        }
      },
      '3': {
        start: {
          line: 12,
          column: 17
        },
        end: {
          line: 12,
          column: 21
        }
      },
      '4': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 31,
          column: 6
        }
      },
      '5': {
        start: {
          line: 15,
          column: 24
        },
        end: {
          line: 15,
          column: 47
        }
      },
      '6': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 16,
          column: 45
        }
      },
      '7': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 30,
          column: 11
        }
      },
      '8': {
        start: {
          line: 19,
          column: 27
        },
        end: {
          line: 25,
          column: 11
        }
      },
      '9': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 26,
          column: 40
        }
      },
      '10': {
        start: {
          line: 27,
          column: 10
        },
        end: {
          line: 29,
          column: 13
        }
      },
      '11': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 46,
          column: 6
        }
      },
      '12': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 24
        }
      },
      '13': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 33
        }
      },
      '14': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 41,
          column: 9
        }
      },
      '15': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 39
        }
      },
      '16': {
        start: {
          line: 38,
          column: 21
        },
        end: {
          line: 38,
          column: 47
        }
      },
      '17': {
        start: {
          line: 39,
          column: 21
        },
        end: {
          line: 39,
          column: 34
        }
      },
      '18': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 64
        }
      },
      '19': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 45,
          column: 11
        }
      },
      '20': {
        start: {
          line: 44,
          column: 10
        },
        end: {
          line: 44,
          column: 29
        }
      },
      '21': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 51,
          column: 6
        }
      },
      '22': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 34
        }
      },
      '23': {
        start: {
          line: 54,
          column: 2
        },
        end: {
          line: 56,
          column: 47
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 57,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'createProjectCtrl',
        decl: {
          start: {
            line: 11,
            column: 11
          },
          end: {
            line: 11,
            column: 28
          }
        },
        loc: {
          start: {
            line: 11,
            column: 71
          },
          end: {
            line: 52,
            column: 3
          }
        },
        line: 11
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 25
          },
          end: {
            line: 14,
            column: 26
          }
        },
        loc: {
          start: {
            line: 14,
            column: 44
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 14
          },
          end: {
            line: 18,
            column: 15
          }
        },
        loc: {
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 30,
            column: 9
          }
        },
        line: 18
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 33,
            column: 19
          },
          end: {
            line: 33,
            column: 20
          }
        },
        loc: {
          start: {
            line: 33,
            column: 25
          },
          end: {
            line: 46,
            column: 5
          }
        },
        line: 33
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 36,
            column: 44
          },
          end: {
            line: 36,
            column: 45
          }
        },
        loc: {
          start: {
            line: 36,
            column: 59
          },
          end: {
            line: 41,
            column: 7
          }
        },
        line: 36
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 43,
            column: 17
          },
          end: {
            line: 43,
            column: 18
          }
        },
        loc: {
          start: {
            line: 43,
            column: 26
          },
          end: {
            line: 45,
            column: 9
          }
        },
        line: 43
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 48,
            column: 22
          },
          end: {
            line: 48,
            column: 23
          }
        },
        loc: {
          start: {
            line: 48,
            column: 28
          },
          end: {
            line: 51,
            column: 5
          }
        },
        line: 48
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2fg2d5eh9o.s[0]++;
(function () {
  cov_2fg2d5eh9o.f[0]++;

  var createProject = (cov_2fg2d5eh9o.s[1]++, {
    bindings: {},
    templateUrl: 'app/projects/create-project/create-project.html',
    controller: createProjectCtrl
  });

  cov_2fg2d5eh9o.s[2]++;
  createProjectCtrl.$inject = ['Users', 'Projects', '$state', 'ActivityService'];

  function createProjectCtrl(Users, Projects, $state, ActivityService) {
    cov_2fg2d5eh9o.f[1]++;

    var self = (cov_2fg2d5eh9o.s[3]++, this);

    cov_2fg2d5eh9o.s[4]++;
    self.createProject = function (project) {
      cov_2fg2d5eh9o.f[2]++;

      var createdBy = (cov_2fg2d5eh9o.s[5]++, Users.currentUser().$id);
      cov_2fg2d5eh9o.s[6]++;
      project.metadata.createdBy = createdBy;
      cov_2fg2d5eh9o.s[7]++;
      Projects.create(project).then(function () {
        cov_2fg2d5eh9o.f[3]++;

        var activity = (cov_2fg2d5eh9o.s[8]++, {
          user: Users.currentUser().profile.username,
          userId: Users.currentUser().$id,
          type: 'Project',
          action: 'Created',
          project: project.uid
        });
        cov_2fg2d5eh9o.s[9]++;
        ActivityService.add(activity);
        cov_2fg2d5eh9o.s[10]++;
        $state.go('app.project', {
          projectID: project.number
        });
      });
    };

    cov_2fg2d5eh9o.s[11]++;
    self.$onInit = function () {
      cov_2fg2d5eh9o.f[4]++;
      cov_2fg2d5eh9o.s[12]++;

      self.project = {};
      cov_2fg2d5eh9o.s[13]++;
      self.project.metadata = {};
      cov_2fg2d5eh9o.s[14]++;
      Projects.$getNextProjectNumber().then(function (lastProject) {
        cov_2fg2d5eh9o.f[5]++;
        cov_2fg2d5eh9o.s[15]++;

        self.lastProject = lastProject;
        var last = (cov_2fg2d5eh9o.s[16]++, lastProject[0].numberFixed);
        var next = (cov_2fg2d5eh9o.s[17]++, last + 0.0001);
        cov_2fg2d5eh9o.s[18]++;
        self.project.number = next.toFixed(4).replace('.', '-');
      });
      cov_2fg2d5eh9o.s[19]++;
      Users.all().$loaded(function (users) {
        cov_2fg2d5eh9o.f[6]++;
        cov_2fg2d5eh9o.s[20]++;

        self.users = users;
      });
    };

    cov_2fg2d5eh9o.s[21]++;
    self.$onDestroy = function () {
      cov_2fg2d5eh9o.f[7]++;
      cov_2fg2d5eh9o.s[22]++;

      self.lastProject.$destroy();
      // doesnt destroy users for persistance in service.
    };
  }

  cov_2fg2d5eh9o.s[23]++;
  angular.module('app').component('createProject', createProject);
})();