'use strict';

var cov_hpufk75qt = function () {
  var path = '/workdir/src/app/common/activity/activity.component.js',
      hash = 'fda611af5415ad0ec09daaaf2bf5d9bee4567121',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/activity/activity.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 47,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 43
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 24,
          column: 7
        }
      },
      '6': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 23,
          column: 11
        }
      },
      '7': {
        start: {
          line: 22,
          column: 10
        },
        end: {
          line: 22,
          column: 39
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '9': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 29,
          column: 11
        }
      },
      '10': {
        start: {
          line: 28,
          column: 10
        },
        end: {
          line: 28,
          column: 39
        }
      },
      '11': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '12': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 35,
          column: 11
        }
      },
      '13': {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 34,
          column: 39
        }
      },
      '14': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 41,
          column: 6
        }
      },
      '15': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 33
        }
      },
      '16': {
        start: {
          line: 44,
          column: 2
        },
        end: {
          line: 46,
          column: 38
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 47,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 21
          }
        },
        loc: {
          start: {
            line: 15,
            column: 39
          },
          end: {
            line: 42,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 19
          },
          end: {
            line: 18,
            column: 20
          }
        },
        loc: {
          start: {
            line: 18,
            column: 25
          },
          end: {
            line: 37,
            column: 5
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 17
          },
          end: {
            line: 21,
            column: 18
          }
        },
        loc: {
          start: {
            line: 21,
            column: 31
          },
          end: {
            line: 23,
            column: 9
          }
        },
        line: 21
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 27,
            column: 17
          },
          end: {
            line: 27,
            column: 18
          }
        },
        loc: {
          start: {
            line: 27,
            column: 31
          },
          end: {
            line: 29,
            column: 9
          }
        },
        line: 27
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 33,
            column: 17
          },
          end: {
            line: 33,
            column: 18
          }
        },
        loc: {
          start: {
            line: 33,
            column: 31
          },
          end: {
            line: 35,
            column: 9
          }
        },
        line: 33
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 39,
            column: 22
          },
          end: {
            line: 39,
            column: 23
          }
        },
        loc: {
          start: {
            line: 39,
            column: 28
          },
          end: {
            line: 41,
            column: 5
          }
        },
        line: 39
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }, {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }],
        line: 19
      },
      '1': {
        loc: {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }, {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }],
        line: 25
      },
      '2': {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 31
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_hpufk75qt.s[0]++;
(function () {
  cov_hpufk75qt.f[0]++;

  var component = (cov_hpufk75qt.s[1]++, {
    bindings: {
      projectId: '<',
      ticketId: '<',
      userId: '<'
    },
    templateUrl: 'app/common/activity/activity.html',
    controller: controller
  });

  cov_hpufk75qt.s[2]++;
  controller.$inject = ['ActivityService'];

  function controller(ActivityService) {
    cov_hpufk75qt.f[1]++;

    var self = (cov_hpufk75qt.s[3]++, this);

    cov_hpufk75qt.s[4]++;
    self.$onInit = function () {
      cov_hpufk75qt.f[2]++;
      cov_hpufk75qt.s[5]++;

      if (angular.isDefined(self.projectId)) {
        cov_hpufk75qt.b[0][0]++;
        cov_hpufk75qt.s[6]++;

        ActivityService.getActivitiesByProject(self.projectId).$loaded(function (activities) {
          cov_hpufk75qt.f[3]++;
          cov_hpufk75qt.s[7]++;

          self.activities = activities;
        });
      } else {
        cov_hpufk75qt.b[0][1]++;
      }
      cov_hpufk75qt.s[8]++;
      if (angular.isDefined(self.ticketId)) {
        cov_hpufk75qt.b[1][0]++;
        cov_hpufk75qt.s[9]++;

        ActivityService.getActivitiesByTicket(self.ticketId).$loaded(function (activities) {
          cov_hpufk75qt.f[4]++;
          cov_hpufk75qt.s[10]++;

          self.activities = activities;
        });
      } else {
        cov_hpufk75qt.b[1][1]++;
      }
      cov_hpufk75qt.s[11]++;
      if (angular.isDefined(self.userId)) {
        cov_hpufk75qt.b[2][0]++;
        cov_hpufk75qt.s[12]++;

        ActivityService.getActivitiesByUser(self.userId).$loaded(function (activities) {
          cov_hpufk75qt.f[5]++;
          cov_hpufk75qt.s[13]++;

          self.activities = activities;
        });
      } else {
        cov_hpufk75qt.b[2][1]++;
      }
    };

    cov_hpufk75qt.s[14]++;
    self.$onDestroy = function () {
      cov_hpufk75qt.f[6]++;
      cov_hpufk75qt.s[15]++;

      self.activities.$destroy();
    };
  }

  cov_hpufk75qt.s[16]++;
  angular.module('dc-activity').component('activity', component);
})();