'use strict';

var cov_16vj84c4ur = function () {
  var path = '/workdir/src/app/common/contacts/contact-list-widget/contact-list-widget.component.js',
      hash = '879cba370c9aabd7109951f621b7ef0618ce57d2',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/contacts/contact-list-widget/contact-list-widget.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 28
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 42
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 25
        }
      },
      '5': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 40
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 39
        }
      },
      '7': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 32
        }
      },
      '8': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 32
        }
      },
      '9': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 53
        }
      },
      '10': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '11': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 32,
          column: 13
        }
      },
      '12': {
        start: {
          line: 31,
          column: 12
        },
        end: {
          line: 31,
          column: 37
        }
      },
      '13': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 31
        }
      },
      '14': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 41,
          column: 82
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 42,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 38
          },
          end: {
            line: 39,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: 'numberOfPages',
        decl: {
          start: {
            line: 23,
            column: 13
          },
          end: {
            line: 23,
            column: 26
          }
        },
        loc: {
          start: {
            line: 23,
            column: 34
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 23
      },
      '3': {
        name: 'onChanges',
        decl: {
          start: {
            line: 27,
            column: 13
          },
          end: {
            line: 27,
            column: 22
          }
        },
        loc: {
          start: {
            line: 27,
            column: 35
          },
          end: {
            line: 34,
            column: 5
          }
        },
        line: 27
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 30,
            column: 19
          },
          end: {
            line: 30,
            column: 20
          }
        },
        loc: {
          start: {
            line: 30,
            column: 31
          },
          end: {
            line: 32,
            column: 11
          }
        },
        line: 30
      },
      '5': {
        name: 'onDestroy',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 22
          }
        },
        loc: {
          start: {
            line: 36,
            column: 25
          },
          end: {
            line: 38,
            column: 5
          }
        },
        line: 36
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 18,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 18,
            column: 33
          }
        }, {
          start: {
            line: 18,
            column: 37
          },
          end: {
            line: 18,
            column: 39
          }
        }],
        line: 18
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }],
        line: 28
      },
      '2': {
        loc: {
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 28,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 28,
            column: 23
          }
        }, {
          start: {
            line: 28,
            column: 27
          },
          end: {
            line: 28,
            column: 61
          }
        }],
        line: 28
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_16vj84c4ur.s[0]++;
(function () {
  cov_16vj84c4ur.f[0]++;

  var contactListWidget = (cov_16vj84c4ur.s[1]++, {
    bindings: {
      customerId: '<',
      pageSize: '<'
    },
    templateUrl: 'app/common/contacts/contact-list-widget/contact-list-widget.html',
    controller: controller
  });

  cov_16vj84c4ur.s[2]++;
  controller.$inject = ['ContactService'];

  function controller(ContactService) {
    cov_16vj84c4ur.f[1]++;

    var self = (cov_16vj84c4ur.s[3]++, this);

    cov_16vj84c4ur.s[4]++;
    self.currentPage = 0;
    cov_16vj84c4ur.s[5]++;
    self.pageSize = (cov_16vj84c4ur.b[0][0]++, self.pageSize) || (cov_16vj84c4ur.b[0][1]++, 10);
    cov_16vj84c4ur.s[6]++;
    self.numberOfPages = numberOfPages;
    cov_16vj84c4ur.s[7]++;
    self.$onChanges = onChanges;
    cov_16vj84c4ur.s[8]++;
    self.$onDestroy = onDestroy;

    function numberOfPages(array) {
      cov_16vj84c4ur.f[2]++;
      cov_16vj84c4ur.s[9]++;

      return Math.ceil(array.length / self.pageSize);
    }

    function onChanges(prev, next) {
      cov_16vj84c4ur.f[3]++;
      cov_16vj84c4ur.s[10]++;

      if ((cov_16vj84c4ur.b[2][0]++, next !== prev) && (cov_16vj84c4ur.b[2][1]++, angular.isDefined(self.customerId))) {
        cov_16vj84c4ur.b[1][0]++;
        cov_16vj84c4ur.s[11]++;

        ContactService.$getByCustomer(self.customerId).$loaded(function (contacts) {
          cov_16vj84c4ur.f[4]++;
          cov_16vj84c4ur.s[12]++;

          self.contacts = contacts;
        });
      } else {
        cov_16vj84c4ur.b[1][1]++;
      }
    }

    function onDestroy() {
      cov_16vj84c4ur.f[5]++;
      cov_16vj84c4ur.s[13]++;

      self.contacts.$destroy();
    }
  }

  cov_16vj84c4ur.s[14]++;
  angular.module('dc-contacts').component('contactListWidget', contactListWidget);
})();