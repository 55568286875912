'use strict';

var cov_1dpam8s7qa = function () {
  var path = '/workdir/src/app/service/service.routes.js',
      hash = 'e6c84f0cec44b6f12ae46d35d0491d7c575ff069',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/service.routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 105,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 99,
          column: 9
        }
      },
      '2': {
        start: {
          line: 19,
          column: 32
        },
        end: {
          line: 21,
          column: 12
        }
      },
      '3': {
        start: {
          line: 22,
          column: 10
        },
        end: {
          line: 27,
          column: 15
        }
      },
      '4': {
        start: {
          line: 24,
          column: 14
        },
        end: {
          line: 24,
          column: 29
        }
      },
      '5': {
        start: {
          line: 26,
          column: 14
        },
        end: {
          line: 26,
          column: 29
        }
      },
      '6': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 30,
          column: 38
        }
      },
      '7': {
        start: {
          line: 41,
          column: 23
        },
        end: {
          line: 41,
          column: 65
        }
      },
      '8': {
        start: {
          line: 42,
          column: 12
        },
        end: {
          line: 42,
          column: 46
        }
      },
      '9': {
        start: {
          line: 73,
          column: 27
        },
        end: {
          line: 73,
          column: 48
        }
      },
      '10': {
        start: {
          line: 74,
          column: 32
        },
        end: {
          line: 84,
          column: 12
        }
      },
      '11': {
        start: {
          line: 80,
          column: 27
        },
        end: {
          line: 80,
          column: 47
        }
      },
      '12': {
        start: {
          line: 81,
          column: 16
        },
        end: {
          line: 81,
          column: 50
        }
      },
      '13': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 90,
          column: 15
        }
      },
      '14': {
        start: {
          line: 87,
          column: 14
        },
        end: {
          line: 87,
          column: 54
        }
      },
      '15': {
        start: {
          line: 89,
          column: 14
        },
        end: {
          line: 89,
          column: 54
        }
      },
      '16': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 93,
          column: 38
        }
      },
      '17': {
        start: {
          line: 102,
          column: 2
        },
        end: {
          line: 104,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 105,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'routeConfig',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 39
          },
          end: {
            line: 100,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 41
          },
          end: {
            line: 18,
            column: 42
          }
        },
        loc: {
          start: {
            line: 18,
            column: 64
          },
          end: {
            line: 28,
            column: 9
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 18
          },
          end: {
            line: 23,
            column: 19
          }
        },
        loc: {
          start: {
            line: 23,
            column: 24
          },
          end: {
            line: 25,
            column: 13
          }
        },
        line: 23
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 25,
            column: 15
          },
          end: {
            line: 25,
            column: 16
          }
        },
        loc: {
          start: {
            line: 25,
            column: 21
          },
          end: {
            line: 27,
            column: 13
          }
        },
        line: 25
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 29,
            column: 35
          },
          end: {
            line: 29,
            column: 36
          }
        },
        loc: {
          start: {
            line: 29,
            column: 53
          },
          end: {
            line: 31,
            column: 9
          }
        },
        line: 29
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 40,
            column: 52
          },
          end: {
            line: 40,
            column: 53
          }
        },
        loc: {
          start: {
            line: 40,
            column: 91
          },
          end: {
            line: 43,
            column: 11
          }
        },
        line: 40
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 72,
            column: 57
          },
          end: {
            line: 72,
            column: 58
          }
        },
        loc: {
          start: {
            line: 72,
            column: 94
          },
          end: {
            line: 91,
            column: 9
          }
        },
        line: 72
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 79,
            column: 40
          },
          end: {
            line: 79,
            column: 41
          }
        },
        loc: {
          start: {
            line: 79,
            column: 65
          },
          end: {
            line: 82,
            column: 15
          }
        },
        line: 79
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 86,
            column: 18
          },
          end: {
            line: 86,
            column: 19
          }
        },
        loc: {
          start: {
            line: 86,
            column: 24
          },
          end: {
            line: 88,
            column: 13
          }
        },
        line: 86
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 88,
            column: 15
          },
          end: {
            line: 88,
            column: 16
          }
        },
        loc: {
          start: {
            line: 88,
            column: 21
          },
          end: {
            line: 90,
            column: 13
          }
        },
        line: 88
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 92,
            column: 35
          },
          end: {
            line: 92,
            column: 36
          }
        },
        loc: {
          start: {
            line: 92,
            column: 53
          },
          end: {
            line: 94,
            column: 9
          }
        },
        line: 92
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1dpam8s7qa.s[0]++;
(function () {
  cov_1dpam8s7qa.f[0]++;

  function routeConfig($stateProvider) {
    cov_1dpam8s7qa.f[1]++;
    cov_1dpam8s7qa.s[1]++;

    $stateProvider.state('service', {
      redirectTo: 'service.all',
      parent: 'app',
      url: '/service',
      template: '<ui-view autoscroll="true"></ui-view>'
    }).state('service.all', {
      url: '/',
      component: 'ticketList'
    }).state('createServiceTicket', {
      parent: 'service.all',
      url: 'create',
      onEnter: ['$uibModal', '$state', function ($uibModal, $state) {
        cov_1dpam8s7qa.f[2]++;

        var modalInstance = (cov_1dpam8s7qa.s[2]++, $uibModal.open({
          component: 'createTicket'
        }));
        cov_1dpam8s7qa.s[3]++;
        modalInstance.result.then(function () {
          cov_1dpam8s7qa.f[3]++;
          cov_1dpam8s7qa.s[4]++;

          $state.go('^');
        }, function () {
          cov_1dpam8s7qa.f[4]++;
          cov_1dpam8s7qa.s[5]++;

          $state.go('^');
        });
      }],
      onExit: ['$uibModalStack', function ($uibModalStack) {
        cov_1dpam8s7qa.f[5]++;
        cov_1dpam8s7qa.s[6]++;

        $uibModalStack.dismissAll();
      }]
    }).state('service.ticket', {
      url: '/ticket/:ticketId',
      component: 'ticket',
      bindings: {
        ticket: 'ticket'
      },
      resolve: {
        ticket: ['TicketService', '$transition$', function (TicketService, $transition$) {
          cov_1dpam8s7qa.f[6]++;

          var id = (cov_1dpam8s7qa.s[7]++, parseFloat($transition$.params().ticketId));
          cov_1dpam8s7qa.s[8]++;
          return TicketService.$getByid(id);
        }]
      }
    })
    // .state('service.ticket.details', {
    //   component: 'ticketDetail',
    //   bindings: {
    //     ticket: 'ticket'
    //   }
    // })
    // .state('service.ticket.lineItems', {
    //   component: 'ticketLineItems',
    //   bindings: {
    //     ticket: 'ticket'
    //   }
    // })
    // .state('service.ticket.comments', {
    //   component: 'comments',
    //   bindings: {
    //     ticketId: 'ticket.$id'
    //   }
    // })
    // .state('service.ticket.activity', {
    //   component: 'activity',
    //   bindings: {
    //     ticketId: 'ticket.$id'
    //   }
    // })
    .state('service.invoice', {
      url: '/ticket/:ticketId/invoice',
      onEnter: ['$uibModal', '$state', '$stateParams', function ($uibModal, $state, $stateParams) {
        cov_1dpam8s7qa.f[7]++;

        var ticketId = (cov_1dpam8s7qa.s[9]++, $stateParams.ticketId);
        var modalInstance = (cov_1dpam8s7qa.s[10]++, $uibModal.open({
          component: 'serviceInvoice',
          windowClass: 'fullscreen',
          animation: false,
          resolve: {
            ticket: ['TicketService', function (TicketService) {
              cov_1dpam8s7qa.f[8]++;

              var id = (cov_1dpam8s7qa.s[11]++, parseFloat(ticketId));
              cov_1dpam8s7qa.s[12]++;
              return TicketService.$getByid(id);
            }]
          }
        }));
        cov_1dpam8s7qa.s[13]++;
        modalInstance.result.then(function () {
          cov_1dpam8s7qa.f[9]++;
          cov_1dpam8s7qa.s[14]++;

          $state.go('service.ticket', { ticketId: ticketId });
        }, function () {
          cov_1dpam8s7qa.f[10]++;
          cov_1dpam8s7qa.s[15]++;

          $state.go('service.ticket', { ticketId: ticketId });
        });
      }],
      onExit: ['$uibModalStack', function ($uibModalStack) {
        cov_1dpam8s7qa.f[11]++;
        cov_1dpam8s7qa.s[16]++;

        $uibModalStack.dismissAll();
      }]
    }).state('printInvoice', {
      url: '/app/service/ticket/:ticketId/print',
      component: 'printInvoice'
    });
  }

  cov_1dpam8s7qa.s[17]++;
  angular.module('dc-service').config(['$stateProvider', routeConfig]);
})();