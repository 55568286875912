'use strict';

var cov_1jd0c6694l = function () {
  var path = '/workdir/src/app/projects/helpers/project-select.component.js',
      hash = '9dbe064a5330612f8ffb4d927e9ca7beb8196a12',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/projects/helpers/project-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 35,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 24
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 36
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 21,
          column: 7
        }
      },
      '6': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 33
        }
      },
      '7': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '8': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 33
        }
      },
      '9': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 31,
          column: 6
        }
      },
      '10': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '11': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 33
        }
      },
      '12': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 34,
          column: 66
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 35,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 32
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 22
          },
          end: {
            line: 17,
            column: 23
          }
        },
        loc: {
          start: {
            line: 17,
            column: 28
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 55
          },
          end: {
            line: 22,
            column: 56
          }
        },
        loc: {
          start: {
            line: 22,
            column: 67
          },
          end: {
            line: 24,
            column: 7
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 27,
            column: 22
          },
          end: {
            line: 27,
            column: 23
          }
        },
        loc: {
          start: {
            line: 27,
            column: 28
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 27
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }],
        line: 18
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }],
        line: 28
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1jd0c6694l.s[0]++;
(function () {
  cov_1jd0c6694l.f[0]++;

  var projectSelect = (cov_1jd0c6694l.s[1]++, {
    bindings: {
      ngModel: '=',
      customerId: '<'
    },
    templateUrl: 'app/projects/helpers/project-select.html',
    controller: controller
  });

  cov_1jd0c6694l.s[2]++;
  controller.$inject = ['Projects'];

  function controller(Projects) {
    cov_1jd0c6694l.f[1]++;

    var self = (cov_1jd0c6694l.s[3]++, this);

    cov_1jd0c6694l.s[4]++;
    self.$onChanges = function () {
      cov_1jd0c6694l.f[2]++;
      cov_1jd0c6694l.s[5]++;

      if (self.projects) {
        cov_1jd0c6694l.b[0][0]++;
        cov_1jd0c6694l.s[6]++;

        // clean up listener before changing customerID
        self.projects.$destroy();
      } else {
        cov_1jd0c6694l.b[0][1]++;
      }
      cov_1jd0c6694l.s[7]++;
      Projects.$getByCustomer(self.customerId).$loaded(function (projects) {
        cov_1jd0c6694l.f[3]++;
        cov_1jd0c6694l.s[8]++;

        self.projects = projects;
      });
    };

    cov_1jd0c6694l.s[9]++;
    self.$onDestroy = function () {
      cov_1jd0c6694l.f[4]++;
      cov_1jd0c6694l.s[10]++;

      if (self.projects) {
        cov_1jd0c6694l.b[1][0]++;
        cov_1jd0c6694l.s[11]++;

        self.projects.$destroy();
      } else {
        cov_1jd0c6694l.b[1][1]++;
      }
    };
  }

  cov_1jd0c6694l.s[12]++;
  angular.module('app').component('projectSelect', projectSelect);
})();