'use strict';

var cov_10bgptx9sx = function () {
  var path = '/workdir/src/app/common/helpers/launch-modal.component.js',
      hash = '36b3914060f93d1743019573f9687992ebdd6554',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/helpers/launch-modal.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 13,
          column: 3
        }
      },
      '2': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 45
        }
      },
      '3': {
        start: {
          line: 18,
          column: 17
        },
        end: {
          line: 18,
          column: 21
        }
      },
      '4': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 39,
          column: 6
        }
      },
      '5': {
        start: {
          line: 20,
          column: 21
        },
        end: {
          line: 24,
          column: 7
        }
      },
      '6': {
        start: {
          line: 25,
          column: 28
        },
        end: {
          line: 32,
          column: 8
        }
      },
      '7': {
        start: {
          line: 29,
          column: 12
        },
        end: {
          line: 29,
          column: 43
        }
      },
      '8': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 38,
          column: 11
        }
      },
      '9': {
        start: {
          line: 35,
          column: 10
        },
        end: {
          line: 35,
          column: 23
        }
      },
      '10': {
        start: {
          line: 42,
          column: 2
        },
        end: {
          line: 44,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 45,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 17,
            column: 11
          },
          end: {
            line: 17,
            column: 21
          }
        },
        loc: {
          start: {
            line: 17,
            column: 39
          },
          end: {
            line: 40,
            column: 3
          }
        },
        line: 17
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 18
          },
          end: {
            line: 19,
            column: 19
          }
        },
        loc: {
          start: {
            line: 19,
            column: 24
          },
          end: {
            line: 39,
            column: 5
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 28,
            column: 22
          },
          end: {
            line: 28,
            column: 23
          }
        },
        loc: {
          start: {
            line: 28,
            column: 28
          },
          end: {
            line: 30,
            column: 11
          }
        },
        line: 28
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 34,
            column: 14
          },
          end: {
            line: 34,
            column: 15
          }
        },
        loc: {
          start: {
            line: 34,
            column: 20
          },
          end: {
            line: 36,
            column: 9
          }
        },
        line: 34
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 36,
            column: 11
          },
          end: {
            line: 36,
            column: 12
          }
        },
        loc: {
          start: {
            line: 36,
            column: 17
          },
          end: {
            line: 38,
            column: 9
          }
        },
        line: 36
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 29,
            column: 19
          },
          end: {
            line: 29,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 19
          },
          end: {
            line: 29,
            column: 34
          }
        }, {
          start: {
            line: 29,
            column: 38
          },
          end: {
            line: 29,
            column: 42
          }
        }],
        line: 29
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_10bgptx9sx.s[0]++;
(function () {
  cov_10bgptx9sx.f[0]++;

  var launchModal = (cov_10bgptx9sx.s[1]++, {
    bindings: {
      modal: '@',
      classes: '@',
      customerId: '<'
    },
    template: '\n    <a ng-click="$ctrl.launch()" href="" ng-class="{{$ctrl.classes}}" ng-transclude></a>',
    transclude: true,
    controller: controller
  });

  cov_10bgptx9sx.s[2]++;
  controller.$inject = ['$uibModal', '$log'];

  function controller($uibModal, $log) {
    cov_10bgptx9sx.f[1]++;

    var self = (cov_10bgptx9sx.s[3]++, this);
    cov_10bgptx9sx.s[4]++;
    self.launch = function () {
      cov_10bgptx9sx.f[2]++;

      var modals = (cov_10bgptx9sx.s[5]++, {
        contact: 'createContact',
        customer: 'createCustomer',
        site: 'createSite'
      });
      var modalInstance = (cov_10bgptx9sx.s[6]++, $uibModal.open({
        component: modals[self.modal],
        resolve: {
          customerId: function customerId() {
            cov_10bgptx9sx.f[3]++;
            cov_10bgptx9sx.s[7]++;

            return (cov_10bgptx9sx.b[0][0]++, self.customerId) || (cov_10bgptx9sx.b[0][1]++, null);
          }
        }
      }));
      cov_10bgptx9sx.s[8]++;
      modalInstance.result.then(function (ok) {
        cov_10bgptx9sx.f[4]++;
        cov_10bgptx9sx.s[9]++;

        $log.log(ok);
      }, function () {
        // modal dismiss promise resolves here.

        cov_10bgptx9sx.f[5]++;
      });
    };
  }

  cov_10bgptx9sx.s[10]++;
  angular.module('dc-common').component('launchModal', launchModal);
})();