'use strict';

var cov_duilc3lcb = function () {
  var path = '/workdir/src/app/common/comment/comment-list/comment-list-item.component.js',
      hash = '8eee3f1d22b374c2722c751049a05c4736fda765',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/comment/comment-list/comment-list-item.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 34,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 26
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 51
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 23
        }
      },
      '5': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 42
        }
      },
      '6': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '7': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 49
        }
      },
      '8': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '9': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 44
        }
      },
      '10': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 28,
          column: 6
        }
      },
      '11': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 42
        }
      },
      '12': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 33,
          column: 51
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 34,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 45
          },
          end: {
            line: 29,
            column: 3
          }
        },
        line: 13
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 22,
            column: 23
          },
          end: {
            line: 22,
            column: 24
          }
        },
        loc: {
          start: {
            line: 22,
            column: 29
          },
          end: {
            line: 24,
            column: 5
          }
        },
        line: 22
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 26,
            column: 25
          },
          end: {
            line: 26,
            column: 26
          }
        },
        loc: {
          start: {
            line: 26,
            column: 31
          },
          end: {
            line: 28,
            column: 5
          }
        },
        line: 26
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        }, {
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        }],
        line: 18
      },
      '1': {
        loc: {
          start: {
            line: 18,
            column: 8
          },
          end: {
            line: 18,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 18,
            column: 8
          },
          end: {
            line: 18,
            column: 20
          }
        }, {
          start: {
            line: 18,
            column: 24
          },
          end: {
            line: 18,
            column: 42
          }
        }],
        line: 18
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_duilc3lcb.s[0]++;
(function () {
  cov_duilc3lcb.f[0]++;

  var commentListItem = (cov_duilc3lcb.s[1]++, {
    bindings: {
      message: '<'
    },
    templateUrl: 'app/common/comment/comment-list/comment-list-item.html',
    controller: controller
  });

  cov_duilc3lcb.s[2]++;
  controller.$inject = ['Users', 'CommentService'];

  function controller(Users, CommentService) {
    cov_duilc3lcb.f[1]++;

    var self = (cov_duilc3lcb.s[3]++, this);
    cov_duilc3lcb.s[4]++;
    self.reply = false;
    cov_duilc3lcb.s[5]++;
    self.userId = Users.currentUser().$id;

    cov_duilc3lcb.s[6]++;
    if ((cov_duilc3lcb.b[1][0]++, self.message) && (cov_duilc3lcb.b[1][1]++, self.message.likes)) {
      cov_duilc3lcb.b[0][0]++;
      cov_duilc3lcb.s[7]++;

      self.likeCount = self.message.likes.length;
    } else {
      cov_duilc3lcb.b[0][1]++;
    }

    cov_duilc3lcb.s[8]++;
    self.likeMessage = function () {
      cov_duilc3lcb.f[2]++;
      cov_duilc3lcb.s[9]++;

      CommentService.like(self.message.$id);
    };

    cov_duilc3lcb.s[10]++;
    self.deleteMessage = function () {
      cov_duilc3lcb.f[3]++;
      cov_duilc3lcb.s[11]++;

      CommentService.remove(self.message);
    };
  }

  cov_duilc3lcb.s[12]++;
  angular.module('dc-comments').component('commentListItem', commentListItem);
})();