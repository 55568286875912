'use strict';

var cov_efewge0iy = function () {
  var path = '/workdir/src/app/common/contacts/helpers/contact-select.component.js',
      hash = '23ffbae575dd990355501b0b246cd3be0fcaaa03',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/contacts/helpers/contact-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 24
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 42
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 21,
          column: 7
        }
      },
      '6': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 28
        }
      },
      '7': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 26,
          column: 11
        }
      },
      '8': {
        start: {
          line: 25,
          column: 10
        },
        end: {
          line: 25,
          column: 35
        }
      },
      '9': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 32,
          column: 47
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 33,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 21
          }
        },
        loc: {
          start: {
            line: 15,
            column: 38
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 22
          },
          end: {
            line: 18,
            column: 23
          }
        },
        loc: {
          start: {
            line: 18,
            column: 28
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 17
          },
          end: {
            line: 24,
            column: 18
          }
        },
        loc: {
          start: {
            line: 24,
            column: 29
          },
          end: {
            line: 26,
            column: 9
          }
        },
        line: 24
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }, {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }],
        line: 19
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_efewge0iy.s[0]++;
(function () {
  cov_efewge0iy.f[0]++;

  var contactSelect = (cov_efewge0iy.s[1]++, {
    bindings: {
      ngModel: '=',
      required: '=',
      customerId: '<'
    },
    templateUrl: 'app/common/contacts/helpers/contact-select.html',
    controller: controller
  });

  cov_efewge0iy.s[2]++;
  controller.$inject = ['ContactService'];

  function controller(ContactService) {
    cov_efewge0iy.f[1]++;

    var self = (cov_efewge0iy.s[3]++, this);

    cov_efewge0iy.s[4]++;
    self.$onChanges = function () {
      cov_efewge0iy.f[2]++;
      cov_efewge0iy.s[5]++;

      if (self.ngModel) {
        cov_efewge0iy.b[0][0]++;
        cov_efewge0iy.s[6]++;

        self.ngModel = null;
      } else {
        cov_efewge0iy.b[0][1]++;
      }
      // TODO: form validation required
      cov_efewge0iy.s[7]++;
      ContactService.$getByCustomer(self.customerId).$loaded(function (contacts) {
        cov_efewge0iy.f[3]++;
        cov_efewge0iy.s[8]++;

        self.contacts = contacts;
      });
    };
  }

  cov_efewge0iy.s[9]++;
  angular.module('dc-contacts').component('contactSelect', contactSelect);
})();