'use strict';

var cov_170ihuwxak = function () {
  var path = '/workdir/src/services/messages.js',
      hash = 'd7ab3f4ce09dd1f1997777e65844b396a710789f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/services/messages.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 106,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '2': {
        start: {
          line: 6,
          column: 19
        },
        end: {
          line: 6,
          column: 23
        }
      },
      '3': {
        start: {
          line: 7,
          column: 25
        },
        end: {
          line: 7,
          column: 29
        }
      },
      '4': {
        start: {
          line: 9,
          column: 20
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '5': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 19
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 24,
          column: 7
        }
      },
      '7': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 48
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 22
        }
      },
      '9': {
        start: {
          line: 29,
          column: 25
        },
        end: {
          line: 29,
          column: 47
        }
      },
      '10': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 31
        }
      },
      '11': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 40
        }
      },
      '12': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 42,
          column: 11
        }
      },
      '13': {
        start: {
          line: 34,
          column: 27
        },
        end: {
          line: 40,
          column: 11
        }
      },
      '14': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 41,
          column: 40
        }
      },
      '15': {
        start: {
          line: 46,
          column: 19
        },
        end: {
          line: 46,
          column: 42
        }
      },
      '16': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 51,
          column: 17
        }
      },
      '17': {
        start: {
          line: 64,
          column: 23
        },
        end: {
          line: 70,
          column: 7
        }
      },
      '18': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 74,
          column: 11
        }
      },
      '19': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 73,
          column: 40
        }
      },
      '20': {
        start: {
          line: 78,
          column: 20
        },
        end: {
          line: 78,
          column: 22
        }
      },
      '21': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '22': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 73
        }
      },
      '23': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 28
        }
      },
      '24': {
        start: {
          line: 86,
          column: 32
        },
        end: {
          line: 88,
          column: 27
        }
      },
      '25': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 89,
          column: 47
        }
      },
      '26': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 96,
          column: 7
        }
      },
      '27': {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 94,
          column: 28
        }
      },
      '28': {
        start: {
          line: 95,
          column: 8
        },
        end: {
          line: 95,
          column: 24
        }
      },
      '29': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 100,
          column: 7
        }
      },
      '30': {
        start: {
          line: 98,
          column: 8
        },
        end: {
          line: 98,
          column: 34
        }
      },
      '31': {
        start: {
          line: 99,
          column: 8
        },
        end: {
          line: 99,
          column: 30
        }
      },
      '32': {
        start: {
          line: 104,
          column: 2
        },
        end: {
          line: 105,
          column: 99
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 106,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'Messages',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 19
          }
        },
        loc: {
          start: {
            line: 4,
            column: 72
          },
          end: {
            line: 102,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: 'all',
        decl: {
          start: {
            line: 21,
            column: 13
          },
          end: {
            line: 21,
            column: 16
          }
        },
        loc: {
          start: {
            line: 21,
            column: 19
          },
          end: {
            line: 26,
            column: 5
          }
        },
        line: 21
      },
      '3': {
        name: 'add',
        decl: {
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 28,
            column: 16
          }
        },
        loc: {
          start: {
            line: 28,
            column: 26
          },
          end: {
            line: 43,
            column: 5
          }
        },
        line: 28
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 33,
            column: 22
          },
          end: {
            line: 33,
            column: 23
          }
        },
        loc: {
          start: {
            line: 33,
            column: 28
          },
          end: {
            line: 42,
            column: 9
          }
        },
        line: 33
      },
      '5': {
        name: 'like',
        decl: {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 45,
            column: 17
          }
        },
        loc: {
          start: {
            line: 45,
            column: 30
          },
          end: {
            line: 61,
            column: 5
          }
        },
        line: 45
      },
      '6': {
        name: 'remove',
        decl: {
          start: {
            line: 63,
            column: 13
          },
          end: {
            line: 63,
            column: 19
          }
        },
        loc: {
          start: {
            line: 63,
            column: 29
          },
          end: {
            line: 75,
            column: 5
          }
        },
        line: 63
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 72,
            column: 16
          },
          end: {
            line: 72,
            column: 17
          }
        },
        loc: {
          start: {
            line: 72,
            column: 22
          },
          end: {
            line: 74,
            column: 9
          }
        },
        line: 72
      },
      '8': {
        name: 'recent',
        decl: {
          start: {
            line: 77,
            column: 13
          },
          end: {
            line: 77,
            column: 19
          }
        },
        loc: {
          start: {
            line: 77,
            column: 22
          },
          end: {
            line: 83,
            column: 5
          }
        },
        line: 77
      },
      '9': {
        name: 'getByProject',
        decl: {
          start: {
            line: 85,
            column: 13
          },
          end: {
            line: 85,
            column: 25
          }
        },
        loc: {
          start: {
            line: 85,
            column: 37
          },
          end: {
            line: 90,
            column: 5
          }
        },
        line: 85
      },
      '10': {
        name: 'reset',
        decl: {
          start: {
            line: 92,
            column: 13
          },
          end: {
            line: 92,
            column: 18
          }
        },
        loc: {
          start: {
            line: 92,
            column: 21
          },
          end: {
            line: 101,
            column: 5
          }
        },
        line: 92
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }, {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 79,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }, {
          start: {
            line: 79,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }],
        line: 79
      },
      '2': {
        loc: {
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 96,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 96,
            column: 7
          }
        }, {
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 96,
            column: 7
          }
        }],
        line: 93
      },
      '3': {
        loc: {
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }, {
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }],
        line: 97
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_170ihuwxak.s[0]++;
(function () {
  cov_170ihuwxak.f[0]++;

  // define function Messages
  function Messages($db, Utils, ActivityService, $firebaseArray, Users) {
    cov_170ihuwxak.f[1]++;

    var messagesRef = (cov_170ihuwxak.s[1]++, $db.messages);
    var messages = (cov_170ihuwxak.s[2]++, null);
    var recentMessages = (cov_170ihuwxak.s[3]++, null);
    // service definition
    var service = (cov_170ihuwxak.s[4]++, {
      all: all,
      add: add,
      like: like,
      remove: remove,
      recent: recent,
      getByProject: getByProject,
      reset: reset
    });

    cov_170ihuwxak.s[5]++;
    return service;

    function all() {
      cov_170ihuwxak.f[2]++;
      cov_170ihuwxak.s[6]++;

      if (!messages) {
        cov_170ihuwxak.b[0][0]++;
        cov_170ihuwxak.s[7]++;

        messages = $firebaseArray($db.messages);
      } else {
        cov_170ihuwxak.b[0][1]++;
      }
      cov_170ihuwxak.s[8]++;
      return messages;
    }

    function add(message) {
      cov_170ihuwxak.f[3]++;

      var messageRef = (cov_170ihuwxak.s[9]++, messagesRef.push().key);
      cov_170ihuwxak.s[10]++;
      message.uid = messageRef;
      cov_170ihuwxak.s[11]++;
      message.createdDate = Utils.now();
      cov_170ihuwxak.s[12]++;
      return $db.messages.child(messageRef).set(message, function () {
        cov_170ihuwxak.f[4]++;

        var activity = (cov_170ihuwxak.s[13]++, {
          userId: message.senderId,
          project: message.project,
          type: 'Message',
          action: 'Created',
          link: message.uid
        });
        cov_170ihuwxak.s[14]++;
        ActivityService.add(activity);
      });
    }

    function like(messageRef) {
      cov_170ihuwxak.f[5]++;

      var user = (cov_170ihuwxak.s[15]++, Users.currentUser().$id);
      // $db.messages.child(messageRef).child('likes').child()
      cov_170ihuwxak.s[16]++;
      return $db.messages.child(messageRef).child('likes').child(user).set(true);
      // .transaction(current => {
      //   // const activity = {
      //   //   type: 'Message',
      //   //   action: 'Liked',
      //   //   link: message.uid
      //   // };
      //   // ActivityService.add(activity);
      //   return (current || 0) + 1;
      // });
    }

    function remove(message) {
      cov_170ihuwxak.f[6]++;

      var activity = (cov_170ihuwxak.s[17]++, {
        userId: message.senderId,
        project: message.project,
        type: 'Message',
        action: 'Deleted',
        link: message.uid
      });
      cov_170ihuwxak.s[18]++;
      return $db.messages.child(message.uid).remove(function () {
        cov_170ihuwxak.f[7]++;
        cov_170ihuwxak.s[19]++;

        ActivityService.add(activity);
      });
    }

    function recent() {
      cov_170ihuwxak.f[8]++;

      var limit = (cov_170ihuwxak.s[20]++, 10);
      cov_170ihuwxak.s[21]++;
      if (!recentMessages) {
        cov_170ihuwxak.b[1][0]++;
        cov_170ihuwxak.s[22]++;

        recentMessages = $firebaseArray($db.messages.limitToLast(limit));
      } else {
        cov_170ihuwxak.b[1][1]++;
      }
      cov_170ihuwxak.s[23]++;
      return recentMessages;
    }

    function getByProject(projectID) {
      cov_170ihuwxak.f[9]++;

      var messagesByProject = (cov_170ihuwxak.s[24]++, messagesRef.orderByChild('project').equalTo(projectID));
      cov_170ihuwxak.s[25]++;
      return $firebaseArray(messagesByProject);
    }

    function reset() {
      cov_170ihuwxak.f[10]++;
      cov_170ihuwxak.s[26]++;

      if (messages) {
        cov_170ihuwxak.b[2][0]++;
        cov_170ihuwxak.s[27]++;

        messages.$destroy();
        cov_170ihuwxak.s[28]++;
        messages = null;
      } else {
        cov_170ihuwxak.b[2][1]++;
      }
      cov_170ihuwxak.s[29]++;
      if (recentMessages) {
        cov_170ihuwxak.b[3][0]++;
        cov_170ihuwxak.s[30]++;

        recentMessages.$destroy();
        cov_170ihuwxak.s[31]++;
        recentMessages = null;
      } else {
        cov_170ihuwxak.b[3][1]++;
      }
    }
  }

  cov_170ihuwxak.s[32]++;
  angular.module('app').factory('Messages', ['$db', 'Utils', 'ActivityService', '$firebaseArray', 'Users', Messages]);
})();