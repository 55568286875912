'use strict';

var cov_1pu71mhpin = function () {
  var path = '/workdir/src/app/common/settings/settings.component.js',
      hash = '8f5d6ae85ba1b4fdb13c7bb0d81481358318729d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/settings/settings.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 42
        }
      },
      '3': {
        start: {
          line: 10,
          column: 17
        },
        end: {
          line: 10,
          column: 21
        }
      },
      '4': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 19,
          column: 6
        }
      },
      '5': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 14,
          column: 9
        }
      },
      '6': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 13,
          column: 34
        }
      },
      '7': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 17,
          column: 9
        }
      },
      '8': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 27
        }
      },
      '9': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 28
        }
      },
      '10': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '11': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 28
        }
      },
      '12': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 28
        }
      },
      '13': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 66
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 28,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 9,
            column: 11
          },
          end: {
            line: 9,
            column: 21
          }
        },
        loc: {
          start: {
            line: 9,
            column: 38
          },
          end: {
            line: 25,
            column: 3
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 11,
            column: 19
          },
          end: {
            line: 11,
            column: 20
          }
        },
        loc: {
          start: {
            line: 11,
            column: 25
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 11
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 12,
            column: 51
          },
          end: {
            line: 12,
            column: 52
          }
        },
        loc: {
          start: {
            line: 12,
            column: 60
          },
          end: {
            line: 14,
            column: 7
          }
        },
        line: 12
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 15,
            column: 51
          },
          end: {
            line: 15,
            column: 52
          }
        },
        loc: {
          start: {
            line: 15,
            column: 60
          },
          end: {
            line: 17,
            column: 7
          }
        },
        line: 15
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 21,
            column: 19
          },
          end: {
            line: 21,
            column: 20
          }
        },
        loc: {
          start: {
            line: 21,
            column: 27
          },
          end: {
            line: 24,
            column: 5
          }
        },
        line: 21
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1pu71mhpin.s[0]++;
(function () {
  cov_1pu71mhpin.f[0]++;

  var dcSettings = (cov_1pu71mhpin.s[1]++, {
    templateUrl: 'app/common/settings/settings.html',
    controller: controller
  });

  cov_1pu71mhpin.s[2]++;
  controller.$inject = ['ClientSettings'];
  function controller(ClientSettings) {
    cov_1pu71mhpin.f[1]++;

    var self = (cov_1pu71mhpin.s[3]++, this);
    cov_1pu71mhpin.s[4]++;
    self.$onInit = function () {
      cov_1pu71mhpin.f[2]++;
      cov_1pu71mhpin.s[5]++;

      ClientSettings.billingCodes().$loaded().then(function (codes) {
        cov_1pu71mhpin.f[3]++;
        cov_1pu71mhpin.s[6]++;

        self.billingCodes = codes;
      });
      cov_1pu71mhpin.s[7]++;
      ClientSettings.serviceRates().$loaded().then(function (rates) {
        cov_1pu71mhpin.f[4]++;
        cov_1pu71mhpin.s[8]++;

        self.rates = rates;
      });
      cov_1pu71mhpin.s[9]++;
      self.serviceRate = {};
    };

    cov_1pu71mhpin.s[10]++;
    self.addRate = function (rate) {
      cov_1pu71mhpin.f[5]++;
      cov_1pu71mhpin.s[11]++;

      self.rates.$add(rate);
      cov_1pu71mhpin.s[12]++;
      self.serviceRate = {};
    };
  }

  cov_1pu71mhpin.s[13]++;
  angular.module('dc-common').component('dcSettings', dcSettings);
})();