'use strict';

var cov_25lxcqz1wy = function () {
  var path = '/workdir/src/app/projects/helpers/project-count.component.js',
      hash = 'ec50b793dcd56d15367e54b76c2f8e4065858b36',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/projects/helpers/project-count.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 40,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 36
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 28
        }
      },
      '5': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 28,
          column: 6
        }
      },
      '6': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '7': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 26,
          column: 11
        }
      },
      '8': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 20,
          column: 35
        }
      },
      '9': {
        start: {
          line: 21,
          column: 10
        },
        end: {
          line: 25,
          column: 13
        }
      },
      '10': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 24,
          column: 13
        }
      },
      '11': {
        start: {
          line: 23,
          column: 14
        },
        end: {
          line: 23,
          column: 36
        }
      },
      '12': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 34,
          column: 6
        }
      },
      '13': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '14': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 33
        }
      },
      '15': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 39,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 40,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 32
          },
          end: {
            line: 35,
            column: 3
          }
        },
        line: 13
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 22
          },
          end: {
            line: 17,
            column: 23
          }
        },
        loc: {
          start: {
            line: 17,
            column: 28
          },
          end: {
            line: 28,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 19,
            column: 57
          },
          end: {
            line: 19,
            column: 58
          }
        },
        loc: {
          start: {
            line: 19,
            column: 69
          },
          end: {
            line: 26,
            column: 9
          }
        },
        line: 19
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 21,
            column: 27
          },
          end: {
            line: 21,
            column: 28
          }
        },
        loc: {
          start: {
            line: 21,
            column: 38
          },
          end: {
            line: 25,
            column: 11
          }
        },
        line: 21
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 30,
            column: 22
          },
          end: {
            line: 30,
            column: 23
          }
        },
        loc: {
          start: {
            line: 30,
            column: 28
          },
          end: {
            line: 34,
            column: 5
          }
        },
        line: 30
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }],
        line: 18
      },
      '1': {
        loc: {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 24,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 24,
            column: 13
          }
        }, {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 24,
            column: 13
          }
        }],
        line: 22
      },
      '2': {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }],
        line: 31
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_25lxcqz1wy.s[0]++;
(function () {
  cov_25lxcqz1wy.f[0]++;

  var projectCount = (cov_25lxcqz1wy.s[1]++, {
    bindings: {
      customerId: '<'
    },
    template: '<span uib-tooltip="Active / Total">{{$ctrl.activeProjects}}/{{$ctrl.projects.length}}</span>',
    controller: controller
  });

  cov_25lxcqz1wy.s[2]++;
  controller.$inject = ['Projects'];

  function controller(Projects) {
    cov_25lxcqz1wy.f[1]++;

    var self = (cov_25lxcqz1wy.s[3]++, this);
    cov_25lxcqz1wy.s[4]++;
    self.activeProjects = 0;

    cov_25lxcqz1wy.s[5]++;
    self.$onChanges = function () {
      cov_25lxcqz1wy.f[2]++;
      cov_25lxcqz1wy.s[6]++;

      if (self.customerId) {
        cov_25lxcqz1wy.b[0][0]++;
        cov_25lxcqz1wy.s[7]++;

        Projects.$getByCustomer(self.customerId).$loaded(function (projects) {
          cov_25lxcqz1wy.f[3]++;
          cov_25lxcqz1wy.s[8]++;

          self.projects = projects;
          cov_25lxcqz1wy.s[9]++;
          projects.forEach(function (project) {
            cov_25lxcqz1wy.f[4]++;
            cov_25lxcqz1wy.s[10]++;

            if (project.active === true) {
              cov_25lxcqz1wy.b[1][0]++;
              cov_25lxcqz1wy.s[11]++;

              self.activeProjects++;
            } else {
              cov_25lxcqz1wy.b[1][1]++;
            }
          });
        });
      } else {
        cov_25lxcqz1wy.b[0][1]++;
      }
    };

    cov_25lxcqz1wy.s[12]++;
    self.$onDestroy = function () {
      cov_25lxcqz1wy.f[5]++;
      cov_25lxcqz1wy.s[13]++;

      if (self.projects) {
        cov_25lxcqz1wy.b[2][0]++;
        cov_25lxcqz1wy.s[14]++;

        self.projects.$destroy();
      } else {
        cov_25lxcqz1wy.b[2][1]++;
      }
    };
  }

  cov_25lxcqz1wy.s[15]++;
  angular.module('app').component('projectCount', projectCount);
})();