'use strict';

var cov_fens6wt64 = function () {
  var path = '/workdir/src/services/extends/project.js',
      hash = 'b24436f680662e93adbb3bde286f1077dc6423e4',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/services/extends/project.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 20
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '2': {
        start: {
          line: 8,
          column: 8
        },
        end: {
          line: 8,
          column: 29
        }
      },
      '3': {
        start: {
          line: 15,
          column: 24
        },
        end: {
          line: 15,
          column: 82
        }
      },
      '4': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 23
        }
      },
      '5': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '6': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 34
        }
      },
      '7': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 37,
          column: 56
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 38,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '$project',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 19
          }
        },
        loc: {
          start: {
            line: 4,
            column: 37
          },
          end: {
            line: 34,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 30,
            column: 11
          },
          end: {
            line: 30,
            column: 12
          }
        },
        loc: {
          start: {
            line: 30,
            column: 30
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 30
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_fens6wt64.s[0]++;
(function () {
  cov_fens6wt64.f[0]++;

  // defines function Project
  function $project($firebaseObject) {
    cov_fens6wt64.f[1]++;

    var Project = (cov_fens6wt64.s[1]++, $firebaseObject.$extend({
      // add a method to the prototype that returns our counter
      getUpdateCount: function getUpdateCount() {
        cov_fens6wt64.s[2]++;

        return this._counter;
      },

      // each time an update arrives from the server, apply the change locally
      /* eslint-disable angular/no-private-call */
      $$updated: function $$updated() {
        // pass in snap.
        // apply the changes using the super method
        var changed = (cov_fens6wt64.s[3]++, $firebaseObject.prototype.$$updated.apply(this, arguments));

        // add / increment a counter each time there is an update
        // if (!this._counter) {
        //   this._counter = 0;
        // }
        // $log.log(this._counter);
        // this._counter++;

        // return whether or not changes occurred
        cov_fens6wt64.s[4]++;
        return changed;
      }
    }));
    /* eslint-enable angular/no-private-call */

    cov_fens6wt64.s[5]++;
    return function (listRef) {
      cov_fens6wt64.f[2]++;
      cov_fens6wt64.s[6]++;

      // create an instance of ListWithTotal (the new operator is required)
      return new Project(listRef);
    };
  }

  cov_fens6wt64.s[7]++;
  angular.module('app').factory('$project', ['$firebaseObject', $project]);
})();