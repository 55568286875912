'use strict';

var cov_csy2f1bu6 = function () {
  var path = '/workdir/src/app/projects/project-list/project-list.js',
      hash = 'faff095018b1d29fe7b3432708eed2902a146634',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/projects/project-list/project-list.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 70,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 45
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 17,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 27
        }
      },
      '6': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 29
        }
      },
      '7': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 37
        }
      },
      '8': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 22,
          column: 26
        }
      },
      '9': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 39
        }
      },
      '10': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 35
        }
      },
      '11': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 45
        }
      },
      '12': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '13': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 34
        }
      },
      '14': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 53
        }
      },
      '15': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 21
        }
      },
      '16': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 19
        }
      },
      '17': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '18': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 67
        }
      },
      '19': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 49,
          column: 7
        }
      },
      '20': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 26
        }
      },
      '21': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 34
        }
      },
      '22': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 55,
          column: 11
        }
      },
      '23': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 54,
          column: 35
        }
      },
      '24': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 69,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 70,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 39
          },
          end: {
            line: 65,
            column: 3
          }
        },
        line: 13
      },
      '2': {
        name: 'archive',
        decl: {
          start: {
            line: 24,
            column: 13
          },
          end: {
            line: 24,
            column: 20
          }
        },
        loc: {
          start: {
            line: 24,
            column: 30
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 24
      },
      '3': {
        name: 'onInit',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 19
          }
        },
        loc: {
          start: {
            line: 29,
            column: 22
          },
          end: {
            line: 37,
            column: 5
          }
        },
        line: 29
      },
      '4': {
        name: 'checkForPM',
        decl: {
          start: {
            line: 39,
            column: 13
          },
          end: {
            line: 39,
            column: 23
          }
        },
        loc: {
          start: {
            line: 39,
            column: 26
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 39
      },
      '5': {
        name: 'grabProjects',
        decl: {
          start: {
            line: 46,
            column: 13
          },
          end: {
            line: 46,
            column: 25
          }
        },
        loc: {
          start: {
            line: 46,
            column: 34
          },
          end: {
            line: 56,
            column: 5
          }
        },
        line: 46
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 53,
            column: 14
          },
          end: {
            line: 53,
            column: 15
          }
        },
        loc: {
          start: {
            line: 53,
            column: 26
          },
          end: {
            line: 55,
            column: 9
          }
        },
        line: 53
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }],
        line: 31
      },
      '1': {
        loc: {
          start: {
            line: 34,
            column: 26
          },
          end: {
            line: 34,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 34,
            column: 26
          },
          end: {
            line: 34,
            column: 43
          }
        }, {
          start: {
            line: 34,
            column: 47
          },
          end: {
            line: 34,
            column: 52
          }
        }],
        line: 34
      },
      '2': {
        loc: {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }, {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }],
        line: 41
      },
      '3': {
        loc: {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }, {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }],
        line: 47
      },
      '4': {
        loc: {
          start: {
            line: 47,
            column: 10
          },
          end: {
            line: 47,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 47,
            column: 10
          },
          end: {
            line: 47,
            column: 17
          }
        }, {
          start: {
            line: 47,
            column: 21
          },
          end: {
            line: 47,
            column: 42
          }
        }],
        line: 47
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_csy2f1bu6.s[0]++;
(function () {
  cov_csy2f1bu6.f[0]++;

  var projectList = (cov_csy2f1bu6.s[1]++, {
    bindings: {
      filterByUser: '<?'
    },
    templateUrl: 'app/projects/project-list/project-list.html',
    controller: controller
  });

  cov_csy2f1bu6.s[2]++;
  controller.$inject = ['Projects', 'Users'];

  function controller(Projects, Users) {
    cov_csy2f1bu6.f[1]++;

    var self = (cov_csy2f1bu6.s[3]++, this);
    cov_csy2f1bu6.s[4]++;
    self.filter = {
      metadata: {}
    };

    cov_csy2f1bu6.s[5]++;
    self.archive = archive;
    cov_csy2f1bu6.s[6]++;
    self.unarchive = archive;
    cov_csy2f1bu6.s[7]++;
    self.grabProjects = grabProjects;
    cov_csy2f1bu6.s[8]++;
    self.$onInit = onInit;

    function archive(project) {
      cov_csy2f1bu6.f[2]++;
      cov_csy2f1bu6.s[9]++;

      project.active = !project.active;
      cov_csy2f1bu6.s[10]++;
      self.projects.$save(project);
    }

    function onInit() {
      cov_csy2f1bu6.f[3]++;
      cov_csy2f1bu6.s[11]++;

      self.currentUser = Users.currentUser();
      cov_csy2f1bu6.s[12]++;
      if (angular.isUndefined(self.filterByUser)) {
        cov_csy2f1bu6.b[0][0]++;
        cov_csy2f1bu6.s[13]++;

        self.filterByUser = false;
      } else {
        cov_csy2f1bu6.b[0][1]++;
      }
      cov_csy2f1bu6.s[14]++;
      self.filterByUser = (cov_csy2f1bu6.b[1][0]++, self.filterByUser) || (cov_csy2f1bu6.b[1][1]++, false);
      cov_csy2f1bu6.s[15]++;
      grabProjects();
      cov_csy2f1bu6.s[16]++;
      checkForPM();
    }

    function checkForPM() {
      cov_csy2f1bu6.f[4]++;
      cov_csy2f1bu6.s[17]++;

      // checks user roles and sets filter to PM
      if (self.currentUser.roles.pm) {
        cov_csy2f1bu6.b[2][0]++;
        cov_csy2f1bu6.s[18]++;

        self.filter.metadata.projectManager = self.currentUser.$id;
      } else {
        cov_csy2f1bu6.b[2][1]++;
      }
    }

    function grabProjects(status) {
      cov_csy2f1bu6.f[5]++;
      cov_csy2f1bu6.s[19]++;

      if ((cov_csy2f1bu6.b[4][0]++, !status) || (cov_csy2f1bu6.b[4][1]++, status !== 'inactive')) {
        cov_csy2f1bu6.b[3][0]++;
        cov_csy2f1bu6.s[20]++;

        status = 'active';
      } else {
        cov_csy2f1bu6.b[3][1]++;
      }
      cov_csy2f1bu6.s[21]++;
      self.projectStatus = status;
      cov_csy2f1bu6.s[22]++;
      Projects.$getByStatus(status).$loaded().then(function (projects) {
        cov_csy2f1bu6.f[6]++;
        cov_csy2f1bu6.s[23]++;

        self.projects = projects;
      });
    }

    // if (self.filterByUser) {
    //   const username = Users.currentUser().profile.username;
    //   Projects.getByUser(username)
    //     .$loaded(projects => {
    //       self.projects = projects;
    //     });
    // }
  }

  cov_csy2f1bu6.s[24]++;
  angular.module('app').component('projectList', projectList);
})();