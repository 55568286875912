// angular.module('app')
// .controller('ImageUpload', ['$scope', '$log', 'Auth',
//   function ImageUpload($scope, $log, Auth) {
//     $scope.upload_image = function (image) {
//       if (!image.valid) return;

//       var imagesRef, safename, imageUpload;

//       const user = Auth.$getAuth();

//       console.log(user);

//       image.isUploading = true;
//       imageUpload = {
//         isUploading: true,
//         data: image.data,
//         thumbnail: image.thumbnail,
//         name: image.filename,
//         author: {
//           provider: user.provider,
//           id: user.id
//         }
//       };

//       safename = imageUpload.name.replace(/\.|\#|\$|\[|\]|-|\//g, "");
//       imagesRef = new Firebase($scope.firebaseUrl + '/images');

//       imagesRef.child(safename).set(imageUpload, function (err) {
//         if (!err) {
//           imagesRef.child(safename).child('isUploading').remove();
//           $scope.$apply(function () {
//             $scope.status = 'Your image "' + image.filename + '" has been successfully uploaded!';
//             if ($scope.uploaded_callback !== undefined) {
//               $scope.uploaded_callback(angular.copy(imageUpload));
//             }
//             image.isUploading = false;
//             image.data = undefined;
//             image.filename = undefined;
//           });
//         }else{
//           $scope.error = 'There was an error while uploading your image: ' + err;
//         }
//       });
//     };
//   }
// ]);
"use strict";

var cov_ybnj7qy2a = function () {
  var path = "/workdir/src/directives/image-upload.js",
      hash = "ec1c934c472482aa669dbdbd8423b22988de1ec2",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/workdir/src/directives/image-upload.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();