'use strict';

var cov_1gv911wszk = function () {
  var path = '/workdir/src/app/common/storage.service.js',
      hash = 'fe006ff1974de2e0f3540c5857a6b03e9a21f228',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/storage.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 29,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 23
        },
        end: {
          line: 4,
          column: 47
        }
      },
      '2': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 10,
          column: 5
        }
      },
      '3': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 19
        }
      },
      '4': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 78
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 75
        }
      },
      '6': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 75
        }
      },
      '7': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 28,
          column: 69
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 29,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'StorageService',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 25
          }
        },
        loc: {
          start: {
            line: 3,
            column: 44
          },
          end: {
            line: 25,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: 'signatures',
        decl: {
          start: {
            line: 14,
            column: 13
          },
          end: {
            line: 14,
            column: 23
          }
        },
        loc: {
          start: {
            line: 14,
            column: 34
          },
          end: {
            line: 16,
            column: 5
          }
        },
        line: 14
      },
      '3': {
        name: 'avatars',
        decl: {
          start: {
            line: 18,
            column: 13
          },
          end: {
            line: 18,
            column: 20
          }
        },
        loc: {
          start: {
            line: 18,
            column: 31
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 18
      },
      '4': {
        name: 'uploads',
        decl: {
          start: {
            line: 22,
            column: 13
          },
          end: {
            line: 22,
            column: 20
          }
        },
        loc: {
          start: {
            line: 22,
            column: 31
          },
          end: {
            line: 24,
            column: 5
          }
        },
        line: 22
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1gv911wszk.s[0]++;
(function () {
  cov_1gv911wszk.f[0]++;

  function StorageService($firebaseStorage) {
    cov_1gv911wszk.f[1]++;

    var storageRef = (cov_1gv911wszk.s[1]++, firebase.storage().ref());

    var service = (cov_1gv911wszk.s[2]++, {
      signatures: signatures,
      avatars: avatars,
      uploads: uploads
    });

    cov_1gv911wszk.s[3]++;
    return service;

    function signatures(fileName) {
      cov_1gv911wszk.f[2]++;
      cov_1gv911wszk.s[4]++;

      return $firebaseStorage(storageRef.child('signatures').child(fileName));
    }

    function avatars(fileName) {
      cov_1gv911wszk.f[3]++;
      cov_1gv911wszk.s[5]++;

      return $firebaseStorage(storageRef.child('avatars').child(fileName));
    }

    function uploads(fileName) {
      cov_1gv911wszk.f[4]++;
      cov_1gv911wszk.s[6]++;

      return $firebaseStorage(storageRef.child('uploads').child(fileName));
    }
  }

  cov_1gv911wszk.s[7]++;
  angular.module('dc-common').factory('StorageService', ['$firebaseStorage', StorageService]);
})();