'use strict';

var cov_5ze2qm790 = function () {
  var path = '/workdir/src/app/service/line-item/line-item.component.js',
      hash = '8620381b3e753c3bd5efc63baa2aff62d929caa0',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/line-item/line-item.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 76,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 98
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 41,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 50
        }
      },
      '6': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 27
        }
      },
      '7': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 31,
          column: 7
        }
      },
      '8': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 28
        }
      },
      '9': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 29,
          column: 10
        }
      },
      '10': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 15
        }
      },
      '11': {
        start: {
          line: 32,
          column: 22
        },
        end: {
          line: 32,
          column: 43
        }
      },
      '12': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 37,
          column: 8
        }
      },
      '13': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 44
        }
      },
      '14': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 30
        }
      },
      '15': {
        start: {
          line: 43,
          column: 4
        },
        end: {
          line: 50,
          column: 7
        }
      },
      '16': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 46,
          column: 7
        }
      },
      '17': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 15
        }
      },
      '18': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 47,
          column: 42
        }
      },
      '19': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 56
        }
      },
      '20': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 42
        }
      },
      '21': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 62,
          column: 6
        }
      },
      '22': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '23': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 49
        }
      },
      '24': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 55,
          column: 35
        }
      },
      '25': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 57,
          column: 33
        }
      },
      '26': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 58,
          column: 46
        }
      },
      '27': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 61,
          column: 9
        }
      },
      '28': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 66,
          column: 6
        }
      },
      '29': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 30
        }
      },
      '30': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 72,
          column: 6
        }
      },
      '31': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 71,
          column: 9
        }
      },
      '32': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 75,
          column: 63
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 76,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 21
          }
        },
        loc: {
          start: {
            line: 15,
            column: 83
          },
          end: {
            line: 73,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 19
          },
          end: {
            line: 18,
            column: 20
          }
        },
        loc: {
          start: {
            line: 18,
            column: 25
          },
          end: {
            line: 41,
            column: 5
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 43,
            column: 28
          },
          end: {
            line: 43,
            column: 29
          }
        },
        loc: {
          start: {
            line: 43,
            column: 34
          },
          end: {
            line: 50,
            column: 5
          }
        },
        line: 43
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 52,
            column: 23
          },
          end: {
            line: 52,
            column: 24
          }
        },
        loc: {
          start: {
            line: 52,
            column: 31
          },
          end: {
            line: 62,
            column: 5
          }
        },
        line: 52
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 64,
            column: 18
          },
          end: {
            line: 64,
            column: 19
          }
        },
        loc: {
          start: {
            line: 64,
            column: 24
          },
          end: {
            line: 66,
            column: 5
          }
        },
        line: 64
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 68,
            column: 18
          },
          end: {
            line: 68,
            column: 19
          }
        },
        loc: {
          start: {
            line: 68,
            column: 24
          },
          end: {
            line: 72,
            column: 5
          }
        },
        line: 68
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }, {
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }],
        line: 21
      },
      '1': {
        loc: {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }, {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }],
        line: 44
      },
      '2': {
        loc: {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }, {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }],
        line: 53
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_5ze2qm790.s[0]++;
(function () {
  cov_5ze2qm790.f[0]++;

  var lineItem = (cov_5ze2qm790.s[1]++, {
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    },
    templateUrl: 'app/service/line-item/line-item.html',
    controller: controller
  });

  cov_5ze2qm790.s[2]++;
  controller.$inject = ['Users', 'Utils', 'ActivityService', 'uibButtonConfig', '$scope', '$log'];

  function controller(Users, Utils, ActivityService, uibButtonConfig, scope, $log) {
    cov_5ze2qm790.f[1]++;

    var self = (cov_5ze2qm790.s[3]++, this);

    cov_5ze2qm790.s[4]++;
    self.$onInit = function () {
      cov_5ze2qm790.f[2]++;
      cov_5ze2qm790.s[5]++;

      uibButtonConfig.activeClass = 'btn-primary';
      cov_5ze2qm790.s[6]++;
      self.newItem = false;
      cov_5ze2qm790.s[7]++;
      if (angular.isUndefined(self.resolve.lineItem)) {
        cov_5ze2qm790.b[0][0]++;
        cov_5ze2qm790.s[8]++;

        self.newItem = true;
        cov_5ze2qm790.s[9]++;
        self.lineItem = {
          type: 'Time',
          rate: 300,
          quantity: 1,
          name: 'Delta DSC',
          description: 'Delta Controls System Controller'
        };
        cov_5ze2qm790.s[10]++;
        return;
      } else {
        cov_5ze2qm790.b[0][1]++;
      }
      var resolve = (cov_5ze2qm790.s[11]++, self.resolve.lineItem);
      cov_5ze2qm790.s[12]++;
      self.rate = {
        description: resolve.description,
        name: resolve.name,
        rate: resolve.rate
      };
      // watch for changes
      cov_5ze2qm790.s[13]++;
      self.original = angular.copy(resolve);
      cov_5ze2qm790.s[14]++;
      self.lineItem = resolve;
    };

    cov_5ze2qm790.s[15]++;
    scope.$watch(self.rate, function () {
      cov_5ze2qm790.f[3]++;
      cov_5ze2qm790.s[16]++;

      if (!self.rate) {
        cov_5ze2qm790.b[1][0]++;
        cov_5ze2qm790.s[17]++;

        return;
      } else {
        cov_5ze2qm790.b[1][1]++;
      }
      cov_5ze2qm790.s[18]++;
      self.lineItem.rate = self.rate.rate;
      cov_5ze2qm790.s[19]++;
      self.lineItem.description = self.rate.description;
      cov_5ze2qm790.s[20]++;
      self.lineItem.name = self.rate.name;
    });

    cov_5ze2qm790.s[21]++;
    self.addLineItem = function (item) {
      cov_5ze2qm790.f[4]++;
      cov_5ze2qm790.s[22]++;

      if (item.type === 'Time') {
        cov_5ze2qm790.b[2][0]++;
        cov_5ze2qm790.s[23]++;

        item.description = self.rate.description;
        cov_5ze2qm790.s[24]++;
        item.name = self.rate.name;
      } else {
        cov_5ze2qm790.b[2][1]++;
      }
      cov_5ze2qm790.s[25]++;
      item.rate = self.rate.rate;
      cov_5ze2qm790.s[26]++;
      item.amount = item.quantity * item.rate;
      cov_5ze2qm790.s[27]++;
      self.close({
        $value: item
      });
    };

    cov_5ze2qm790.s[28]++;
    self.delete = function () {
      cov_5ze2qm790.f[5]++;
      cov_5ze2qm790.s[29]++;

      $log.log(self.lineItem);
    };

    cov_5ze2qm790.s[30]++;
    self.cancel = function () {
      cov_5ze2qm790.f[6]++;
      cov_5ze2qm790.s[31]++;

      self.dismiss({
        $value: self.original
      });
    };
  }

  cov_5ze2qm790.s[32]++;
  angular.module('dc-service').component('lineItem', lineItem);
})();