'use strict';

var cov_1dimk4x1yt = function () {
  var path = '/workdir/src/app/common/customer/customer-detail/customer-detail.js',
      hash = 'ab47e21834cf2c8a9841386302db37687be51a4e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/customer/customer-detail/customer-detail.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 63,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 86
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 25
        }
      },
      '5': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 23
        }
      },
      '6': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 39
        }
      },
      '7': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 53
        }
      },
      '8': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '9': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 28
        }
      },
      '10': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 24
        }
      },
      '11': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 50,
          column: 6
        }
      },
      '12': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 24
        }
      },
      '13': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 34,
          column: 11
        }
      },
      '14': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 33,
          column: 35
        }
      },
      '15': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 39,
          column: 9
        }
      },
      '16': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 31
        }
      },
      '17': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 44,
          column: 9
        }
      },
      '18': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 33
        }
      },
      '19': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 49,
          column: 9
        }
      },
      '20': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 27
        }
      },
      '21': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 57,
          column: 6
        }
      },
      '22': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 53,
          column: 31
        }
      },
      '23': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 54,
          column: 31
        }
      },
      '24': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 30
        }
      },
      '25': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 31
        }
      },
      '26': {
        start: {
          line: 60,
          column: 2
        },
        end: {
          line: 62,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 63,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 76
          },
          end: {
            line: 58,
            column: 3
          }
        },
        line: 13
      },
      '2': {
        name: 'numberOfPages',
        decl: {
          start: {
            line: 20,
            column: 13
          },
          end: {
            line: 20,
            column: 26
          }
        },
        loc: {
          start: {
            line: 20,
            column: 34
          },
          end: {
            line: 22,
            column: 5
          }
        },
        line: 20
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 23
          },
          end: {
            line: 24,
            column: 24
          }
        },
        loc: {
          start: {
            line: 24,
            column: 29
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 24
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 29,
            column: 19
          },
          end: {
            line: 29,
            column: 20
          }
        },
        loc: {
          start: {
            line: 29,
            column: 25
          },
          end: {
            line: 50,
            column: 5
          }
        },
        line: 29
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 32,
            column: 17
          },
          end: {
            line: 32,
            column: 18
          }
        },
        loc: {
          start: {
            line: 32,
            column: 29
          },
          end: {
            line: 34,
            column: 9
          }
        },
        line: 32
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 37,
            column: 15
          },
          end: {
            line: 37,
            column: 16
          }
        },
        loc: {
          start: {
            line: 37,
            column: 26
          },
          end: {
            line: 39,
            column: 7
          }
        },
        line: 37
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 42,
            column: 15
          },
          end: {
            line: 42,
            column: 16
          }
        },
        loc: {
          start: {
            line: 42,
            column: 27
          },
          end: {
            line: 44,
            column: 7
          }
        },
        line: 42
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 47,
            column: 15
          },
          end: {
            line: 47,
            column: 16
          }
        },
        loc: {
          start: {
            line: 47,
            column: 24
          },
          end: {
            line: 49,
            column: 7
          }
        },
        line: 47
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 52,
            column: 22
          },
          end: {
            line: 52,
            column: 23
          }
        },
        loc: {
          start: {
            line: 52,
            column: 28
          },
          end: {
            line: 57,
            column: 5
          }
        },
        line: 52
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1dimk4x1yt.s[0]++;
(function () {
  cov_1dimk4x1yt.f[0]++;

  var customerDetail = (cov_1dimk4x1yt.s[1]++, {
    bindings: {
      customer: '='
    },
    templateUrl: 'app/common/customer/customer-detail/customer-detail.html',
    controller: controller
  });

  cov_1dimk4x1yt.s[2]++;
  controller.$inject = ['Projects', 'TicketService', 'ContactService', 'SiteService'];

  function controller(Projects, TicketService, ContactService, SiteService) {
    cov_1dimk4x1yt.f[1]++;

    var self = (cov_1dimk4x1yt.s[3]++, this);

    cov_1dimk4x1yt.s[4]++;
    self.currentPage = 0;
    cov_1dimk4x1yt.s[5]++;
    self.pageSize = 10;
    cov_1dimk4x1yt.s[6]++;
    self.numberOfPages = numberOfPages;

    function numberOfPages(array) {
      cov_1dimk4x1yt.f[2]++;
      cov_1dimk4x1yt.s[7]++;

      return Math.ceil(array.length / self.pageSize);
    }

    cov_1dimk4x1yt.s[8]++;
    self.saveDetails = function () {
      cov_1dimk4x1yt.f[3]++;
      cov_1dimk4x1yt.s[9]++;

      self.customer.$save();
      cov_1dimk4x1yt.s[10]++;
      self.edit = false;
    };

    cov_1dimk4x1yt.s[11]++;
    self.$onInit = function () {
      cov_1dimk4x1yt.f[4]++;
      cov_1dimk4x1yt.s[12]++;

      self.edit = false;
      cov_1dimk4x1yt.s[13]++;
      Projects.$getByCustomer(self.customer.$id).$loaded(function (projects) {
        cov_1dimk4x1yt.f[5]++;
        cov_1dimk4x1yt.s[14]++;

        self.projects = projects;
      });

      cov_1dimk4x1yt.s[15]++;
      TicketService.$getByCustomer(self.customer.$id).$loaded(function (tickets) {
        cov_1dimk4x1yt.f[6]++;
        cov_1dimk4x1yt.s[16]++;

        self.tickets = tickets;
      });

      cov_1dimk4x1yt.s[17]++;
      ContactService.$getByCustomer(self.customer.$id).$loaded(function (contacts) {
        cov_1dimk4x1yt.f[7]++;
        cov_1dimk4x1yt.s[18]++;

        self.contacts = contacts;
      });

      cov_1dimk4x1yt.s[19]++;
      SiteService.getByCustomer(self.customer.$id).$loaded(function (sites) {
        cov_1dimk4x1yt.f[8]++;
        cov_1dimk4x1yt.s[20]++;

        self.sites = sites;
      });
    };

    cov_1dimk4x1yt.s[21]++;
    self.$onDestroy = function () {
      cov_1dimk4x1yt.f[9]++;
      cov_1dimk4x1yt.s[22]++;

      self.customer.$destroy();
      cov_1dimk4x1yt.s[23]++;
      self.projects.$destroy();
      cov_1dimk4x1yt.s[24]++;
      self.tickets.$destroy();
      cov_1dimk4x1yt.s[25]++;
      self.contacts.$destroy();
    };
  }

  cov_1dimk4x1yt.s[26]++;
  angular.module('dc-customer').component('customerDetail', customerDetail);
})();