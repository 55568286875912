'use strict';

var cov_qmbj4d9x9 = function () {
  var path = '/workdir/src/app/common/settings/settings.service.js',
      hash = 'e2f4eb7e5f7a73dc4e7a1e514f85d5b18799ee5e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/settings/settings.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 55,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '2': {
        start: {
          line: 6,
          column: 19
        },
        end: {
          line: 6,
          column: 23
        }
      },
      '3': {
        start: {
          line: 7,
          column: 16
        },
        end: {
          line: 7,
          column: 20
        }
      },
      '4': {
        start: {
          line: 8,
          column: 16
        },
        end: {
          line: 8,
          column: 20
        }
      },
      '5': {
        start: {
          line: 10,
          column: 20
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '6': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 19
        }
      },
      '7': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 22,
          column: 7
        }
      },
      '8': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 24
        }
      },
      '9': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 46
        }
      },
      '10': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 22
        }
      },
      '11': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '12': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 21
        }
      },
      '13': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 66
        }
      },
      '14': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 19
        }
      },
      '15': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '16': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 21
        }
      },
      '17': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 65
        }
      },
      '18': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 19
        }
      },
      '19': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 46,
          column: 7
        }
      },
      '20': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 25
        }
      },
      '21': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 49,
          column: 7
        }
      },
      '22': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 25
        }
      },
      '23': {
        start: {
          line: 53,
          column: 2
        },
        end: {
          line: 54,
          column: 93
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 55,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'ClientSettings',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 25
          }
        },
        loc: {
          start: {
            line: 4,
            column: 64
          },
          end: {
            line: 51,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: 'all',
        decl: {
          start: {
            line: 19,
            column: 13
          },
          end: {
            line: 19,
            column: 16
          }
        },
        loc: {
          start: {
            line: 19,
            column: 19
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 19
      },
      '3': {
        name: 'billingCodes',
        decl: {
          start: {
            line: 27,
            column: 13
          },
          end: {
            line: 27,
            column: 25
          }
        },
        loc: {
          start: {
            line: 27,
            column: 28
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 27
      },
      '4': {
        name: 'serviceRates',
        decl: {
          start: {
            line: 35,
            column: 13
          },
          end: {
            line: 35,
            column: 25
          }
        },
        loc: {
          start: {
            line: 35,
            column: 28
          },
          end: {
            line: 41,
            column: 5
          }
        },
        line: 35
      },
      '5': {
        name: 'reset',
        decl: {
          start: {
            line: 43,
            column: 13
          },
          end: {
            line: 43,
            column: 18
          }
        },
        loc: {
          start: {
            line: 43,
            column: 21
          },
          end: {
            line: 50,
            column: 5
          }
        },
        line: 43
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }],
        line: 28
      },
      '2': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }],
        line: 36
      },
      '3': {
        loc: {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }, {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }],
        line: 44
      },
      '4': {
        loc: {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }, {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }],
        line: 47
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_qmbj4d9x9.s[0]++;
(function () {
  cov_qmbj4d9x9.f[0]++;

  // define function for Activity
  function ClientSettings($db, $firebaseObject, $firebaseArray) {
    cov_qmbj4d9x9.f[1]++;

    var settingsRef = (cov_qmbj4d9x9.s[1]++, $db.settings);
    var settings = (cov_qmbj4d9x9.s[2]++, null);
    var codes = (cov_qmbj4d9x9.s[3]++, null);
    var rates = (cov_qmbj4d9x9.s[4]++, null);
    // service definition
    var service = (cov_qmbj4d9x9.s[5]++, {
      all: all,
      billingCodes: billingCodes,
      serviceRates: serviceRates,
      reset: reset
    });

    cov_qmbj4d9x9.s[6]++;
    return service;

    function all() {
      cov_qmbj4d9x9.f[2]++;
      cov_qmbj4d9x9.s[7]++;

      if (settings) {
        cov_qmbj4d9x9.b[0][0]++;
        cov_qmbj4d9x9.s[8]++;

        return settings;
      } else {
        cov_qmbj4d9x9.b[0][1]++;
      }
      cov_qmbj4d9x9.s[9]++;
      settings = $firebaseObject(settingsRef);
      cov_qmbj4d9x9.s[10]++;
      return settings;
    }

    function billingCodes() {
      cov_qmbj4d9x9.f[3]++;
      cov_qmbj4d9x9.s[11]++;

      if (codes) {
        cov_qmbj4d9x9.b[1][0]++;
        cov_qmbj4d9x9.s[12]++;

        return codes;
      } else {
        cov_qmbj4d9x9.b[1][1]++;
      }
      cov_qmbj4d9x9.s[13]++;
      codes = $firebaseObject(settingsRef.child('billing-codes'));
      cov_qmbj4d9x9.s[14]++;
      return codes;
    }

    function serviceRates() {
      cov_qmbj4d9x9.f[4]++;
      cov_qmbj4d9x9.s[15]++;

      if (rates) {
        cov_qmbj4d9x9.b[2][0]++;
        cov_qmbj4d9x9.s[16]++;

        return rates;
      } else {
        cov_qmbj4d9x9.b[2][1]++;
      }
      cov_qmbj4d9x9.s[17]++;
      rates = $firebaseArray(settingsRef.child('service-rates'));
      cov_qmbj4d9x9.s[18]++;
      return rates;
    }

    function reset() {
      cov_qmbj4d9x9.f[5]++;
      cov_qmbj4d9x9.s[19]++;

      if (codes) {
        cov_qmbj4d9x9.b[3][0]++;
        cov_qmbj4d9x9.s[20]++;

        codes.$destroy();
      } else {
        cov_qmbj4d9x9.b[3][1]++;
      }
      cov_qmbj4d9x9.s[21]++;
      if (rates) {
        cov_qmbj4d9x9.b[4][0]++;
        cov_qmbj4d9x9.s[22]++;

        rates.$destroy();
      } else {
        cov_qmbj4d9x9.b[4][1]++;
      }
    }
  }

  cov_qmbj4d9x9.s[23]++;
  angular.module('dc-common').factory('ClientSettings', ['$db', '$firebaseObject', '$firebaseArray', ClientSettings]);
})();