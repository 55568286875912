'use strict';

var cov_24m4vpm71f = function () {
  var path = '/workdir/src/app/projects/project-priorities/priority-type.js',
      hash = '77937cb822d004893f44d7d712d2d031a49bcb94',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/projects/project-priorities/priority-type.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 91,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 13,
          column: 3
        }
      },
      '2': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 53
        }
      },
      '3': {
        start: {
          line: 18,
          column: 17
        },
        end: {
          line: 18,
          column: 21
        }
      },
      '4': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 23,
          column: 6
        }
      },
      '5': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 45
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 27
        }
      },
      '7': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 47,
          column: 6
        }
      },
      '8': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 25
        }
      },
      '9': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 34
        }
      },
      '10': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 46,
          column: 11
        }
      },
      '11': {
        start: {
          line: 32,
          column: 10
        },
        end: {
          line: 32,
          column: 36
        }
      },
      '12': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 45,
          column: 13
        }
      },
      '13': {
        start: {
          line: 35,
          column: 12
        },
        end: {
          line: 37,
          column: 13
        }
      },
      '14': {
        start: {
          line: 36,
          column: 14
        },
        end: {
          line: 36,
          column: 45
        }
      },
      '15': {
        start: {
          line: 38,
          column: 12
        },
        end: {
          line: 41,
          column: 13
        }
      },
      '16': {
        start: {
          line: 39,
          column: 14
        },
        end: {
          line: 39,
          column: 42
        }
      },
      '17': {
        start: {
          line: 40,
          column: 14
        },
        end: {
          line: 40,
          column: 21
        }
      },
      '18': {
        start: {
          line: 42,
          column: 12
        },
        end: {
          line: 44,
          column: 13
        }
      },
      '19': {
        start: {
          line: 43,
          column: 14
        },
        end: {
          line: 43,
          column: 51
        }
      },
      '20': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 52,
          column: 6
        }
      },
      '21': {
        start: {
          line: 50,
          column: 20
        },
        end: {
          line: 50,
          column: 33
        }
      },
      '22': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 43
        }
      },
      '23': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 57,
          column: 6
        }
      },
      '24': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 51
        }
      },
      '25': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 27
        }
      },
      '26': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 66,
          column: 6
        }
      },
      '27': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 65,
          column: 11
        }
      },
      '28': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 64,
          column: 24
        }
      },
      '29': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 85,
          column: 6
        }
      },
      '30': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 76,
          column: 9
        }
      },
      '31': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 73,
          column: 9
        }
      },
      '32': {
        start: {
          line: 72,
          column: 10
        },
        end: {
          line: 72,
          column: 34
        }
      },
      '33': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 74,
          column: 49
        }
      },
      '34': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 27
        }
      },
      '35': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 84,
          column: 9
        }
      },
      '36': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 81,
          column: 9
        }
      },
      '37': {
        start: {
          line: 80,
          column: 10
        },
        end: {
          line: 80,
          column: 34
        }
      },
      '38': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 82,
          column: 79
        }
      },
      '39': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 27
        }
      },
      '40': {
        start: {
          line: 88,
          column: 2
        },
        end: {
          line: 90,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 91,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 17,
            column: 11
          },
          end: {
            line: 17,
            column: 21
          }
        },
        loc: {
          start: {
            line: 17,
            column: 45
          },
          end: {
            line: 86,
            column: 3
          }
        },
        line: 17
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 20,
            column: 19
          },
          end: {
            line: 20,
            column: 20
          }
        },
        loc: {
          start: {
            line: 20,
            column: 25
          },
          end: {
            line: 23,
            column: 5
          }
        },
        line: 20
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 25,
            column: 25
          },
          end: {
            line: 25,
            column: 26
          }
        },
        loc: {
          start: {
            line: 25,
            column: 31
          },
          end: {
            line: 47,
            column: 5
          }
        },
        line: 25
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 14
          },
          end: {
            line: 31,
            column: 15
          }
        },
        loc: {
          start: {
            line: 31,
            column: 26
          },
          end: {
            line: 46,
            column: 9
          }
        },
        line: 31
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 33,
            column: 27
          },
          end: {
            line: 33,
            column: 28
          }
        },
        loc: {
          start: {
            line: 33,
            column: 38
          },
          end: {
            line: 45,
            column: 11
          }
        },
        line: 33
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 49,
            column: 22
          },
          end: {
            line: 49,
            column: 23
          }
        },
        loc: {
          start: {
            line: 49,
            column: 28
          },
          end: {
            line: 52,
            column: 5
          }
        },
        line: 49
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 54,
            column: 22
          },
          end: {
            line: 54,
            column: 23
          }
        },
        loc: {
          start: {
            line: 54,
            column: 28
          },
          end: {
            line: 57,
            column: 5
          }
        },
        line: 54
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 59,
            column: 16
          },
          end: {
            line: 59,
            column: 17
          }
        },
        loc: {
          start: {
            line: 59,
            column: 27
          },
          end: {
            line: 66,
            column: 5
          }
        },
        line: 59
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 61,
            column: 14
          },
          end: {
            line: 61,
            column: 15
          }
        },
        loc: {
          start: {
            line: 61,
            column: 20
          },
          end: {
            line: 63,
            column: 9
          }
        },
        line: 61
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 63,
            column: 11
          },
          end: {
            line: 63,
            column: 12
          }
        },
        loc: {
          start: {
            line: 63,
            column: 18
          },
          end: {
            line: 65,
            column: 9
          }
        },
        line: 63
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 68,
            column: 16
          },
          end: {
            line: 68,
            column: 17
          }
        },
        loc: {
          start: {
            line: 68,
            column: 22
          },
          end: {
            line: 85,
            column: 5
          }
        },
        line: 68
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 69,
            column: 37
          },
          end: {
            line: 69,
            column: 38
          }
        },
        loc: {
          start: {
            line: 69,
            column: 48
          },
          end: {
            line: 76,
            column: 7
          }
        },
        line: 69
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 77,
            column: 28
          },
          end: {
            line: 77,
            column: 29
          }
        },
        loc: {
          start: {
            line: 77,
            column: 39
          },
          end: {
            line: 84,
            column: 7
          }
        },
        line: 77
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 35,
            column: 12
          },
          end: {
            line: 37,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 12
          },
          end: {
            line: 37,
            column: 13
          }
        }, {
          start: {
            line: 35,
            column: 12
          },
          end: {
            line: 37,
            column: 13
          }
        }],
        line: 35
      },
      '1': {
        loc: {
          start: {
            line: 38,
            column: 12
          },
          end: {
            line: 41,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 12
          },
          end: {
            line: 41,
            column: 13
          }
        }, {
          start: {
            line: 38,
            column: 12
          },
          end: {
            line: 41,
            column: 13
          }
        }],
        line: 38
      },
      '2': {
        loc: {
          start: {
            line: 42,
            column: 12
          },
          end: {
            line: 44,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 12
          },
          end: {
            line: 44,
            column: 13
          }
        }, {
          start: {
            line: 42,
            column: 12
          },
          end: {
            line: 44,
            column: 13
          }
        }],
        line: 42
      },
      '3': {
        loc: {
          start: {
            line: 71,
            column: 8
          },
          end: {
            line: 73,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 8
          },
          end: {
            line: 73,
            column: 9
          }
        }, {
          start: {
            line: 71,
            column: 8
          },
          end: {
            line: 73,
            column: 9
          }
        }],
        line: 71
      },
      '4': {
        loc: {
          start: {
            line: 79,
            column: 8
          },
          end: {
            line: 81,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 8
          },
          end: {
            line: 81,
            column: 9
          }
        }, {
          start: {
            line: 79,
            column: 8
          },
          end: {
            line: 81,
            column: 9
          }
        }],
        line: 79
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_24m4vpm71f.s[0]++;
(function () {
  cov_24m4vpm71f.f[0]++;

  var priorityType = (cov_24m4vpm71f.s[1]++, {
    require: {
      projectPriorities: '^projectPriorities'
    },
    bindings: {
      priority: '<'
    },
    templateUrl: 'app/projects/project-priorities/priority-type.html',
    controller: controller,
    controllerAs: '$priorities'
  });

  cov_24m4vpm71f.s[2]++;
  controller.$inject = ['$log', 'Users', 'Projects'];

  function controller($log, Users, Projects) {
    cov_24m4vpm71f.f[1]++;

    var self = (cov_24m4vpm71f.s[3]++, this);

    cov_24m4vpm71f.s[4]++;
    self.$onInit = function () {
      cov_24m4vpm71f.f[2]++;
      cov_24m4vpm71f.s[5]++;

      self.roles = Users.currentUser().roles;
      cov_24m4vpm71f.s[6]++;
      self.buildProjects();
    };

    cov_24m4vpm71f.s[7]++;
    self.buildProjects = function () {
      cov_24m4vpm71f.f[3]++;
      cov_24m4vpm71f.s[8]++;

      self.projects = [];
      cov_24m4vpm71f.s[9]++;
      self.completedProjects = [];
      cov_24m4vpm71f.s[10]++;
      Projects.$getByPriority(self.priority).$loaded().then(function (projects) {
        cov_24m4vpm71f.f[4]++;
        cov_24m4vpm71f.s[11]++;

        self.saveArray = projects;
        cov_24m4vpm71f.s[12]++;
        projects.forEach(function (project) {
          cov_24m4vpm71f.f[5]++;
          cov_24m4vpm71f.s[13]++;

          // check for project visibility object
          if (!project.visiblePriorities) {
            cov_24m4vpm71f.b[0][0]++;
            cov_24m4vpm71f.s[14]++;

            project.visiblePriorities = {};
          } else {
            cov_24m4vpm71f.b[0][1]++;
          }
          cov_24m4vpm71f.s[15]++;
          if (!project.visiblePriorities[self.priority]) {
            cov_24m4vpm71f.b[1][0]++;
            cov_24m4vpm71f.s[16]++;

            self.projects.push(project);
            cov_24m4vpm71f.s[17]++;
            return;
          } else {
            cov_24m4vpm71f.b[1][1]++;
          }
          cov_24m4vpm71f.s[18]++;
          if (project.active) {
            cov_24m4vpm71f.b[2][0]++;
            cov_24m4vpm71f.s[19]++;

            self.completedProjects.push(project);
          } else {
            cov_24m4vpm71f.b[2][1]++;
          }
        });
      });
    };

    cov_24m4vpm71f.s[20]++;
    self.$onChanges = function () {
      cov_24m4vpm71f.f[6]++;

      var order = (cov_24m4vpm71f.s[21]++, self.priority);
      cov_24m4vpm71f.s[22]++;
      self.orderBy = 'priorities.' + order;
    };

    cov_24m4vpm71f.s[23]++;
    self.saveToggle = function () {
      cov_24m4vpm71f.f[7]++;
      cov_24m4vpm71f.s[24]++;

      self.projectPriorities.toggleVisible = false;
      cov_24m4vpm71f.s[25]++;
      self.buildProjects();
    };

    cov_24m4vpm71f.s[26]++;
    self.save = function (project) {
      cov_24m4vpm71f.f[8]++;
      cov_24m4vpm71f.s[27]++;

      self.saveArray.$save(project).then(function () {
        // pass data.key if needed

        cov_24m4vpm71f.f[9]++;
      }, function (err) {
        cov_24m4vpm71f.f[10]++;
        cov_24m4vpm71f.s[28]++;

        $log.log(err);
      });
    };

    cov_24m4vpm71f.s[29]++;
    self.sort = function () {
      cov_24m4vpm71f.f[11]++;
      cov_24m4vpm71f.s[30]++;

      self.completedProjects.forEach(function (project) {
        cov_24m4vpm71f.f[12]++;
        cov_24m4vpm71f.s[31]++;

        // check for project priorities
        if (!project.priorities) {
          cov_24m4vpm71f.b[3][0]++;
          cov_24m4vpm71f.s[32]++;

          project.priorities = {};
        } else {
          cov_24m4vpm71f.b[3][1]++;
        }
        cov_24m4vpm71f.s[33]++;
        delete project.priorities[self.priority];
        cov_24m4vpm71f.s[34]++;
        self.save(project);
      });
      cov_24m4vpm71f.s[35]++;
      self.projects.forEach(function (project) {
        cov_24m4vpm71f.f[13]++;
        cov_24m4vpm71f.s[36]++;

        // check for project priorities
        if (!project.priorities) {
          cov_24m4vpm71f.b[4][0]++;
          cov_24m4vpm71f.s[37]++;

          project.priorities = {};
        } else {
          cov_24m4vpm71f.b[4][1]++;
        }
        cov_24m4vpm71f.s[38]++;
        project.priorities[self.priority] = self.projects.indexOf(project) + 1;
        cov_24m4vpm71f.s[39]++;
        self.save(project);
      });
    };
  }

  cov_24m4vpm71f.s[40]++;
  angular.module('app').component('priorityType', priorityType);
})();