'use strict';

var cov_194nso2vlu = function () {
  var path = '/workdir/src/app/common/customer/helpers/customer-select.component.js',
      hash = '0fa31ce86d254452e3f0cc918778cfa037a81416',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/customer/helpers/customer-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 54
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 26,
          column: 6
        }
      },
      '5': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 38
        }
      },
      '6': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 65
        }
      },
      '7': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 25,
          column: 11
        }
      },
      '8': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 24,
          column: 69
        }
      },
      '9': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 31,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 32,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 48
          },
          end: {
            line: 27,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 19
          },
          end: {
            line: 19,
            column: 20
          }
        },
        loc: {
          start: {
            line: 19,
            column: 25
          },
          end: {
            line: 26,
            column: 5
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 17
          },
          end: {
            line: 23,
            column: 18
          }
        },
        loc: {
          start: {
            line: 23,
            column: 30
          },
          end: {
            line: 25,
            column: 9
          }
        },
        line: 23
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 20
          },
          end: {
            line: 20,
            column: 37
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 20,
            column: 20
          },
          end: {
            line: 20,
            column: 31
          }
        }, {
          start: {
            line: 20,
            column: 35
          },
          end: {
            line: 20,
            column: 37
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 21,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 21,
            column: 41
          }
        }, {
          start: {
            line: 21,
            column: 45
          },
          end: {
            line: 21,
            column: 64
          }
        }],
        line: 21
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_194nso2vlu.s[0]++;
(function () {
  cov_194nso2vlu.f[0]++;

  var customerSelect = (cov_194nso2vlu.s[1]++, {
    bindings: {
      ngModel: '=',
      required: '<',
      filter: '<',
      placeholder: '<'
    },
    templateUrl: 'app/common/customer/helpers/customer-select.html',
    controller: controller
  });

  cov_194nso2vlu.s[2]++;
  controller.$inject = ['CustomerService', '$filter'];

  function controller(CustomerService, $filter) {
    cov_194nso2vlu.f[1]++;

    var self = (cov_194nso2vlu.s[3]++, this);

    cov_194nso2vlu.s[4]++;
    self.$onInit = function () {
      cov_194nso2vlu.f[2]++;
      cov_194nso2vlu.s[5]++;

      self.filter = (cov_194nso2vlu.b[0][0]++, self.filter) || (cov_194nso2vlu.b[0][1]++, {});
      cov_194nso2vlu.s[6]++;
      self.placeholder = (cov_194nso2vlu.b[1][0]++, self.placeholder) || (cov_194nso2vlu.b[1][1]++, 'Select a Customer');
      cov_194nso2vlu.s[7]++;
      CustomerService.all().$loaded(function (customers) {
        cov_194nso2vlu.f[3]++;
        cov_194nso2vlu.s[8]++;

        self.customers = $filter('filter')(customers, self.filter);
      });
    };
  }

  cov_194nso2vlu.s[9]++;
  angular.module('dc-customer').component('customerSelect', customerSelect);
})();