'use strict';

var cov_vsw39liaz = function () {
  var path = '/workdir/src/app/common/nav/main-nav/main-nav.js',
      hash = '3b0b5bf38afc574fffd07e4b0c7f78c503f0565e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/nav/main-nav/main-nav.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 18
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 148
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 36
        }
      },
      '5': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 14,
          column: 28
        }
      },
      '6': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '7': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 16,
          column: 20
        }
      },
      '8': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 23
        }
      },
      '9': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 23
        }
      },
      '10': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 28
        }
      },
      '11': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 30
        }
      },
      '12': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 30
        }
      },
      '13': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 29
        }
      },
      '14': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 26,
          column: 11
        }
      },
      '15': {
        start: {
          line: 25,
          column: 10
        },
        end: {
          line: 25,
          column: 34
        }
      },
      '16': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 31,
          column: 35
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 32,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 128
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 18
          },
          end: {
            line: 15,
            column: 19
          }
        },
        loc: {
          start: {
            line: 15,
            column: 30
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 14
          },
          end: {
            line: 24,
            column: 15
          }
        },
        loc: {
          start: {
            line: 24,
            column: 20
          },
          end: {
            line: 26,
            column: 9
          }
        },
        line: 24
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_vsw39liaz.s[0]++;
(function () {
  cov_vsw39liaz.f[0]++;

  var mainNav = (cov_vsw39liaz.s[1]++, {
    templateUrl: 'app/common/nav/main-nav/main-nav.html',
    controller: controller
  });

  cov_vsw39liaz.s[2]++;
  controller.$inject = ['Auth', 'ClientSettings', 'ActivityService', 'TicketService', 'Projects', 'Messages', 'CustomerService', 'Users', '$state'];

  function controller(Auth, ActivityService, ClientSettings, TicketService, Projects, Messages, CustomerService, Users, $state) {
    cov_vsw39liaz.f[1]++;

    var self = (cov_vsw39liaz.s[3]++, this);

    cov_vsw39liaz.s[4]++;
    self.user = Users.currentUser();
    cov_vsw39liaz.s[5]++;
    self.isCollapsed = true;
    cov_vsw39liaz.s[6]++;
    self.logout = function () {
      cov_vsw39liaz.f[2]++;
      cov_vsw39liaz.s[7]++;

      Users.reset();
      cov_vsw39liaz.s[8]++;
      Messages.reset();
      cov_vsw39liaz.s[9]++;
      Projects.reset();
      cov_vsw39liaz.s[10]++;
      TicketService.reset();
      cov_vsw39liaz.s[11]++;
      ActivityService.reset();
      cov_vsw39liaz.s[12]++;
      CustomerService.reset();
      cov_vsw39liaz.s[13]++;
      ClientSettings.reset();
      cov_vsw39liaz.s[14]++;
      return Auth.logout().then(function () {
        cov_vsw39liaz.f[3]++;
        cov_vsw39liaz.s[15]++;

        $state.go('auth.login');
      });
    };
  }

  cov_vsw39liaz.s[16]++;
  angular.module('dc-common').component('mainNav', mainNav);
})();