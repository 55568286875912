'use strict';

var cov_4n2qmx3r0 = function () {
  var path = '/workdir/src/app/service/ticket-detail/ticket-detail.component.js',
      hash = 'e891b9b25d757068da527d6116a9bc3f0aaea04f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/ticket-detail/ticket-detail.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 26
        }
      },
      '3': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 71
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 19,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 24
          },
          end: {
            line: 16,
            column: 3
          }
        },
        line: 14
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_4n2qmx3r0.s[0]++;
(function () {
  cov_4n2qmx3r0.f[0]++;

  var ticketDetail = (cov_4n2qmx3r0.s[1]++, {
    bindings: {
      ticket: '=',
      edit: '='
    },
    templateUrl: 'app/service/ticket-detail/ticket-detail.html',
    controller: controller
  });

  cov_4n2qmx3r0.s[2]++;
  controller.$inject = [];

  function controller() {
    // const self = this;

    cov_4n2qmx3r0.f[1]++;
  }

  cov_4n2qmx3r0.s[3]++;
  angular.module('dc-service').component('ticketDetail', ticketDetail);
})();