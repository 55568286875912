'use strict';

var cov_1pi28aahw4 = function () {
  var path = '/workdir/src/app/common/customer/sites/helpers/site-select.component.js',
      hash = '9e320458a711bbf8443830fc80b208c5ed53a32a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/customer/sites/helpers/site-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 36,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 39
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 26,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 22,
          column: 7
        }
      },
      '6': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 30
        }
      },
      '7': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 25,
          column: 9
        }
      },
      '8': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 24,
          column: 27
        }
      },
      '9': {
        start: {
          line: 28,
          column: 4
        },
        end: {
          line: 32,
          column: 6
        }
      },
      '10': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 31,
          column: 7
        }
      },
      '11': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 30
        }
      },
      '12': {
        start: {
          line: 35,
          column: 2
        },
        end: {
          line: 35,
          column: 68
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 36,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 21
          }
        },
        loc: {
          start: {
            line: 15,
            column: 35
          },
          end: {
            line: 33,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 22
          },
          end: {
            line: 18,
            column: 23
          }
        },
        loc: {
          start: {
            line: 18,
            column: 28
          },
          end: {
            line: 26,
            column: 5
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 57
          },
          end: {
            line: 23,
            column: 58
          }
        },
        loc: {
          start: {
            line: 23,
            column: 66
          },
          end: {
            line: 25,
            column: 7
          }
        },
        line: 23
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 28,
            column: 22
          },
          end: {
            line: 28,
            column: 23
          }
        },
        loc: {
          start: {
            line: 28,
            column: 28
          },
          end: {
            line: 32,
            column: 5
          }
        },
        line: 28
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        }, {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 22,
            column: 7
          }
        }],
        line: 19
      },
      '1': {
        loc: {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }, {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }],
        line: 29
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1pi28aahw4.s[0]++;
(function () {
  cov_1pi28aahw4.f[0]++;

  var siteSelect = (cov_1pi28aahw4.s[1]++, {
    bindings: {
      ngModel: '=',
      ngDisabled: '=',
      customerId: '<'
    },
    templateUrl: 'app/common/customer/sites/helpers/site-select.html',
    controller: controller
  });

  cov_1pi28aahw4.s[2]++;
  controller.$inject = ['SiteService'];

  function controller(SiteService) {
    cov_1pi28aahw4.f[1]++;

    var self = (cov_1pi28aahw4.s[3]++, this);

    cov_1pi28aahw4.s[4]++;
    self.$onChanges = function () {
      cov_1pi28aahw4.f[2]++;
      cov_1pi28aahw4.s[5]++;

      if (self.sites) {
        cov_1pi28aahw4.b[0][0]++;
        cov_1pi28aahw4.s[6]++;

        // clean up listener before changing customerID
        self.sites.$destroy();
      } else {
        cov_1pi28aahw4.b[0][1]++;
      }
      cov_1pi28aahw4.s[7]++;
      SiteService.getByCustomer(self.customerId).$loaded(function (sites) {
        cov_1pi28aahw4.f[3]++;
        cov_1pi28aahw4.s[8]++;

        self.sites = sites;
      });
    };

    cov_1pi28aahw4.s[9]++;
    self.$onDestroy = function () {
      cov_1pi28aahw4.f[4]++;
      cov_1pi28aahw4.s[10]++;

      if (self.sites) {
        cov_1pi28aahw4.b[1][0]++;
        cov_1pi28aahw4.s[11]++;

        self.sites.$destroy();
      } else {
        cov_1pi28aahw4.b[1][1]++;
      }
    };
  }

  cov_1pi28aahw4.s[12]++;
  angular.module('dc-customer').component('siteSelect', siteSelect);
})();