'use strict';

var cov_4vymav1nx = function () {
  var path = '/workdir/src/app/service/invoice/print-invoice.component.js',
      hash = '7c94060f73c67fb5362567bf994cc1bf59b6199e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/invoice/print-invoice.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 7,
          column: 3
        }
      },
      '2': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 93
        }
      },
      '3': {
        start: {
          line: 12,
          column: 17
        },
        end: {
          line: 12,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 23
        },
        end: {
          line: 15,
          column: 56
        }
      },
      '5': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '6': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 17,
          column: 29
        }
      },
      '7': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 47
        }
      },
      '8': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 19,
          column: 36
        }
      },
      '9': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 22
        }
      },
      '10': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 31,
          column: 6
        }
      },
      '11': {
        start: {
          line: 28,
          column: 16
        },
        end: {
          line: 28,
          column: 40
        }
      },
      '12': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 29,
          column: 19
        }
      },
      '13': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 19
        }
      },
      '14': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 35,
          column: 6
        }
      },
      '15': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 29
        }
      },
      '16': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 38,
          column: 71
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 39,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 11,
            column: 11
          },
          end: {
            line: 11,
            column: 21
          }
        },
        loc: {
          start: {
            line: 11,
            column: 81
          },
          end: {
            line: 36,
            column: 3
          }
        },
        line: 11
      },
      '2': {
        name: 'loadTicket',
        decl: {
          start: {
            line: 14,
            column: 13
          },
          end: {
            line: 14,
            column: 23
          }
        },
        loc: {
          start: {
            line: 14,
            column: 26
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 16,
            column: 44
          },
          end: {
            line: 16,
            column: 45
          }
        },
        loc: {
          start: {
            line: 16,
            column: 54
          },
          end: {
            line: 20,
            column: 7
          }
        },
        line: 16
      },
      '4': {
        name: 'printTicket',
        decl: {
          start: {
            line: 23,
            column: 13
          },
          end: {
            line: 23,
            column: 24
          }
        },
        loc: {
          start: {
            line: 23,
            column: 27
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 23
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 27,
            column: 19
          },
          end: {
            line: 27,
            column: 20
          }
        },
        loc: {
          start: {
            line: 27,
            column: 25
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 27
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 33,
            column: 22
          },
          end: {
            line: 33,
            column: 23
          }
        },
        loc: {
          start: {
            line: 33,
            column: 28
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 33
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_4vymav1nx.s[0]++;
(function () {
  cov_4vymav1nx.f[0]++;

  var printInvoice = (cov_4vymav1nx.s[1]++, {
    bindings: {},
    templateUrl: 'app/service/invoice/print-invoice.html',
    controller: controller
  });

  cov_4vymav1nx.s[2]++;
  controller.$inject = ['$uibModal', '$stateParams', 'TicketService', '$window', '$timeout'];

  function controller($uibModal, $stateParams, TicketService, $window, $timeout) {
    cov_4vymav1nx.f[1]++;

    var self = (cov_4vymav1nx.s[3]++, this);

    function loadTicket() {
      cov_4vymav1nx.f[2]++;

      var ticketId = (cov_4vymav1nx.s[4]++, parseFloat($stateParams.ticketId));
      cov_4vymav1nx.s[5]++;
      TicketService.$getByid(ticketId).then(function (ticket) {
        cov_4vymav1nx.f[3]++;
        cov_4vymav1nx.s[6]++;

        self.ticket = ticket;
        cov_4vymav1nx.s[7]++;
        self.lineItems = self.ticket.lineItems;
        cov_4vymav1nx.s[8]++;
        $timeout(printTicket, 2000);
      });
    }

    function printTicket() {
      cov_4vymav1nx.f[4]++;
      cov_4vymav1nx.s[9]++;

      $window.print();
    }

    cov_4vymav1nx.s[10]++;
    self.$onInit = function () {
      cov_4vymav1nx.f[5]++;

      var d = (cov_4vymav1nx.s[11]++, new Date().toISOString());
      cov_4vymav1nx.s[12]++;
      self.now = d;
      cov_4vymav1nx.s[13]++;
      loadTicket();
    };

    cov_4vymav1nx.s[14]++;
    self.$onDestroy = function () {
      cov_4vymav1nx.f[6]++;
      cov_4vymav1nx.s[15]++;

      self.ticket.$destroy();
    };
  }

  cov_4vymav1nx.s[16]++;
  angular.module('dc-service').component('printInvoice', printInvoice);
})();