'use strict';

var cov_2cluefwwwj = function () {
  var path = '/workdir/src/app/service/line-item.service.js',
      hash = '6f0f4245c7bd388cfd2df7a09f8c30a78dbddb09',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/line-item.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 67,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 22
        },
        end: {
          line: 5,
          column: 33
        }
      },
      '2': {
        start: {
          line: 7,
          column: 20
        },
        end: {
          line: 11,
          column: 5
        }
      },
      '3': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '4': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 71
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 47
        }
      },
      '6': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 33
        }
      },
      '7': {
        start: {
          line: 20,
          column: 22
        },
        end: {
          line: 20,
          column: 67
        }
      },
      '8': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 29
        }
      },
      '9': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '10': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 24,
          column: 55
        }
      },
      '11': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 33,
          column: 11
        }
      },
      '12': {
        start: {
          line: 26,
          column: 27
        },
        end: {
          line: 31,
          column: 11
        }
      },
      '13': {
        start: {
          line: 32,
          column: 10
        },
        end: {
          line: 32,
          column: 40
        }
      },
      '14': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 41,
          column: 7
        }
      },
      '15': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 71
        }
      },
      '16': {
        start: {
          line: 42,
          column: 22
        },
        end: {
          line: 42,
          column: 72
        }
      },
      '17': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 53,
          column: 9
        }
      },
      '18': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 55
        }
      },
      '19': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 52,
          column: 11
        }
      },
      '20': {
        start: {
          line: 57,
          column: 22
        },
        end: {
          line: 57,
          column: 72
        }
      },
      '21': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 58,
          column: 30
        }
      },
      '22': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 61,
          column: 19
        }
      },
      '23': {
        start: {
          line: 64,
          column: 2
        },
        end: {
          line: 66,
          column: 95
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 67,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'LineItemService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 26
          }
        },
        loc: {
          start: {
            line: 4,
            column: 63
          },
          end: {
            line: 62,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: 'create',
        decl: {
          start: {
            line: 13,
            column: 13
          },
          end: {
            line: 13,
            column: 19
          }
        },
        loc: {
          start: {
            line: 13,
            column: 34
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 31
          },
          end: {
            line: 22,
            column: 32
          }
        },
        loc: {
          start: {
            line: 22,
            column: 37
          },
          end: {
            line: 34,
            column: 7
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 25,
            column: 54
          },
          end: {
            line: 25,
            column: 55
          }
        },
        loc: {
          start: {
            line: 25,
            column: 60
          },
          end: {
            line: 33,
            column: 9
          }
        },
        line: 25
      },
      '5': {
        name: 'update',
        decl: {
          start: {
            line: 37,
            column: 13
          },
          end: {
            line: 37,
            column: 19
          }
        },
        loc: {
          start: {
            line: 37,
            column: 34
          },
          end: {
            line: 54,
            column: 5
          }
        },
        line: 37
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 43,
            column: 31
          },
          end: {
            line: 43,
            column: 32
          }
        },
        loc: {
          start: {
            line: 43,
            column: 37
          },
          end: {
            line: 53,
            column: 7
          }
        },
        line: 43
      },
      '7': {
        name: 'remove',
        decl: {
          start: {
            line: 56,
            column: 13
          },
          end: {
            line: 56,
            column: 19
          }
        },
        loc: {
          start: {
            line: 56,
            column: 34
          },
          end: {
            line: 59,
            column: 5
          }
        },
        line: 56
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }, {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }],
        line: 14
      },
      '1': {
        loc: {
          start: {
            line: 14,
            column: 10
          },
          end: {
            line: 14,
            column: 26
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 14,
            column: 10
          },
          end: {
            line: 14,
            column: 17
          }
        }, {
          start: {
            line: 14,
            column: 21
          },
          end: {
            line: 14,
            column: 26
          }
        }],
        line: 14
      },
      '2': {
        loc: {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }],
        line: 38
      },
      '3': {
        loc: {
          start: {
            line: 38,
            column: 10
          },
          end: {
            line: 38,
            column: 26
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 38,
            column: 10
          },
          end: {
            line: 38,
            column: 17
          }
        }, {
          start: {
            line: 38,
            column: 21
          },
          end: {
            line: 38,
            column: 26
          }
        }],
        line: 38
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2cluefwwwj.s[0]++;
(function () {
  cov_2cluefwwwj.f[0]++;

  // defines function Tickets
  function LineItemService($db, Utils, ActivityService, Users) {
    cov_2cluefwwwj.f[1]++;

    var ticketRef = (cov_2cluefwwwj.s[1]++, $db.tickets);

    var service = (cov_2cluefwwwj.s[2]++, {
      create: create,
      update: update,
      remove: remove
    });

    function create(ticket, item) {
      cov_2cluefwwwj.f[2]++;
      cov_2cluefwwwj.s[3]++;

      if ((cov_2cluefwwwj.b[1][0]++, !ticket) || (cov_2cluefwwwj.b[1][1]++, !item)) {
        cov_2cluefwwwj.b[0][0]++;
        cov_2cluefwwwj.s[4]++;

        // TODO: need to catch up on my error handling throughout.
        throw new Error('Expects String:ticketID and Object:LineItem');
      } else {
        cov_2cluefwwwj.b[0][1]++;
      }
      cov_2cluefwwwj.s[5]++;
      item.createdBy = Users.currentUser().$id;
      cov_2cluefwwwj.s[6]++;
      item.created = Utils.now();
      var itemRef = (cov_2cluefwwwj.s[7]++, ticketRef.child(ticket + '/lineItems').push());
      cov_2cluefwwwj.s[8]++;
      item.uid = itemRef.key;
      cov_2cluefwwwj.s[9]++;
      return itemRef.set(item, function () {
        cov_2cluefwwwj.f[3]++;
        cov_2cluefwwwj.s[10]++;

        // loading resolves from cloud function
        ticketRef.child(ticket + '/loading').set(true);
        cov_2cluefwwwj.s[11]++;
        ticketRef.child(ticket + '/id').once('value', function (id) {
          cov_2cluefwwwj.f[4]++;

          var activity = (cov_2cluefwwwj.s[12]++, {
            userId: item.createdBy,
            type: 'Line Item',
            action: 'Added',
            ticket: id.val()
          });
          cov_2cluefwwwj.s[13]++;
          ActivityService.add(activity);
        });
      });
    }

    function update(ticket, item) {
      cov_2cluefwwwj.f[5]++;
      cov_2cluefwwwj.s[14]++;

      if ((cov_2cluefwwwj.b[3][0]++, !ticket) || (cov_2cluefwwwj.b[3][1]++, !item)) {
        cov_2cluefwwwj.b[2][0]++;
        cov_2cluefwwwj.s[15]++;

        // TODO: need to catch up on my error handling throughout.
        throw new Error('Expects String:ticketID and Object:LineItem');
      } else {
        cov_2cluefwwwj.b[2][1]++;
      }
      var itemRef = (cov_2cluefwwwj.s[16]++, ticketRef.child(ticket + '/lineItems/' + item.uid));
      cov_2cluefwwwj.s[17]++;
      return itemRef.set(item, function () {
        cov_2cluefwwwj.f[6]++;
        cov_2cluefwwwj.s[18]++;

        // loading resolves from cloud function
        ticketRef.child(ticket + '/loading').set(true);
        cov_2cluefwwwj.s[19]++;
        itemRef.child('updates').push({
          updatedBy: Users.currentUser().$id,
          updated: Utils.now(),
          name: item.name,
          amount: item.amount,
          rate: item.rate
        });
      });
    }

    function remove(ticket, item) {
      cov_2cluefwwwj.f[7]++;

      var itemRef = (cov_2cluefwwwj.s[20]++, ticketRef.child(ticket + '/lineItems/' + item.uid));
      cov_2cluefwwwj.s[21]++;
      return itemRef.remove();
    }

    cov_2cluefwwwj.s[22]++;
    return service;
  }

  cov_2cluefwwwj.s[23]++;
  angular.module('dc-service').factory('LineItemService', ['$db', 'Utils', 'ActivityService', 'Users', LineItemService]);
})();