'use strict';

var cov_hhyh3qo1r = function () {
  var path = '/workdir/src/directives/address-autocomplete.js',
      hash = 'f6be569d2854c189946caefbc0cf179d62e12f19',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/directives/address-autocomplete.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '2': {
        start: {
          line: 17,
          column: 24
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '3': {
        start: {
          line: 22,
          column: 29
        },
        end: {
          line: 22,
          column: 85
        }
      },
      '4': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 54,
          column: 11
        }
      },
      '5': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 53,
          column: 13
        }
      },
      '6': {
        start: {
          line: 25,
          column: 24
        },
        end: {
          line: 25,
          column: 26
        }
      },
      '7': {
        start: {
          line: 26,
          column: 26
        },
        end: {
          line: 26,
          column: 49
        }
      },
      '8': {
        start: {
          line: 27,
          column: 31
        },
        end: {
          line: 27,
          column: 55
        }
      },
      '9': {
        start: {
          line: 29,
          column: 12
        },
        end: {
          line: 29,
          column: 59
        }
      },
      '10': {
        start: {
          line: 30,
          column: 12
        },
        end: {
          line: 33,
          column: 14
        }
      },
      '11': {
        start: {
          line: 35,
          column: 27
        },
        end: {
          line: 35,
          column: 89
        }
      },
      '12': {
        start: {
          line: 36,
          column: 25
        },
        end: {
          line: 36,
          column: 55
        }
      },
      '13': {
        start: {
          line: 37,
          column: 26
        },
        end: {
          line: 37,
          column: 56
        }
      },
      '14': {
        start: {
          line: 38,
          column: 22
        },
        end: {
          line: 38,
          column: 24
        }
      },
      '15': {
        start: {
          line: 39,
          column: 12
        },
        end: {
          line: 42,
          column: 13
        }
      },
      '16': {
        start: {
          line: 41,
          column: 14
        },
        end: {
          line: 41,
          column: 51
        }
      },
      '17': {
        start: {
          line: 43,
          column: 28
        },
        end: {
          line: 43,
          column: 57
        }
      },
      '18': {
        start: {
          line: 44,
          column: 12
        },
        end: {
          line: 50,
          column: 14
        }
      },
      '19': {
        start: {
          line: 51,
          column: 12
        },
        end: {
          line: 51,
          column: 40
        }
      },
      '20': {
        start: {
          line: 52,
          column: 12
        },
        end: {
          line: 52,
          column: 37
        }
      },
      '21': {
        start: {
          line: 59,
          column: 2
        },
        end: {
          line: 61,
          column: 59
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 62,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'addressAutocomplete',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 30
          }
        },
        loc: {
          start: {
            line: 3,
            column: 33
          },
          end: {
            line: 57,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 23,
            column: 69
          },
          end: {
            line: 23,
            column: 70
          }
        },
        loc: {
          start: {
            line: 23,
            column: 75
          },
          end: {
            line: 54,
            column: 9
          }
        },
        line: 23
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 23
          },
          end: {
            line: 24,
            column: 24
          }
        },
        loc: {
          start: {
            line: 24,
            column: 29
          },
          end: {
            line: 53,
            column: 11
          }
        },
        line: 24
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 35,
            column: 27
          },
          end: {
            line: 35,
            column: 89
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 35,
            column: 27
          },
          end: {
            line: 35,
            column: 83
          }
        }, {
          start: {
            line: 35,
            column: 87
          },
          end: {
            line: 35,
            column: 89
          }
        }],
        line: 35
      },
      '1': {
        loc: {
          start: {
            line: 36,
            column: 25
          },
          end: {
            line: 36,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 36,
            column: 25
          },
          end: {
            line: 36,
            column: 49
          }
        }, {
          start: {
            line: 36,
            column: 53
          },
          end: {
            line: 36,
            column: 55
          }
        }],
        line: 36
      },
      '2': {
        loc: {
          start: {
            line: 37,
            column: 26
          },
          end: {
            line: 37,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 37,
            column: 26
          },
          end: {
            line: 37,
            column: 50
          }
        }, {
          start: {
            line: 37,
            column: 54
          },
          end: {
            line: 37,
            column: 56
          }
        }],
        line: 37
      },
      '3': {
        loc: {
          start: {
            line: 39,
            column: 12
          },
          end: {
            line: 42,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 12
          },
          end: {
            line: 42,
            column: 13
          }
        }, {
          start: {
            line: 39,
            column: 12
          },
          end: {
            line: 42,
            column: 13
          }
        }],
        line: 39
      },
      '4': {
        loc: {
          start: {
            line: 41,
            column: 20
          },
          end: {
            line: 41,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 41,
            column: 20
          },
          end: {
            line: 41,
            column: 44
          }
        }, {
          start: {
            line: 41,
            column: 48
          },
          end: {
            line: 41,
            column: 50
          }
        }],
        line: 41
      },
      '5': {
        loc: {
          start: {
            line: 43,
            column: 28
          },
          end: {
            line: 43,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 43,
            column: 28
          },
          end: {
            line: 43,
            column: 51
          }
        }, {
          start: {
            line: 43,
            column: 55
          },
          end: {
            line: 43,
            column: 57
          }
        }],
        line: 43
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_hhyh3qo1r.s[0]++;
(function () {
  cov_hhyh3qo1r.f[0]++;

  function addressAutocomplete() {
    cov_hhyh3qo1r.f[1]++;
    cov_hhyh3qo1r.s[1]++;

    return {
      require: 'ngModel',
      replace: true,
      scope: {
        ngModel: '=',
        address1: '=',
        city: '=',
        state: '=',
        country: '=',
        zip: '='
      },
      template: '<input class="form-control" type="text">',
      link: function link(scope, element, attrs, model) {
        var options = (cov_hhyh3qo1r.s[2]++, {
          types: [],
          componentRestrictions: { country: 'us' }
        });

        var autocomplete = (cov_hhyh3qo1r.s[3]++, new google.maps.places.Autocomplete(element[0], options));
        cov_hhyh3qo1r.s[4]++;
        google.maps.event.addListener(autocomplete, 'place_changed', function () {
          cov_hhyh3qo1r.f[2]++;
          cov_hhyh3qo1r.s[5]++;

          scope.$apply(function () {
            cov_hhyh3qo1r.f[3]++;

            var obj = (cov_hhyh3qo1r.s[6]++, {});
            var place = (cov_hhyh3qo1r.s[7]++, autocomplete.getPlace());
            var components = (cov_hhyh3qo1r.s[8]++, place.address_components); // from Google API place object

            cov_hhyh3qo1r.s[9]++;
            obj.formattedAddress = place.formatted_address;
            cov_hhyh3qo1r.s[10]++;
            obj.geo = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            };

            var street = (cov_hhyh3qo1r.s[11]++, (cov_hhyh3qo1r.b[0][0]++, components[0].short_name + ' ' + components[1].long_name) || (cov_hhyh3qo1r.b[0][1]++, ''));
            var city = (cov_hhyh3qo1r.s[12]++, (cov_hhyh3qo1r.b[1][0]++, components[3].short_name) || (cov_hhyh3qo1r.b[1][1]++, ''));
            var state = (cov_hhyh3qo1r.s[13]++, (cov_hhyh3qo1r.b[2][0]++, components[5].short_name) || (cov_hhyh3qo1r.b[2][1]++, ''));
            var zip = (cov_hhyh3qo1r.s[14]++, '');
            cov_hhyh3qo1r.s[15]++;
            if (components[7]) {
              cov_hhyh3qo1r.b[3][0]++;
              cov_hhyh3qo1r.s[16]++;

              // this is broken. if theres no 7 it offsets the array indicies
              zip = (cov_hhyh3qo1r.b[4][0]++, components[7].short_name) || (cov_hhyh3qo1r.b[4][1]++, '');
            } else {
              cov_hhyh3qo1r.b[3][1]++;
            }
            var country = (cov_hhyh3qo1r.s[17]++, (cov_hhyh3qo1r.b[5][0]++, components[6].long_name) || (cov_hhyh3qo1r.b[5][1]++, ''));
            cov_hhyh3qo1r.s[18]++;
            obj.address = {
              street: street,
              city: city,
              state: state,
              zip: zip,
              country: country
            };
            cov_hhyh3qo1r.s[19]++;
            obj.components = components;
            cov_hhyh3qo1r.s[20]++;
            model.$setViewValue(obj);
          });
        });
      }
    };
  }

  cov_hhyh3qo1r.s[21]++;
  angular.module('app').directive('addressAutocomplete', addressAutocomplete);
})();