'use strict';

var cov_57i9gnuqc = function () {
  var path = '/workdir/src/app/users/edit-profile/edit-profile.js',
      hash = 'e589909861a2c1996f1cf17ce3adc895d9f5296b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/edit-profile/edit-profile.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 75,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 112
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 21
        },
        end: {
          line: 12,
          column: 42
        }
      },
      '5': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 21,
          column: 6
        }
      },
      '7': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 24
        }
      },
      '8': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 38,
          column: 6
        }
      },
      '9': {
        start: {
          line: 24,
          column: 28
        },
        end: {
          line: 26,
          column: 8
        }
      },
      '10': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 37,
          column: 11
        }
      },
      '11': {
        start: {
          line: 29,
          column: 26
        },
        end: {
          line: 29,
          column: 55
        }
      },
      '12': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 34,
          column: 13
        }
      },
      '13': {
        start: {
          line: 31,
          column: 12
        },
        end: {
          line: 31,
          column: 93
        }
      },
      '14': {
        start: {
          line: 32,
          column: 12
        },
        end: {
          line: 32,
          column: 58
        }
      },
      '15': {
        start: {
          line: 33,
          column: 12
        },
        end: {
          line: 33,
          column: 24
        }
      },
      '16': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 40,
          column: 22
        }
      },
      '17': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 55,
          column: 6
        }
      },
      '18': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 21
        }
      },
      '19': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 46,
          column: 7
        }
      },
      '20': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 46
        }
      },
      '21': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 15
        }
      },
      '22': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 54,
          column: 11
        }
      },
      '23': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 20
        }
      },
      '24': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 30
        }
      },
      '25': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 28
        }
      },
      '26': {
        start: {
          line: 53,
          column: 10
        },
        end: {
          line: 53,
          column: 29
        }
      },
      '27': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 65,
          column: 6
        }
      },
      '28': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 64,
          column: 11
        }
      },
      '29': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 63,
          column: 13
        }
      },
      '30': {
        start: {
          line: 62,
          column: 12
        },
        end: {
          line: 62,
          column: 29
        }
      },
      '31': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 69,
          column: 6
        }
      },
      '32': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 27
        }
      },
      '33': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 74,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 75,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'editProfileCtrl',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 26
          }
        },
        loc: {
          start: {
            line: 10,
            column: 94
          },
          end: {
            line: 70,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 16
          },
          end: {
            line: 19,
            column: 17
          }
        },
        loc: {
          start: {
            line: 19,
            column: 22
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 18
          },
          end: {
            line: 23,
            column: 19
          }
        },
        loc: {
          start: {
            line: 23,
            column: 24
          },
          end: {
            line: 38,
            column: 5
          }
        },
        line: 23
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 28,
            column: 14
          },
          end: {
            line: 28,
            column: 15
          }
        },
        loc: {
          start: {
            line: 28,
            column: 23
          },
          end: {
            line: 35,
            column: 9
          }
        },
        line: 28
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 30,
            column: 51
          },
          end: {
            line: 30,
            column: 52
          }
        },
        loc: {
          start: {
            line: 30,
            column: 62
          },
          end: {
            line: 34,
            column: 11
          }
        },
        line: 30
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 35,
            column: 11
          },
          end: {
            line: 35,
            column: 12
          }
        },
        loc: {
          start: {
            line: 35,
            column: 17
          },
          end: {
            line: 37,
            column: 9
          }
        },
        line: 35
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 41,
            column: 25
          },
          end: {
            line: 41,
            column: 26
          }
        },
        loc: {
          start: {
            line: 41,
            column: 33
          },
          end: {
            line: 55,
            column: 5
          }
        },
        line: 41
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 48,
            column: 14
          },
          end: {
            line: 48,
            column: 15
          }
        },
        loc: {
          start: {
            line: 48,
            column: 20
          },
          end: {
            line: 52,
            column: 9
          }
        },
        line: 48
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 52,
            column: 17
          },
          end: {
            line: 52,
            column: 18
          }
        },
        loc: {
          start: {
            line: 52,
            column: 26
          },
          end: {
            line: 54,
            column: 9
          }
        },
        line: 52
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 57,
            column: 19
          },
          end: {
            line: 57,
            column: 20
          }
        },
        loc: {
          start: {
            line: 57,
            column: 25
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 57
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 60,
            column: 14
          },
          end: {
            line: 60,
            column: 15
          }
        },
        loc: {
          start: {
            line: 60,
            column: 25
          },
          end: {
            line: 64,
            column: 9
          }
        },
        line: 60
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 61,
            column: 26
          },
          end: {
            line: 61,
            column: 27
          }
        },
        loc: {
          start: {
            line: 61,
            column: 34
          },
          end: {
            line: 63,
            column: 11
          }
        },
        line: 61
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 66,
            column: 22
          },
          end: {
            line: 66,
            column: 23
          }
        },
        loc: {
          start: {
            line: 66,
            column: 28
          },
          end: {
            line: 69,
            column: 5
          }
        },
        line: 66
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 17,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 17,
            column: 5
          }
        }, {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 17,
            column: 5
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 43,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 43,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }, {
          start: {
            line: 43,
            column: 6
          },
          end: {
            line: 46,
            column: 7
          }
        }],
        line: 43
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_57i9gnuqc.s[0]++;
(function () {
  cov_57i9gnuqc.f[0]++;

  var editProfile = (cov_57i9gnuqc.s[1]++, {
    templateUrl: 'app/users/edit-profile/edit-profile.html',
    controller: editProfileCtrl
  });

  cov_57i9gnuqc.s[2]++;
  editProfileCtrl.$inject = ['Users', '$stateParams', '$log', 'Modernizr', 'Auth', '$uibModal', 'Files', '$db'];

  function editProfileCtrl(Users, $stateParams, $log, Modernizr, Auth, $uibModal, Files, $db) {
    cov_57i9gnuqc.f[1]++;

    var self = (cov_57i9gnuqc.s[3]++, this);
    var username = (cov_57i9gnuqc.s[4]++, $stateParams.username);
    cov_57i9gnuqc.s[5]++;
    if (!username) {
      // handle no username
      // redirect to user not found page?
      // $state.go()

      cov_57i9gnuqc.b[0][0]++;
    } else {
      cov_57i9gnuqc.b[0][1]++;
    }

    cov_57i9gnuqc.s[6]++;
    self.save = function () {
      cov_57i9gnuqc.f[2]++;
      cov_57i9gnuqc.s[7]++;

      self.user.$save();
    };

    cov_57i9gnuqc.s[8]++;
    self.upload = function () {
      cov_57i9gnuqc.f[3]++;

      var modalInstance = (cov_57i9gnuqc.s[9]++, $uibModal.open({
        component: 'imageUpload'
      }));
      cov_57i9gnuqc.s[10]++;
      modalInstance.result.then(function (image) {
        cov_57i9gnuqc.f[4]++;

        var fileRef = (cov_57i9gnuqc.s[11]++, 'thumb-' + image.original.uid);
        cov_57i9gnuqc.s[12]++;
        Files.put(fileRef, image.thumbnail).then(function (success) {
          cov_57i9gnuqc.f[5]++;
          cov_57i9gnuqc.s[13]++;

          $db.files.child(image.original.path).child('thumbnail').set(success.downloadURL);
          cov_57i9gnuqc.s[14]++;
          self.user.profile.image = success.downloadURL;
          cov_57i9gnuqc.s[15]++;
          self.save();
        });
      }, function () {
        // modal dismiss promise resolves here.

        cov_57i9gnuqc.f[6]++;
      });
    };

    cov_57i9gnuqc.s[16]++;
    self.error = null;
    cov_57i9gnuqc.s[17]++;
    self.resetPassword = function (form) {
      cov_57i9gnuqc.f[7]++;
      cov_57i9gnuqc.s[18]++;

      $log.log(form);
      cov_57i9gnuqc.s[19]++;
      if (form.pass !== form.verify) {
        cov_57i9gnuqc.b[1][0]++;
        cov_57i9gnuqc.s[20]++;

        self.error = 'passwords do not match';
        cov_57i9gnuqc.s[21]++;
        return;
      } else {
        cov_57i9gnuqc.b[1][1]++;
      }
      cov_57i9gnuqc.s[22]++;
      Auth.$updatePassword(form.pass).then(function () {
        cov_57i9gnuqc.f[8]++;
        cov_57i9gnuqc.s[23]++;

        form = {};
        cov_57i9gnuqc.s[24]++;
        form.$setPristine();
        cov_57i9gnuqc.s[25]++;
        self.error = null;
      }).catch(function (error) {
        cov_57i9gnuqc.f[9]++;
        cov_57i9gnuqc.s[26]++;

        self.error = error;
      });
    };

    cov_57i9gnuqc.s[27]++;
    self.$onInit = function () {
      cov_57i9gnuqc.f[10]++;
      cov_57i9gnuqc.s[28]++;

      Users.getProfileByUsername(username).then(function (profile) {
        cov_57i9gnuqc.f[11]++;
        cov_57i9gnuqc.s[29]++;

        profile.$loaded(function (user) {
          cov_57i9gnuqc.f[12]++;
          cov_57i9gnuqc.s[30]++;

          self.user = user;
        });
      });
    };
    cov_57i9gnuqc.s[31]++;
    self.$onDestroy = function () {
      cov_57i9gnuqc.f[13]++;
      cov_57i9gnuqc.s[32]++;

      // find userobject by username
      self.user.$destroy();
    };
  }

  cov_57i9gnuqc.s[33]++;
  angular.module('app').component('editProfile', editProfile);
})();