'use strict';

var cov_ur1m2b5iw = function () {
  var path = '/workdir/src/app/common/activity/recent-activity/recent-activity.component.js',
      hash = '9a4aec7c1fa3191805af0981b35caa7684fc1580',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/activity/recent-activity/recent-activity.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 25,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 43
        }
      },
      '3': {
        start: {
          line: 13,
          column: 17
        },
        end: {
          line: 13,
          column: 21
        }
      },
      '4': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 19,
          column: 6
        }
      },
      '5': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 18,
          column: 9
        }
      },
      '6': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 17,
          column: 37
        }
      },
      '7': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 24,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 25,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 12,
            column: 11
          },
          end: {
            line: 12,
            column: 21
          }
        },
        loc: {
          start: {
            line: 12,
            column: 39
          },
          end: {
            line: 20,
            column: 3
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 19
          },
          end: {
            line: 14,
            column: 20
          }
        },
        loc: {
          start: {
            line: 14,
            column: 25
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 16,
            column: 12
          },
          end: {
            line: 16,
            column: 13
          }
        },
        loc: {
          start: {
            line: 16,
            column: 26
          },
          end: {
            line: 18,
            column: 7
          }
        },
        line: 16
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_ur1m2b5iw.s[0]++;
(function () {
  cov_ur1m2b5iw.f[0]++;

  var recentActivity = (cov_ur1m2b5iw.s[1]++, {
    bindings: {
      filter: '@?'
    },
    templateUrl: 'app/common/activity/recent-activity/recent-activity.html',
    controller: controller
  });

  cov_ur1m2b5iw.s[2]++;
  controller.$inject = ['ActivityService'];
  function controller(ActivityService) {
    cov_ur1m2b5iw.f[1]++;

    var self = (cov_ur1m2b5iw.s[3]++, this);
    cov_ur1m2b5iw.s[4]++;
    self.$onInit = function () {
      cov_ur1m2b5iw.f[2]++;
      cov_ur1m2b5iw.s[5]++;

      ActivityService.recent().$loaded().then(function (activities) {
        cov_ur1m2b5iw.f[3]++;
        cov_ur1m2b5iw.s[6]++;

        self.activities = activities;
      });
    };
  }

  cov_ur1m2b5iw.s[7]++;
  angular.module('dc-activity').component('recentActivity', recentActivity);
})();