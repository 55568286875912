'use strict';

var cov_2psmwut0mq = function () {
  var path = '/workdir/src/app/users/user.module.js',
      hash = '7542d18057ab393d0e65f207745d4bbd067fdf0f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/user.module.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 7,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 6,
          column: 7
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 7,
            column: 1
          }
        },
        line: 2
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2psmwut0mq.s[0]++;
(function () {
  cov_2psmwut0mq.f[0]++;
  cov_2psmwut0mq.s[1]++;

  angular.module('dc-user', ['ui.router']);
})();