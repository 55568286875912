'use strict';

var cov_218vhsxnx = function () {
  var path = '/workdir/src/app/products/product.component.js',
      hash = '002384a377023a70628138aa9c5c65a0e3b56111',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/products/product.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 25,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 52
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 21,
          column: 9
        }
      },
      '5': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 24
        }
      },
      '6': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 20,
          column: 11
        }
      },
      '7': {
        start: {
          line: 17,
          column: 10
        },
        end: {
          line: 19,
          column: 13
        }
      },
      '8': {
        start: {
          line: 18,
          column: 12
        },
        end: {
          line: 18,
          column: 40
        }
      },
      '9': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 24,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 25,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 45
          },
          end: {
            line: 22,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 12
          },
          end: {
            line: 14,
            column: 13
          }
        },
        loc: {
          start: {
            line: 14,
            column: 25
          },
          end: {
            line: 21,
            column: 7
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 16,
            column: 26
          },
          end: {
            line: 16,
            column: 27
          }
        },
        loc: {
          start: {
            line: 16,
            column: 33
          },
          end: {
            line: 20,
            column: 9
          }
        },
        line: 16
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 17,
            column: 28
          },
          end: {
            line: 17,
            column: 29
          }
        },
        loc: {
          start: {
            line: 17,
            column: 34
          },
          end: {
            line: 19,
            column: 11
          }
        },
        line: 17
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_218vhsxnx.s[0]++;
(function () {
  cov_218vhsxnx.f[0]++;

  var productList = (cov_218vhsxnx.s[1]++, {
    templateUrl: 'app/products/product-list.html',
    controller: controller
  });

  cov_218vhsxnx.s[2]++;
  controller.$inject = ['ProductService', '$scope'];

  function controller(ProductService, scope) {
    cov_218vhsxnx.f[1]++;

    var self = (cov_218vhsxnx.s[3]++, this);

    cov_218vhsxnx.s[4]++;
    ProductService.all().then(function (querySnap) {
      cov_218vhsxnx.f[2]++;
      cov_218vhsxnx.s[5]++;

      self.items = [];
      cov_218vhsxnx.s[6]++;
      querySnap.forEach(function (doc) {
        cov_218vhsxnx.f[3]++;
        cov_218vhsxnx.s[7]++;

        scope.$applyAsync(function () {
          cov_218vhsxnx.f[4]++;
          cov_218vhsxnx.s[8]++;

          self.items.push(doc.data());
        });
      });
    });
  }

  cov_218vhsxnx.s[9]++;
  angular.module('dc-products').component('productList', productList);
})();