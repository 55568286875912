'use strict';

var cov_2l4fnhwb61 = function () {
  var path = '/workdir/src/services/db.js',
      hash = '6a8883ca9f33a2677d6add6058835c007616acc9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/services/db.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 66
        }
      },
      '2': {
        start: {
          line: 5,
          column: 15
        },
        end: {
          line: 5,
          column: 35
        }
      },
      '3': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 25,
          column: 5
        }
      },
      '4': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 27,
          column: 19
        }
      },
      '5': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 31,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 32,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '$db',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 14
          }
        },
        loc: {
          start: {
            line: 3,
            column: 26
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 3
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2l4fnhwb61.s[0]++;
(function () {
  cov_2l4fnhwb61.f[0]++;

  function $db(appConfig) {
    cov_2l4fnhwb61.f[1]++;

    var db = (cov_2l4fnhwb61.s[1]++, firebase.database().ref().child(appConfig.clientID));
    var fs = (cov_2l4fnhwb61.s[2]++, firebase.firestore());
    var service = (cov_2l4fnhwb61.s[3]++, {
      root: db,
      metadata: db.child('metadata'),
      users: db.child('users'),
      // products: db.child('products'),
      products: fs.collection('products'),
      projects: db.child('projects'),
      customers: db.child('customers'),
      numbers: db.child('financials'),
      messages: db.child('messages'),
      activity: db.child('activities'),
      tickets: db.child('tickets'),
      files: db.child('files'),
      queues: db.child('queues'),
      settings: db.child('settings'),
      status: db.child('status'),
      sites: db.child('sites'),
      contacts: db.child('contacts'),
      version: firebase.database().ref().child('version')
    });

    cov_2l4fnhwb61.s[4]++;
    return service;
  }

  cov_2l4fnhwb61.s[5]++;
  angular.module('app').factory('$db', ['appConfig', $db]);
})();