'use strict';

var cov_blseo8gvd = function () {
  var path = '/workdir/src/app/components/user-helpers/user-name.js',
      hash = '7850c0be1f581643c4c5de3d4dad57bdc9ebf0e4',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/components/user-helpers/user-name.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 47,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 45
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 28,
          column: 4
        },
        end: {
          line: 35,
          column: 6
        }
      },
      '5': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 34,
          column: 7
        }
      },
      '6': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 55
        }
      },
      '7': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 41,
          column: 6
        }
      },
      '8': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '9': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 29
        }
      },
      '10': {
        start: {
          line: 44,
          column: 2
        },
        end: {
          line: 46,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 47,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'userNameCtrl',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 23
          }
        },
        loc: {
          start: {
            line: 16,
            column: 39
          },
          end: {
            line: 42,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 28,
            column: 22
          },
          end: {
            line: 28,
            column: 23
          }
        },
        loc: {
          start: {
            line: 28,
            column: 38
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 28
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 37,
            column: 21
          },
          end: {
            line: 37,
            column: 22
          }
        },
        loc: {
          start: {
            line: 37,
            column: 27
          },
          end: {
            line: 41,
            column: 5
          }
        },
        line: 37
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        }, {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        }],
        line: 29
      },
      '1': {
        loc: {
          start: {
            line: 29,
            column: 10
          },
          end: {
            line: 29,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 10
          },
          end: {
            line: 29,
            column: 23
          }
        }, {
          start: {
            line: 29,
            column: 27
          },
          end: {
            line: 29,
            column: 54
          }
        }],
        line: 29
      },
      '2': {
        loc: {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }, {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }],
        line: 38
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_blseo8gvd.s[0]++;
(function () {
  cov_blseo8gvd.f[0]++;

  var userName = (cov_blseo8gvd.s[1]++, {
    bindings: {
      uid: '<'
    },
    template: '\n      <span class="username" ng-if="$ctrl.username && !$ctrl.showLink">@{{$ctrl.username}}</span>\n      <a ui-sref="app.profile.main({username: $ctrl.username})" ng-if="$ctrl.username && $ctrl.showLink"><span class="username">@{{$ctrl.username}}</span>\n    ',
    controller: userNameCtrl
  });

  cov_blseo8gvd.s[2]++;
  userNameCtrl.$inject = ['Users', '$attrs'];

  function userNameCtrl(Users, $attrs) {
    cov_blseo8gvd.f[1]++;

    var self = (cov_blseo8gvd.s[3]++, this);
    // $onInit
    // self.$onInit = () => {
    //   if (angular.isDefined(self.uid)) {
    //     Users.getDisplayName(self.uid)
    //       .then(username => {
    //         self.username = username;
    //       });
    //   }
    // };
    // $onChanges
    cov_blseo8gvd.s[4]++;
    self.$onChanges = function (prev, next) {
      cov_blseo8gvd.f[2]++;
      cov_blseo8gvd.s[5]++;

      if ((cov_blseo8gvd.b[1][0]++, next !== prev) && (cov_blseo8gvd.b[1][1]++, angular.isDefined(self.uid))) {
        cov_blseo8gvd.b[0][0]++;
        cov_blseo8gvd.s[6]++;

        self.username = Users.getDisplayName(self.uid);
        // .then(username => {
        //   self.username = username;
        // });
      } else {
        cov_blseo8gvd.b[0][1]++;
      }
    };
    // $postLink
    cov_blseo8gvd.s[7]++;
    self.$postLink = function () {
      cov_blseo8gvd.f[3]++;
      cov_blseo8gvd.s[8]++;

      if ('userNameLink' in $attrs) {
        cov_blseo8gvd.b[2][0]++;
        cov_blseo8gvd.s[9]++;

        self.showLink = true;
      } else {
        cov_blseo8gvd.b[2][1]++;
      }
    };
  }

  cov_blseo8gvd.s[10]++;
  angular.module('app').component('userName', userName);
})();