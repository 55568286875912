'use strict';

var cov_2lgvv5e4ad = function () {
  var path = '/workdir/src/app/common/modals/update-app-version.component.js',
      hash = '71d25f174d158d0e765a15652bfa4cdd87d4bc5b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/modals/update-app-version.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 31,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 27
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 17
        },
        end: {
          line: 13,
          column: 21
        }
      },
      '3': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 19,
          column: 6
        }
      },
      '4': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 18,
          column: 9
        }
      },
      '5': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '7': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 30,
          column: 53
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 31,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 12,
            column: 11
          },
          end: {
            line: 12,
            column: 21
          }
        },
        loc: {
          start: {
            line: 12,
            column: 24
          },
          end: {
            line: 26,
            column: 3
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 14
          },
          end: {
            line: 15,
            column: 15
          }
        },
        loc: {
          start: {
            line: 15,
            column: 20
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 18
          },
          end: {
            line: 21,
            column: 19
          }
        },
        loc: {
          start: {
            line: 21,
            column: 24
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 21
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2lgvv5e4ad.s[0]++;
(function () {
  cov_2lgvv5e4ad.f[0]++;

  var updateAppVersion = (cov_2lgvv5e4ad.s[1]++, {
    bindings: {
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'app/common/modals/update-app-version.html',
    controller: controller
  });

  function controller() {
    cov_2lgvv5e4ad.f[1]++;

    var self = (cov_2lgvv5e4ad.s[2]++, this);

    cov_2lgvv5e4ad.s[3]++;
    self.ok = function () {
      cov_2lgvv5e4ad.f[2]++;
      cov_2lgvv5e4ad.s[4]++;

      self.close({
        $value: true
      });
    };

    cov_2lgvv5e4ad.s[5]++;
    self.cancel = function () {
      cov_2lgvv5e4ad.f[3]++;
      cov_2lgvv5e4ad.s[6]++;

      self.dismiss({
        $value: 'cancel'
      });
    };
  }

  cov_2lgvv5e4ad.s[7]++;
  angular.module('dc-common').component('updateAppVersion', updateAppVersion);
})();

// $ctrl.openComponentModal = function () {
//   var modalInstance = $uibModal.open({
//     animation: $ctrl.animationsEnabled,
//     component: 'modalComponent',
//     resolve: {
//       items: function () {
//         return $ctrl.items;
//       }
//     }
//   });

//   modalInstance.result.then(function (selectedItem) {
//     $ctrl.selected = selectedItem;
//   }, function () {
//     $log.info('modal-component dismissed at: ' + new Date());
//   });