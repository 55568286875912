'use strict';

var cov_2rq6rbhajc = function () {
  var path = '/workdir/src/app/common/nav/side-nav/side-nav.js',
      hash = 'ece1e31e90b2f724d88a79f11ab687253b7c6f9b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/nav/side-nav/side-nav.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 18
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 80
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 40
        }
      },
      '5': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 43
        }
      },
      '6': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 14,
          column: 27
        }
      },
      '7': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 31
        }
      },
      '8': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 24
        }
      },
      '9': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '10': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 24,
          column: 7
        }
      },
      '11': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 19,
          column: 34
        }
      },
      '12': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 41
        }
      },
      '13': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 23,
          column: 16
        }
      },
      '14': {
        start: {
          line: 22,
          column: 10
        },
        end: {
          line: 22,
          column: 37
        }
      },
      '15': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 29,
          column: 35
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 30,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 70
          },
          end: {
            line: 26,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 21
          },
          end: {
            line: 17,
            column: 22
          }
        },
        loc: {
          start: {
            line: 17,
            column: 27
          },
          end: {
            line: 25,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 17
          },
          end: {
            line: 21,
            column: 18
          }
        },
        loc: {
          start: {
            line: 21,
            column: 23
          },
          end: {
            line: 23,
            column: 9
          }
        },
        line: 21
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }],
        line: 18
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2rq6rbhajc.s[0]++;
(function () {
  cov_2rq6rbhajc.f[0]++;

  var sideNav = (cov_2rq6rbhajc.s[1]++, {
    templateUrl: 'app/common/nav/side-nav/side-nav.html',
    controller: controller
  });

  cov_2rq6rbhajc.s[2]++;
  controller.$inject = ['Users', 'responsiveBreakpoints', '$state', '$timeout'];

  function controller(Users, responsiveBreakpoints, $state, $timeout) {
    cov_2rq6rbhajc.f[1]++;

    var self = (cov_2rq6rbhajc.s[3]++, this);
    cov_2rq6rbhajc.s[4]++;
    self.screen = responsiveBreakpoints;
    cov_2rq6rbhajc.s[5]++;
    self.roles = Users.currentUser().roles;
    cov_2rq6rbhajc.s[6]++;
    self.navOpened = false;
    cov_2rq6rbhajc.s[7]++;
    self.transitioning = false;
    cov_2rq6rbhajc.s[8]++;
    self.state = $state;
    cov_2rq6rbhajc.s[9]++;
    self.navToggle = function () {
      cov_2rq6rbhajc.f[2]++;
      cov_2rq6rbhajc.s[10]++;

      if (self.screen.xs) {
        cov_2rq6rbhajc.b[0][0]++;
        cov_2rq6rbhajc.s[11]++;

        self.transitioning = true;
        cov_2rq6rbhajc.s[12]++;
        self.navOpened = !self.navOpened;
        cov_2rq6rbhajc.s[13]++;
        $timeout(function () {
          cov_2rq6rbhajc.f[3]++;
          cov_2rq6rbhajc.s[14]++;

          self.transitioning = false;
        }, 300);
      } else {
        cov_2rq6rbhajc.b[0][1]++;
      }
    };
  }

  cov_2rq6rbhajc.s[15]++;
  angular.module('dc-common').component('sideNav', sideNav);
})();