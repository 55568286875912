'use strict';

var cov_35si3pbg9 = function () {
  var path = '/workdir/src/app/users/user-list/user-list.js',
      hash = '9e2a8bf533cb88206eb382b4ed5c433f3eb6f71b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/user-list/user-list.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 37,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 43
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '5': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 31
        }
      },
      '6': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 23
        }
      },
      '7': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 31,
          column: 6
        }
      },
      '8': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '9': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 29,
          column: 13
        }
      },
      '10': {
        start: {
          line: 26,
          column: 12
        },
        end: {
          line: 26,
          column: 26
        }
      },
      '11': {
        start: {
          line: 28,
          column: 12
        },
        end: {
          line: 28,
          column: 26
        }
      },
      '12': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 36,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 37,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'userListCtrl',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 23
          }
        },
        loc: {
          start: {
            line: 10,
            column: 37
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 19
          },
          end: {
            line: 13,
            column: 20
          }
        },
        loc: {
          start: {
            line: 13,
            column: 25
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 22
          },
          end: {
            line: 22,
            column: 23
          }
        },
        loc: {
          start: {
            line: 22,
            column: 29
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 25,
            column: 16
          },
          end: {
            line: 25,
            column: 17
          }
        },
        loc: {
          start: {
            line: 25,
            column: 23
          },
          end: {
            line: 27,
            column: 11
          }
        },
        line: 25
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 27,
            column: 19
          },
          end: {
            line: 27,
            column: 20
          }
        },
        loc: {
          start: {
            line: 27,
            column: 26
          },
          end: {
            line: 29,
            column: 11
          }
        },
        line: 27
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }, {
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }],
        line: 23
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_35si3pbg9.s[0]++;
(function () {
  cov_35si3pbg9.f[0]++;

  var userList = (cov_35si3pbg9.s[1]++, {
    templateUrl: 'app/users/user-list/user-list.html',
    controller: userListCtrl
  });

  cov_35si3pbg9.s[2]++;
  userListCtrl.$inject = ['Users', '$log'];

  function userListCtrl(Users, $log) {
    cov_35si3pbg9.f[1]++;

    var self = (cov_35si3pbg9.s[3]++, this);

    cov_35si3pbg9.s[4]++;
    self.$onInit = function () {
      cov_35si3pbg9.f[2]++;
      cov_35si3pbg9.s[5]++;

      self.users = Users.all();
      cov_35si3pbg9.s[6]++;
      self.filter = '';
      // Timesheets.employees()
      //   .then(employees => {
      //     self.employees = employees;
      //   });
    };

    cov_35si3pbg9.s[7]++;
    self.removeUser = function (uid) {
      cov_35si3pbg9.f[3]++;
      cov_35si3pbg9.s[8]++;

      if (uid) {
        cov_35si3pbg9.b[0][0]++;
        cov_35si3pbg9.s[9]++;

        Users.remove(uid).then(function (res) {
          cov_35si3pbg9.f[4]++;
          cov_35si3pbg9.s[10]++;

          $log.log(res);
        }).catch(function (err) {
          cov_35si3pbg9.f[5]++;
          cov_35si3pbg9.s[11]++;

          $log.log(err);
        });
      } else {
        cov_35si3pbg9.b[0][1]++;
      }
    };
  }

  cov_35si3pbg9.s[12]++;
  angular.module('app').component('userList', userList);
})();