'use strict';

var cov_1n7ko5vgnk = function () {
  var path = '/workdir/src/app/components/image-upload/image-upload.js',
      hash = 'ada78b9e68431e026fd72ec49b8e2ba3f019fc0f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/components/image-upload/image-upload.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 57
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 38
        }
      },
      '6': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 29
        }
      },
      '7': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 29,
          column: 6
        }
      },
      '8': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 28,
          column: 9
        }
      },
      '9': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '10': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 35,
          column: 7
        }
      },
      '11': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 38
        }
      },
      '12': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 15
        }
      },
      '13': {
        start: {
          line: 38,
          column: 22
        },
        end: {
          line: 38,
          column: 63
        }
      },
      '14': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 55,
          column: 11
        }
      },
      '15': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 48,
          column: 12
        }
      },
      '16': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 73
        }
      },
      '17': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 46
        }
      },
      '18': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 34
        }
      },
      '19': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 54,
          column: 27
        }
      },
      '20': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 62,
          column: 6
        }
      },
      '21': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 61,
          column: 9
        }
      },
      '22': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 67,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 68,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 47
          },
          end: {
            line: 63,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 19
          },
          end: {
            line: 17,
            column: 20
          }
        },
        loc: {
          start: {
            line: 17,
            column: 25
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 14
          },
          end: {
            line: 22,
            column: 15
          }
        },
        loc: {
          start: {
            line: 22,
            column: 20
          },
          end: {
            line: 29,
            column: 5
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 18
          },
          end: {
            line: 31,
            column: 19
          }
        },
        loc: {
          start: {
            line: 31,
            column: 26
          },
          end: {
            line: 56,
            column: 5
          }
        },
        line: 31
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 41,
            column: 14
          },
          end: {
            line: 41,
            column: 15
          }
        },
        loc: {
          start: {
            line: 41,
            column: 25
          },
          end: {
            line: 53,
            column: 9
          }
        },
        line: 41
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 53,
            column: 17
          },
          end: {
            line: 53,
            column: 18
          }
        },
        loc: {
          start: {
            line: 53,
            column: 24
          },
          end: {
            line: 55,
            column: 9
          }
        },
        line: 53
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 58,
            column: 18
          },
          end: {
            line: 58,
            column: 19
          }
        },
        loc: {
          start: {
            line: 58,
            column: 24
          },
          end: {
            line: 62,
            column: 5
          }
        },
        line: 58
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        }, {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        }],
        line: 32
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1n7ko5vgnk.s[0]++;
(function () {
  cov_1n7ko5vgnk.f[0]++;

  var imageUpload = (cov_1n7ko5vgnk.s[1]++, {
    bindings: {
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'app/components/image-upload/image-upload.html',
    controller: controller
  });

  cov_1n7ko5vgnk.s[2]++;
  controller.$inject = ['Users', '$log', '$db', 'Files'];

  function controller(Users, $log, $db, Files) {
    cov_1n7ko5vgnk.f[1]++;

    var self = (cov_1n7ko5vgnk.s[3]++, this);

    cov_1n7ko5vgnk.s[4]++;
    self.$onInit = function () {
      cov_1n7ko5vgnk.f[2]++;
      cov_1n7ko5vgnk.s[5]++;

      self.user = Users.currentUser();
      cov_1n7ko5vgnk.s[6]++;
      self.showUpload = true;
    };

    cov_1n7ko5vgnk.s[7]++;
    self.ok = function () {
      cov_1n7ko5vgnk.f[3]++;
      cov_1n7ko5vgnk.s[8]++;

      self.close({
        $value: {
          thumbnail: self.image,
          original: self.imgObj
        }
      });
    };

    cov_1n7ko5vgnk.s[9]++;
    self.upload = function (file) {
      cov_1n7ko5vgnk.f[4]++;
      cov_1n7ko5vgnk.s[10]++;

      if (!file) {
        cov_1n7ko5vgnk.b[0][0]++;
        cov_1n7ko5vgnk.s[11]++;

        self.err = 'No File Selected';
        cov_1n7ko5vgnk.s[12]++;
        return;
      } else {
        cov_1n7ko5vgnk.b[0][1]++;
      }

      // create db key files
      var fileRef = (cov_1n7ko5vgnk.s[13]++, $db.files.child(self.user.$id).push().key);
      // upload with firebase
      cov_1n7ko5vgnk.s[14]++;
      Files.put(fileRef, file).then(function (success) {
        cov_1n7ko5vgnk.f[5]++;
        cov_1n7ko5vgnk.s[15]++;

        self.imgObj = {
          uid: fileRef,
          original: success.downloadURL,
          path: success.metadata.fullPath,
          size: success.metadata.size,
          created: success.metadata.updated
        };
        cov_1n7ko5vgnk.s[16]++;
        $db.files.child(self.user.$id).child(fileRef).set(self.imgObj);
        // toggle crop
        cov_1n7ko5vgnk.s[17]++;
        self.newImage = success.downloadURL;
        cov_1n7ko5vgnk.s[18]++;
        self.showUpload = false;
      }).catch(function (err) {
        cov_1n7ko5vgnk.f[6]++;
        cov_1n7ko5vgnk.s[19]++;

        self.error = err;
      });
    };

    cov_1n7ko5vgnk.s[20]++;
    self.cancel = function () {
      cov_1n7ko5vgnk.f[7]++;
      cov_1n7ko5vgnk.s[21]++;

      self.dismiss({
        $value: 'cancel'
      });
    };
  }

  cov_1n7ko5vgnk.s[22]++;
  angular.module('app').component('imageUpload', imageUpload);
})();

// $ctrl.openComponentModal = function () {
//   var modalInstance = $uibModal.open({
//     animation: $ctrl.animationsEnabled,
//     component: 'modalComponent',
//     resolve: {
//       items: function () {
//         return $ctrl.items;
//       }
//     }
//   });

//   modalInstance.result.then(function (selectedItem) {
//     $ctrl.selected = selectedItem;
//   }, function () {
//     $log.info('modal-component dismissed at: ' + new Date());
//   });