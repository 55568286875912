'use strict';

var cov_2kyqflubcw = function () {
  var path = '/workdir/src/app/service/ticket-line-items/ticket-line-items.component.js',
      hash = '9ca47f9ebab2d13edb2004f102c888acddf988af',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/ticket-line-items/ticket-line-items.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 56
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 22,
          column: 6
        }
      },
      '5': {
        start: {
          line: 17,
          column: 28
        },
        end: {
          line: 19,
          column: 8
        }
      },
      '6': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 21,
          column: 79
        }
      },
      '7': {
        start: {
          line: 21,
          column: 22
        },
        end: {
          line: 21,
          column: 67
        }
      },
      '8': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 36,
          column: 6
        }
      },
      '9': {
        start: {
          line: 25,
          column: 28
        },
        end: {
          line: 30,
          column: 8
        }
      },
      '10': {
        start: {
          line: 28,
          column: 26
        },
        end: {
          line: 28,
          column: 30
        }
      },
      '11': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 35,
          column: 13
        }
      },
      '12': {
        start: {
          line: 32,
          column: 22
        },
        end: {
          line: 32,
          column: 67
        }
      },
      '13': {
        start: {
          line: 34,
          column: 12
        },
        end: {
          line: 34,
          column: 59
        }
      },
      '14': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 40,
          column: 6
        }
      },
      '15': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 52
        }
      },
      '16': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 43,
          column: 71
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 44,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 50
          },
          end: {
            line: 41,
            column: 3
          }
        },
        line: 13
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 23
          },
          end: {
            line: 16,
            column: 24
          }
        },
        loc: {
          start: {
            line: 16,
            column: 29
          },
          end: {
            line: 22,
            column: 5
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 14
          },
          end: {
            line: 21,
            column: 15
          }
        },
        loc: {
          start: {
            line: 21,
            column: 22
          },
          end: {
            line: 21,
            column: 67
          }
        },
        line: 21
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 21,
            column: 69
          },
          end: {
            line: 21,
            column: 70
          }
        },
        loc: {
          start: {
            line: 21,
            column: 75
          },
          end: {
            line: 21,
            column: 77
          }
        },
        line: 21
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 24,
            column: 24
          },
          end: {
            line: 24,
            column: 25
          }
        },
        loc: {
          start: {
            line: 24,
            column: 32
          },
          end: {
            line: 36,
            column: 5
          }
        },
        line: 24
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 28,
            column: 20
          },
          end: {
            line: 28,
            column: 21
          }
        },
        loc: {
          start: {
            line: 28,
            column: 26
          },
          end: {
            line: 28,
            column: 30
          }
        },
        line: 28
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 32,
            column: 14
          },
          end: {
            line: 32,
            column: 15
          }
        },
        loc: {
          start: {
            line: 32,
            column: 22
          },
          end: {
            line: 32,
            column: 67
          }
        },
        line: 32
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 33,
            column: 10
          },
          end: {
            line: 33,
            column: 11
          }
        },
        loc: {
          start: {
            line: 33,
            column: 22
          },
          end: {
            line: 35,
            column: 11
          }
        },
        line: 33
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 38,
            column: 26
          },
          end: {
            line: 38,
            column: 27
          }
        },
        loc: {
          start: {
            line: 38,
            column: 34
          },
          end: {
            line: 40,
            column: 5
          }
        },
        line: 38
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2kyqflubcw.s[0]++;
(function () {
  cov_2kyqflubcw.f[0]++;

  var component = (cov_2kyqflubcw.s[1]++, {
    bindings: {
      ticket: '='
    },
    templateUrl: 'app/service/ticket-line-items/ticket-line-items.html',
    controller: controller
  });

  cov_2kyqflubcw.s[2]++;
  controller.$inject = ['LineItemService', '$uibModal'];

  function controller(LineItemService, $uibModal) {
    cov_2kyqflubcw.f[1]++;

    var self = (cov_2kyqflubcw.s[3]++, this);

    cov_2kyqflubcw.s[4]++;
    self.addLineItem = function () {
      cov_2kyqflubcw.f[2]++;

      var modalInstance = (cov_2kyqflubcw.s[5]++, $uibModal.open({
        component: 'lineItem'
      }));
      cov_2kyqflubcw.s[6]++;
      modalInstance.result.then(function (item) {
        cov_2kyqflubcw.f[3]++;
        cov_2kyqflubcw.s[7]++;
        return LineItemService.create(self.ticket.$id, item);
      }, function () {
        cov_2kyqflubcw.f[4]++;
      });
    };

    cov_2kyqflubcw.s[8]++;
    self.editLineItem = function (data) {
      cov_2kyqflubcw.f[5]++;

      var modalInstance = (cov_2kyqflubcw.s[9]++, $uibModal.open({
        component: 'lineItem',
        resolve: {
          lineItem: function lineItem() {
            cov_2kyqflubcw.f[6]++;
            cov_2kyqflubcw.s[10]++;
            return data;
          }
        }
      }));
      cov_2kyqflubcw.s[11]++;
      modalInstance.result.then(function (item) {
        cov_2kyqflubcw.f[7]++;
        cov_2kyqflubcw.s[12]++;
        return LineItemService.update(self.ticket.$id, item);
      }, function (original) {
        cov_2kyqflubcw.f[8]++;
        cov_2kyqflubcw.s[13]++;

        self.ticket.lineItems[original.uid] = original;
      });
    };

    cov_2kyqflubcw.s[14]++;
    self.deleteLineItem = function (item) {
      cov_2kyqflubcw.f[9]++;
      cov_2kyqflubcw.s[15]++;

      LineItemService.remove(self.ticket.$id, item);
    };
  }

  cov_2kyqflubcw.s[16]++;
  angular.module('dc-service').component('ticketLineItems', component);
})();

// self.ticket.loading = true;
// self.ticket.$ref().child('lineItems').push(lineItem);
// const activity = {
//   userId: lineItem.createdBy,
//   type: 'Line Item',
//   action: 'Added',
//   ticket: self.ticket.id
// };
// ActivityService.add(activity);