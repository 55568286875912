'use strict';

var cov_19fo1vvzv8 = function () {
  var path = '/workdir/src/app/service/create-ticket/create-ticket.component.js',
      hash = '88ee2633fc4eb106424f5e1fec81ae8c5b9f8906',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/create-ticket/create-ticket.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 41
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 18,
          column: 6
        }
      },
      '5': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 24
        }
      },
      '6': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '7': {
        start: {
          line: 21,
          column: 21
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '8': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 36,
          column: 11
        }
      },
      '9': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 35,
          column: 13
        }
      },
      '10': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 43,
          column: 6
        }
      },
      '11': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 42,
          column: 9
        }
      },
      '12': {
        start: {
          line: 46,
          column: 2
        },
        end: {
          line: 48,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 49,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 37
          },
          end: {
            line: 44,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 19
          },
          end: {
            line: 16,
            column: 20
          }
        },
        loc: {
          start: {
            line: 16,
            column: 25
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 20,
            column: 24
          },
          end: {
            line: 20,
            column: 25
          }
        },
        loc: {
          start: {
            line: 20,
            column: 30
          },
          end: {
            line: 37,
            column: 5
          }
        },
        line: 20
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 32,
            column: 14
          },
          end: {
            line: 32,
            column: 15
          }
        },
        loc: {
          start: {
            line: 32,
            column: 21
          },
          end: {
            line: 36,
            column: 9
          }
        },
        line: 32
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 39,
            column: 18
          },
          end: {
            line: 39,
            column: 19
          }
        },
        loc: {
          start: {
            line: 39,
            column: 24
          },
          end: {
            line: 43,
            column: 5
          }
        },
        line: 39
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 25,
            column: 20
          },
          end: {
            line: 25,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 25,
            column: 20
          },
          end: {
            line: 25,
            column: 33
          }
        }, {
          start: {
            line: 25,
            column: 37
          },
          end: {
            line: 25,
            column: 41
          }
        }],
        line: 25
      },
      '1': {
        loc: {
          start: {
            line: 26,
            column: 14
          },
          end: {
            line: 26,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 26,
            column: 14
          },
          end: {
            line: 26,
            column: 23
          }
        }, {
          start: {
            line: 26,
            column: 27
          },
          end: {
            line: 26,
            column: 31
          }
        }],
        line: 26
      },
      '2': {
        loc: {
          start: {
            line: 27,
            column: 19
          },
          end: {
            line: 27,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 27,
            column: 19
          },
          end: {
            line: 27,
            column: 31
          }
        }, {
          start: {
            line: 27,
            column: 35
          },
          end: {
            line: 27,
            column: 39
          }
        }],
        line: 27
      },
      '3': {
        loc: {
          start: {
            line: 28,
            column: 20
          },
          end: {
            line: 28,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 28,
            column: 20
          },
          end: {
            line: 28,
            column: 35
          }
        }, {
          start: {
            line: 28,
            column: 39
          },
          end: {
            line: 28,
            column: 43
          }
        }],
        line: 28
      },
      '4': {
        loc: {
          start: {
            line: 29,
            column: 19
          },
          end: {
            line: 29,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 19
          },
          end: {
            line: 29,
            column: 33
          }
        }, {
          start: {
            line: 29,
            column: 37
          },
          end: {
            line: 29,
            column: 41
          }
        }],
        line: 29
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_19fo1vvzv8.s[0]++;
(function () {
  cov_19fo1vvzv8.f[0]++;

  var createTicket = (cov_19fo1vvzv8.s[1]++, {
    bindings: {
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'app/service/create-ticket/create-ticket.html',
    controller: controller
  });

  cov_19fo1vvzv8.s[2]++;
  controller.$inject = ['TicketService'];

  function controller(TicketService) {
    cov_19fo1vvzv8.f[1]++;

    var self = (cov_19fo1vvzv8.s[3]++, this);
    cov_19fo1vvzv8.s[4]++;
    self.$onInit = function () {
      cov_19fo1vvzv8.f[2]++;
      cov_19fo1vvzv8.s[5]++;

      self.priority = 1; // 0 == low / 1 == normal / 5 == high / 10 == urgent
    };

    cov_19fo1vvzv8.s[6]++;
    self.createTicket = function () {
      cov_19fo1vvzv8.f[3]++;

      var ticket = (cov_19fo1vvzv8.s[7]++, {
        name: self.subject,
        description: self.description,
        priority: self.priority,
        customerId: (cov_19fo1vvzv8.b[0][0]++, self.customer) || (cov_19fo1vvzv8.b[0][1]++, null),
        site: (cov_19fo1vvzv8.b[1][0]++, self.site) || (cov_19fo1vvzv8.b[1][1]++, null),
        projectId: (cov_19fo1vvzv8.b[2][0]++, self.project) || (cov_19fo1vvzv8.b[2][1]++, null),
        assignedTo: (cov_19fo1vvzv8.b[3][0]++, self.assignedTo) || (cov_19fo1vvzv8.b[3][1]++, null),
        reference: (cov_19fo1vvzv8.b[4][0]++, self.reference) || (cov_19fo1vvzv8.b[4][1]++, null)
      });
      cov_19fo1vvzv8.s[8]++;
      TicketService.create(ticket).then(function (key) {
        cov_19fo1vvzv8.f[4]++;
        cov_19fo1vvzv8.s[9]++;

        self.close({
          $value: key
        });
      });
    };

    cov_19fo1vvzv8.s[10]++;
    self.cancel = function () {
      cov_19fo1vvzv8.f[5]++;
      cov_19fo1vvzv8.s[11]++;

      self.dismiss({
        $value: 'cancel'
      });
    };
  }

  cov_19fo1vvzv8.s[12]++;
  angular.module('dc-service').component('createTicket', createTicket);
})();