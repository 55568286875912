'use strict';

var cov_1nb2d93kmv = function () {
  var path = '/workdir/src/app/common/comment/comment-list/comment-list.component.js',
      hash = 'e0ba42dd438abe6db1f77ce107c254aead7d7bbc',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/comment/comment-list/comment-list.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 42
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '5': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 22,
          column: 13
        }
      },
      '6': {
        start: {
          line: 21,
          column: 12
        },
        end: {
          line: 21,
          column: 37
        }
      },
      '7': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 29,
          column: 7
        }
      },
      '8': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 28,
          column: 13
        }
      },
      '9': {
        start: {
          line: 27,
          column: 12
        },
        end: {
          line: 27,
          column: 37
        }
      },
      '10': {
        start: {
          line: 32,
          column: 4
        },
        end: {
          line: 34,
          column: 6
        }
      },
      '11': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 21
        }
      },
      '12': {
        start: {
          line: 36,
          column: 4
        },
        end: {
          line: 38,
          column: 6
        }
      },
      '13': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 31
        }
      },
      '14': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 43,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 44,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 38
          },
          end: {
            line: 39,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: 'grabMessages',
        decl: {
          start: {
            line: 17,
            column: 13
          },
          end: {
            line: 17,
            column: 25
          }
        },
        loc: {
          start: {
            line: 17,
            column: 28
          },
          end: {
            line: 30,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 20,
            column: 16
          },
          end: {
            line: 20,
            column: 17
          }
        },
        loc: {
          start: {
            line: 20,
            column: 28
          },
          end: {
            line: 22,
            column: 11
          }
        },
        line: 20
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 26,
            column: 16
          },
          end: {
            line: 26,
            column: 17
          }
        },
        loc: {
          start: {
            line: 26,
            column: 28
          },
          end: {
            line: 28,
            column: 11
          }
        },
        line: 26
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 32,
            column: 19
          },
          end: {
            line: 32,
            column: 20
          }
        },
        loc: {
          start: {
            line: 32,
            column: 25
          },
          end: {
            line: 34,
            column: 5
          }
        },
        line: 32
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 36,
            column: 22
          },
          end: {
            line: 36,
            column: 23
          }
        },
        loc: {
          start: {
            line: 36,
            column: 28
          },
          end: {
            line: 38,
            column: 5
          }
        },
        line: 36
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }],
        line: 18
      },
      '1': {
        loc: {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 29,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 29,
            column: 7
          }
        }, {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 29,
            column: 7
          }
        }],
        line: 24
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1nb2d93kmv.s[0]++;
(function () {
  cov_1nb2d93kmv.f[0]++;

  var commentList = (cov_1nb2d93kmv.s[1]++, {
    bindings: {
      projectId: '<?',
      ticketId: '<?'
    },
    templateUrl: 'app/common/comment/comment-list/comment-list.html',
    controller: controller
  });

  cov_1nb2d93kmv.s[2]++;
  controller.$inject = ['CommentService'];

  function controller(CommentService) {
    cov_1nb2d93kmv.f[1]++;

    var self = (cov_1nb2d93kmv.s[3]++, this);

    function grabMessages() {
      cov_1nb2d93kmv.f[2]++;
      cov_1nb2d93kmv.s[4]++;

      if (angular.isDefined(self.projectId)) {
        cov_1nb2d93kmv.b[0][0]++;
        cov_1nb2d93kmv.s[5]++;

        CommentService.getByProject(self.projectId).$loaded().then(function (messages) {
          cov_1nb2d93kmv.f[3]++;
          cov_1nb2d93kmv.s[6]++;

          self.messages = messages;
        });
      } else {
        cov_1nb2d93kmv.b[0][1]++;
      }
      cov_1nb2d93kmv.s[7]++;
      if (angular.isDefined(self.ticketId)) {
        cov_1nb2d93kmv.b[1][0]++;
        cov_1nb2d93kmv.s[8]++;

        CommentService.getByTicket(self.ticketId).$loaded().then(function (messages) {
          cov_1nb2d93kmv.f[4]++;
          cov_1nb2d93kmv.s[9]++;

          self.messages = messages;
        });
      } else {
        cov_1nb2d93kmv.b[1][1]++;
      }
    }

    cov_1nb2d93kmv.s[10]++;
    self.$onInit = function () {
      cov_1nb2d93kmv.f[5]++;
      cov_1nb2d93kmv.s[11]++;

      grabMessages();
    };

    cov_1nb2d93kmv.s[12]++;
    self.$onDestroy = function () {
      cov_1nb2d93kmv.f[6]++;
      cov_1nb2d93kmv.s[13]++;

      self.messages.$destroy();
    };
  }

  cov_1nb2d93kmv.s[14]++;
  angular.module('dc-comments').component('commentList', commentList);
})();