'use strict';

var cov_24f6c5rw82 = function () {
  var path = '/workdir/src/app/common/customer/customer.service.js',
      hash = '2f6e4ca4c670320b2151d34aaf354dc16f9328d1',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/customer/customer.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 57,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 25
        },
        end: {
          line: 5,
          column: 38
        }
      },
      '2': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 6,
          column: 24
        }
      },
      '3': {
        start: {
          line: 8,
          column: 20
        },
        end: {
          line: 14,
          column: 5
        }
      },
      '4': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '5': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 49
        }
      },
      '6': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 23
        }
      },
      '7': {
        start: {
          line: 24,
          column: 23
        },
        end: {
          line: 24,
          column: 46
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 39
        }
      },
      '9': {
        start: {
          line: 29,
          column: 20
        },
        end: {
          line: 29,
          column: 30
        }
      },
      '10': {
        start: {
          line: 30,
          column: 26
        },
        end: {
          line: 30,
          column: 49
        }
      },
      '11': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 33
        }
      },
      '12': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 41
        }
      },
      '13': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 38,
          column: 9
        }
      },
      '14': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 36,
          column: 9
        }
      },
      '15': {
        start: {
          line: 35,
          column: 10
        },
        end: {
          line: 35,
          column: 35
        }
      },
      '16': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 42
        }
      },
      '17': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 27
        }
      },
      '18': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 46
        }
      },
      '19': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 50,
          column: 7
        }
      },
      '20': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 29
        }
      },
      '21': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 25
        }
      },
      '22': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 19
        }
      },
      '23': {
        start: {
          line: 55,
          column: 2
        },
        end: {
          line: 56,
          column: 110
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 57,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'CustomerService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 26
          }
        },
        loc: {
          start: {
            line: 4,
            column: 76
          },
          end: {
            line: 53,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: 'all',
        decl: {
          start: {
            line: 16,
            column: 13
          },
          end: {
            line: 16,
            column: 16
          }
        },
        loc: {
          start: {
            line: 16,
            column: 19
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 16
      },
      '3': {
        name: '$get',
        decl: {
          start: {
            line: 23,
            column: 13
          },
          end: {
            line: 23,
            column: 17
          }
        },
        loc: {
          start: {
            line: 23,
            column: 23
          },
          end: {
            line: 26,
            column: 5
          }
        },
        line: 23
      },
      '4': {
        name: 'create',
        decl: {
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 28,
            column: 19
          }
        },
        loc: {
          start: {
            line: 28,
            column: 30
          },
          end: {
            line: 40,
            column: 5
          }
        },
        line: 28
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 33,
            column: 52
          },
          end: {
            line: 33,
            column: 53
          }
        },
        loc: {
          start: {
            line: 33,
            column: 59
          },
          end: {
            line: 38,
            column: 7
          }
        },
        line: 33
      },
      '6': {
        name: 'remove',
        decl: {
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 42,
            column: 19
          }
        },
        loc: {
          start: {
            line: 42,
            column: 25
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 42
      },
      '7': {
        name: 'reset',
        decl: {
          start: {
            line: 46,
            column: 13
          },
          end: {
            line: 46,
            column: 18
          }
        },
        loc: {
          start: {
            line: 46,
            column: 21
          },
          end: {
            line: 51,
            column: 5
          }
        },
        line: 46
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }],
        line: 17
      },
      '1': {
        loc: {
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 36,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 36,
            column: 9
          }
        }, {
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 36,
            column: 9
          }
        }],
        line: 34
      },
      '2': {
        loc: {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 50,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 50,
            column: 7
          }
        }, {
          start: {
            line: 47,
            column: 6
          },
          end: {
            line: 50,
            column: 7
          }
        }],
        line: 47
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_24f6c5rw82.s[0]++;
(function () {
  cov_24f6c5rw82.f[0]++;

  // defines function Projects
  function CustomerService($db, Utils, $firebaseArray, $firebaseObject, $q) {
    cov_24f6c5rw82.f[1]++;

    var customersRef = (cov_24f6c5rw82.s[1]++, $db.customers);
    var customers = (cov_24f6c5rw82.s[2]++, null);

    var service = (cov_24f6c5rw82.s[3]++, {
      all: all,
      $get: $get,
      create: create,
      remove: remove,
      reset: reset
    });

    function all() {
      cov_24f6c5rw82.f[2]++;
      cov_24f6c5rw82.s[4]++;

      if (!customers) {
        cov_24f6c5rw82.b[0][0]++;
        cov_24f6c5rw82.s[5]++;

        customers = $firebaseArray(customersRef);
      } else {
        cov_24f6c5rw82.b[0][1]++;
      }
      cov_24f6c5rw82.s[6]++;
      return customers;
    }

    function $get(uid) {
      cov_24f6c5rw82.f[3]++;

      var customer = (cov_24f6c5rw82.s[7]++, customersRef.child(uid));
      cov_24f6c5rw82.s[8]++;
      return $firebaseObject(customer);
    }

    function create(customer) {
      cov_24f6c5rw82.f[4]++;

      var defer = (cov_24f6c5rw82.s[9]++, $q.defer());
      var customerRef = (cov_24f6c5rw82.s[10]++, customersRef.push().key);
      cov_24f6c5rw82.s[11]++;
      customer.uid = customerRef;
      cov_24f6c5rw82.s[12]++;
      customer.createdDate = Utils.now();
      cov_24f6c5rw82.s[13]++;
      customersRef.child(customerRef).set(customer, function (err) {
        cov_24f6c5rw82.f[5]++;
        cov_24f6c5rw82.s[14]++;

        if (err) {
          cov_24f6c5rw82.b[1][0]++;
          cov_24f6c5rw82.s[15]++;

          return defer.reject(err);
        } else {
          cov_24f6c5rw82.b[1][1]++;
        }
        cov_24f6c5rw82.s[16]++;
        return defer.resolve(customerRef);
      });
      cov_24f6c5rw82.s[17]++;
      return defer.promise;
    }

    function remove(uid) {
      cov_24f6c5rw82.f[6]++;
      cov_24f6c5rw82.s[18]++;

      return customersRef.child(uid).remove();
    }

    function reset() {
      cov_24f6c5rw82.f[7]++;
      cov_24f6c5rw82.s[19]++;

      if (customers) {
        cov_24f6c5rw82.b[2][0]++;
        cov_24f6c5rw82.s[20]++;

        customers.$destroy();
        cov_24f6c5rw82.s[21]++;
        customers = null;
      } else {
        cov_24f6c5rw82.b[2][1]++;
      }
    }
    cov_24f6c5rw82.s[22]++;
    return service;
  }

  cov_24f6c5rw82.s[23]++;
  angular.module('dc-customer').factory('CustomerService', ['$db', 'Utils', '$firebaseArray', '$firebaseObject', '$q', CustomerService]);
})();