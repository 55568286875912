'use strict';

var cov_23bz54p814 = function () {
  var path = '/workdir/src/app/service/sign-ticket/sign-ticket.component.js',
      hash = '580b37af774e248fdacbe15dd3401bad91504a46',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/service/sign-ticket/sign-ticket.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 26
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 22,
          column: 6
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 21,
          column: 8
        }
      },
      '6': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '7': {
        start: {
          line: 25,
          column: 24
        },
        end: {
          line: 25,
          column: 37
        }
      },
      '8': {
        start: {
          line: 26,
          column: 19
        },
        end: {
          line: 26,
          column: 28
        }
      },
      '9': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 32,
          column: 9
        }
      },
      '10': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 39,
          column: 6
        }
      },
      '11': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 38,
          column: 9
        }
      },
      '12': {
        start: {
          line: 42,
          column: 2
        },
        end: {
          line: 44,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 45,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 24
          },
          end: {
            line: 40,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 19
          },
          end: {
            line: 17,
            column: 20
          }
        },
        loc: {
          start: {
            line: 17,
            column: 25
          },
          end: {
            line: 22,
            column: 5
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 16
          },
          end: {
            line: 24,
            column: 17
          }
        },
        loc: {
          start: {
            line: 24,
            column: 22
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 24
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 35,
            column: 18
          },
          end: {
            line: 35,
            column: 19
          }
        },
        loc: {
          start: {
            line: 35,
            column: 24
          },
          end: {
            line: 39,
            column: 5
          }
        },
        line: 35
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_23bz54p814.s[0]++;
(function () {
  cov_23bz54p814.f[0]++;

  var signTicket = (cov_23bz54p814.s[1]++, {
    bindings: {
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'app/service/sign-ticket/sign-ticket.html',
    controller: controller
  });

  cov_23bz54p814.s[2]++;
  controller.$inject = [];

  function controller() {
    cov_23bz54p814.f[1]++;

    var self = (cov_23bz54p814.s[3]++, this);

    cov_23bz54p814.s[4]++;
    self.$onInit = function () {
      cov_23bz54p814.f[2]++;
      cov_23bz54p814.s[5]++;

      self.name = {
        first: '',
        last: ''
      };
    };

    cov_23bz54p814.s[6]++;
    self.done = function () {
      cov_23bz54p814.f[3]++;

      var signature = (cov_23bz54p814.s[7]++, self.accept());
      var name = (cov_23bz54p814.s[8]++, self.name);
      cov_23bz54p814.s[9]++;
      self.close({
        $value: {
          signature: signature,
          name: name
        }
      });
    };

    cov_23bz54p814.s[10]++;
    self.cancel = function () {
      cov_23bz54p814.f[4]++;
      cov_23bz54p814.s[11]++;

      self.dismiss({
        $value: 'cancel'
      });
    };
  }

  cov_23bz54p814.s[12]++;
  angular.module('dc-service').component('signTicket', signTicket);
})();