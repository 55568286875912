'use strict';

var cov_2b46mijmtx = function () {
  var path = '/workdir/src/app/common/activity/activity-log/activity-log.component.js',
      hash = '163289cf0b71a97900387500ab6cc8208a27f7d4',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/activity/activity-log/activity-log.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 24
        },
        end: {
          line: 7,
          column: 3
        }
      },
      '2': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 43
        }
      },
      '3': {
        start: {
          line: 12,
          column: 17
        },
        end: {
          line: 12,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 17,
          column: 6
        }
      },
      '5': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 16,
          column: 9
        }
      },
      '6': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 37
        }
      },
      '7': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '8': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 33
        }
      },
      '9': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 25,
          column: 47
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 26,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 11,
            column: 11
          },
          end: {
            line: 11,
            column: 21
          }
        },
        loc: {
          start: {
            line: 11,
            column: 39
          },
          end: {
            line: 21,
            column: 3
          }
        },
        line: 11
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 19
          },
          end: {
            line: 13,
            column: 20
          }
        },
        loc: {
          start: {
            line: 13,
            column: 25
          },
          end: {
            line: 17,
            column: 5
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 14,
            column: 46
          },
          end: {
            line: 14,
            column: 47
          }
        },
        loc: {
          start: {
            line: 14,
            column: 60
          },
          end: {
            line: 16,
            column: 7
          }
        },
        line: 14
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 18,
            column: 22
          },
          end: {
            line: 18,
            column: 23
          }
        },
        loc: {
          start: {
            line: 18,
            column: 28
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 18
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2b46mijmtx.s[0]++;
(function () {
  cov_2b46mijmtx.f[0]++;

  var dcActivityLog = (cov_2b46mijmtx.s[1]++, {
    bindings: {},
    templateUrl: 'app/common/activity/activity-log/activity-log.html',
    controller: controller
  });

  cov_2b46mijmtx.s[2]++;
  controller.$inject = ['ActivityService'];

  function controller(ActivityService) {
    cov_2b46mijmtx.f[1]++;

    var self = (cov_2b46mijmtx.s[3]++, this);
    cov_2b46mijmtx.s[4]++;
    self.$onInit = function () {
      cov_2b46mijmtx.f[2]++;
      cov_2b46mijmtx.s[5]++;

      ActivityService.getActivities().$loaded(function (activities) {
        cov_2b46mijmtx.f[3]++;
        cov_2b46mijmtx.s[6]++;

        self.activities = activities;
      });
    };
    cov_2b46mijmtx.s[7]++;
    self.$onDestroy = function () {
      cov_2b46mijmtx.f[4]++;
      cov_2b46mijmtx.s[8]++;

      self.activities.$destroy();
    };
  }

  cov_2b46mijmtx.s[9]++;
  angular.module('dc-activity').component('dcActivityLog', dcActivityLog);
})();