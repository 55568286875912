'use strict';

var cov_1dphn98eec = function () {
  var path = '/workdir/src/filters/default.js',
      hash = 'a50a72b716e73a6d84654075f4e207e5b66ffc44',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/filters/default.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 12,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 6,
          column: 6
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 26
        }
      },
      '3': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 11,
          column: 32
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 12,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'Default',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 18
          }
        },
        loc: {
          start: {
            line: 3,
            column: 21
          },
          end: {
            line: 7,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 12
          }
        },
        loc: {
          start: {
            line: 4,
            column: 33
          },
          end: {
            line: 6,
            column: 5
          }
        },
        line: 4
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 5,
            column: 13
          },
          end: {
            line: 5,
            column: 25
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 5,
            column: 13
          },
          end: {
            line: 5,
            column: 18
          }
        }, {
          start: {
            line: 5,
            column: 22
          },
          end: {
            line: 5,
            column: 25
          }
        }],
        line: 5
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1dphn98eec.s[0]++;
(function () {
  cov_1dphn98eec.f[0]++;

  function Default() {
    cov_1dphn98eec.f[1]++;
    cov_1dphn98eec.s[1]++;

    return function (value, def) {
      cov_1dphn98eec.f[2]++;
      cov_1dphn98eec.s[2]++;

      return (cov_1dphn98eec.b[0][0]++, value) || (cov_1dphn98eec.b[0][1]++, def);
    };
  }

  cov_1dphn98eec.s[3]++;
  angular.module('app').filter('default', Default);
})();