'use strict';

var cov_2gqtekdj6p = function () {
  var path = '/workdir/src/app/users/user.service.js',
      hash = '547bec42298449d4be25776a11401d7c0de45c8a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/user.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 120,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 21
        },
        end: {
          line: 5,
          column: 30
        }
      },
      '2': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 19
        }
      },
      '3': {
        start: {
          line: 7,
          column: 16
        },
        end: {
          line: 7,
          column: 20
        }
      },
      '4': {
        start: {
          line: 8,
          column: 20
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '5': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 26,
          column: 7
        }
      },
      '7': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 61
        }
      },
      '8': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '9': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 41
        }
      },
      '10': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 19
        }
      },
      '11': {
        start: {
          line: 37,
          column: 20
        },
        end: {
          line: 37,
          column: 30
        }
      },
      '12': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 47,
          column: 11
        }
      },
      '13': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 44,
          column: 11
        }
      },
      '14': {
        start: {
          line: 41,
          column: 12
        },
        end: {
          line: 41,
          column: 41
        }
      },
      '15': {
        start: {
          line: 43,
          column: 12
        },
        end: {
          line: 43,
          column: 40
        }
      },
      '16': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 28
        }
      },
      '17': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 27
        }
      },
      '18': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 57
        }
      },
      '19': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 50
        }
      },
      '20': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 60,
          column: 18
        }
      },
      '21': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 27
        }
      },
      '22': {
        start: {
          line: 68,
          column: 16
        },
        end: {
          line: 71,
          column: 32
        }
      },
      '23': {
        start: {
          line: 72,
          column: 6
        },
        end: {
          line: 72,
          column: 31
        }
      },
      '24': {
        start: {
          line: 76,
          column: 20
        },
        end: {
          line: 76,
          column: 30
        }
      },
      '25': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 85,
          column: 11
        }
      },
      '26': {
        start: {
          line: 82,
          column: 10
        },
        end: {
          line: 84,
          column: 13
        }
      },
      '27': {
        start: {
          line: 83,
          column: 12
        },
        end: {
          line: 83,
          column: 51
        }
      },
      '28': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 27
        }
      },
      '29': {
        start: {
          line: 90,
          column: 25
        },
        end: {
          line: 90,
          column: 54
        }
      },
      '30': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 93,
          column: 7
        }
      },
      '31': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 92,
          column: 29
        }
      },
      '32': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 41
        }
      },
      '33': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '34': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 24
        }
      },
      '35': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 20
        }
      },
      '36': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '37': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 25
        }
      },
      '38': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 21
        }
      },
      '39': {
        start: {
          line: 115,
          column: 4
        },
        end: {
          line: 115,
          column: 19
        }
      },
      '40': {
        start: {
          line: 118,
          column: 2
        },
        end: {
          line: 119,
          column: 98
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 120,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'Users',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 16
          }
        },
        loc: {
          start: {
            line: 4,
            column: 72
          },
          end: {
            line: 116,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 34
          },
          end: {
            line: 21,
            column: 35
          }
        },
        loc: {
          start: {
            line: 21,
            column: 46
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 21
      },
      '3': {
        name: 'all',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 16
          }
        },
        loc: {
          start: {
            line: 29,
            column: 19
          },
          end: {
            line: 34,
            column: 5
          }
        },
        line: 29
      },
      '4': {
        name: 'remove',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 19
          }
        },
        loc: {
          start: {
            line: 36,
            column: 25
          },
          end: {
            line: 49,
            column: 5
          }
        },
        line: 36
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 39,
            column: 14
          },
          end: {
            line: 39,
            column: 15
          }
        },
        loc: {
          start: {
            line: 39,
            column: 25
          },
          end: {
            line: 45,
            column: 9
          }
        },
        line: 39
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 45,
            column: 11
          },
          end: {
            line: 45,
            column: 12
          }
        },
        loc: {
          start: {
            line: 45,
            column: 18
          },
          end: {
            line: 47,
            column: 9
          }
        },
        line: 45
      },
      '7': {
        name: 'getAvatar',
        decl: {
          start: {
            line: 51,
            column: 13
          },
          end: {
            line: 51,
            column: 22
          }
        },
        loc: {
          start: {
            line: 51,
            column: 28
          },
          end: {
            line: 53,
            column: 5
          }
        },
        line: 51
      },
      '8': {
        name: 'getProfile',
        decl: {
          start: {
            line: 55,
            column: 13
          },
          end: {
            line: 55,
            column: 23
          }
        },
        loc: {
          start: {
            line: 55,
            column: 29
          },
          end: {
            line: 57,
            column: 5
          }
        },
        line: 55
      },
      '9': {
        name: 'currentUser',
        decl: {
          start: {
            line: 59,
            column: 13
          },
          end: {
            line: 59,
            column: 24
          }
        },
        loc: {
          start: {
            line: 59,
            column: 27
          },
          end: {
            line: 61,
            column: 5
          }
        },
        line: 59
      },
      '10': {
        name: 'isAuthenticated',
        decl: {
          start: {
            line: 63,
            column: 13
          },
          end: {
            line: 63,
            column: 28
          }
        },
        loc: {
          start: {
            line: 63,
            column: 31
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 63
      },
      '11': {
        name: 'queryByName',
        decl: {
          start: {
            line: 67,
            column: 13
          },
          end: {
            line: 67,
            column: 24
          }
        },
        loc: {
          start: {
            line: 67,
            column: 32
          },
          end: {
            line: 73,
            column: 5
          }
        },
        line: 67
      },
      '12': {
        name: 'getProfileByUsername',
        decl: {
          start: {
            line: 75,
            column: 13
          },
          end: {
            line: 75,
            column: 33
          }
        },
        loc: {
          start: {
            line: 75,
            column: 44
          },
          end: {
            line: 87,
            column: 5
          }
        },
        line: 75
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 81,
            column: 14
          },
          end: {
            line: 81,
            column: 15
          }
        },
        loc: {
          start: {
            line: 81,
            column: 26
          },
          end: {
            line: 85,
            column: 9
          }
        },
        line: 81
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 82,
            column: 27
          },
          end: {
            line: 82,
            column: 28
          }
        },
        loc: {
          start: {
            line: 82,
            column: 38
          },
          end: {
            line: 84,
            column: 11
          }
        },
        line: 82
      },
      '15': {
        name: 'getDisplayName',
        decl: {
          start: {
            line: 89,
            column: 13
          },
          end: {
            line: 89,
            column: 27
          }
        },
        loc: {
          start: {
            line: 89,
            column: 33
          },
          end: {
            line: 102,
            column: 5
          }
        },
        line: 89
      },
      '16': {
        name: 'reset',
        decl: {
          start: {
            line: 104,
            column: 13
          },
          end: {
            line: 104,
            column: 18
          }
        },
        loc: {
          start: {
            line: 104,
            column: 21
          },
          end: {
            line: 113,
            column: 5
          }
        },
        line: 104
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }, {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }, {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }],
        line: 30
      },
      '2': {
        loc: {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 44,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 44,
            column: 11
          }
        }, {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 44,
            column: 11
          }
        }],
        line: 40
      },
      '3': {
        loc: {
          start: {
            line: 91,
            column: 6
          },
          end: {
            line: 93,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 91,
            column: 6
          },
          end: {
            line: 93,
            column: 7
          }
        }, {
          start: {
            line: 91,
            column: 6
          },
          end: {
            line: 93,
            column: 7
          }
        }],
        line: 91
      },
      '4': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }],
        line: 105
      },
      '5': {
        loc: {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }, {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }],
        line: 109
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2gqtekdj6p.s[0]++;
(function () {
  cov_2gqtekdj6p.f[0]++;

  // defines function Users
  function Users(Auth, $db, $firebaseArray, $firebaseObject, $http, $q) {
    cov_2gqtekdj6p.f[1]++;

    var usersRef = (cov_2gqtekdj6p.s[1]++, $db.users);
    var user = (cov_2gqtekdj6p.s[2]++, null);
    var users = (cov_2gqtekdj6p.s[3]++, null);
    var service = (cov_2gqtekdj6p.s[4]++, {
      all: all,
      isAuthenticated: isAuthenticated,
      getProfile: getProfile,
      getAvatar: getAvatar,
      getProfileByUsername: getProfileByUsername,
      getDisplayName: getDisplayName,
      queryByName: queryByName,
      currentUser: currentUser,
      remove: remove,
      reset: reset
    });

    cov_2gqtekdj6p.s[5]++;
    Auth.auth.$onAuthStateChanged(function (authData) {
      cov_2gqtekdj6p.f[2]++;
      cov_2gqtekdj6p.s[6]++;

      if (authData) {
        cov_2gqtekdj6p.b[0][0]++;
        cov_2gqtekdj6p.s[7]++;

        user = $firebaseObject(usersRef.child(authData.uid));
      } else {
        // $state.go('login');

        cov_2gqtekdj6p.b[0][1]++;
      }
    });

    function all() {
      cov_2gqtekdj6p.f[3]++;
      cov_2gqtekdj6p.s[8]++;

      if (!users) {
        cov_2gqtekdj6p.b[1][0]++;
        cov_2gqtekdj6p.s[9]++;

        users = $firebaseArray(usersRef);
      } else {
        cov_2gqtekdj6p.b[1][1]++;
      }
      cov_2gqtekdj6p.s[10]++;
      return users;
    }

    function remove(uid) {
      cov_2gqtekdj6p.f[4]++;

      var defer = (cov_2gqtekdj6p.s[11]++, $q.defer());
      cov_2gqtekdj6p.s[12]++;
      $http.delete('api/remove-user?uid=' + uid).then(function (success) {
        cov_2gqtekdj6p.f[5]++;
        cov_2gqtekdj6p.s[13]++;

        if (success.data === 'success') {
          cov_2gqtekdj6p.b[2][0]++;
          cov_2gqtekdj6p.s[14]++;

          usersRef.child(uid).remove();
          // should probably just mark as inactive user instead.
          cov_2gqtekdj6p.s[15]++;
          defer.resolve(success.data);
        } else {
          cov_2gqtekdj6p.b[2][1]++;
        }
      }, function (err) {
        cov_2gqtekdj6p.f[6]++;
        cov_2gqtekdj6p.s[16]++;

        defer.reject(err);
      });
      cov_2gqtekdj6p.s[17]++;
      return defer.promise;
    }

    function getAvatar(uid) {
      cov_2gqtekdj6p.f[7]++;
      cov_2gqtekdj6p.s[18]++;

      return service.all().$getRecord(uid).profile.image;
    }

    function getProfile(uid) {
      cov_2gqtekdj6p.f[8]++;
      cov_2gqtekdj6p.s[19]++;

      return $firebaseObject(usersRef.child(uid));
    }

    function currentUser() {
      cov_2gqtekdj6p.f[9]++;
      cov_2gqtekdj6p.s[20]++;

      return user;
    }

    function isAuthenticated() {
      cov_2gqtekdj6p.f[10]++;
      cov_2gqtekdj6p.s[21]++;

      return Boolean(user);
    }

    function queryByName(query) {
      cov_2gqtekdj6p.f[11]++;

      var q = (cov_2gqtekdj6p.s[22]++, usersRef.orderByChild('profile/username').startAt(query).endAt(query + '\uF8FF'));
      cov_2gqtekdj6p.s[23]++;
      return $firebaseArray(q);
    }

    function getProfileByUsername(username) {
      cov_2gqtekdj6p.f[12]++;

      var defer = (cov_2gqtekdj6p.s[24]++, $q.defer());
      cov_2gqtekdj6p.s[25]++;
      usersRef.orderByChild('profile/username').equalTo(username).once('value').then(function (snapshot) {
        cov_2gqtekdj6p.f[13]++;
        cov_2gqtekdj6p.s[26]++;

        snapshot.forEach(function (userObj) {
          cov_2gqtekdj6p.f[14]++;
          cov_2gqtekdj6p.s[27]++;

          defer.resolve(getProfile(userObj.key));
        });
      });
      cov_2gqtekdj6p.s[28]++;
      return defer.promise;
    }

    function getDisplayName(uid) {
      cov_2gqtekdj6p.f[15]++;

      var userRecord = (cov_2gqtekdj6p.s[29]++, service.all().$getRecord(uid));
      cov_2gqtekdj6p.s[30]++;
      if (!userRecord) {
        cov_2gqtekdj6p.b[3][0]++;
        cov_2gqtekdj6p.s[31]++;

        return 'userdeleted';
      } else {
        cov_2gqtekdj6p.b[3][1]++;
      }
      cov_2gqtekdj6p.s[32]++;
      return userRecord.profile.username;
      // const defer = $q.defer();
      // $firebaseObject(usersRef.child(uid))
      //   .$loaded()
      //   .then(userData => {
      //     defer.resolve(userData.profile.username);
      //   });
      // return defer.promise;
    }

    function reset() {
      cov_2gqtekdj6p.f[16]++;
      cov_2gqtekdj6p.s[33]++;

      if (user) {
        cov_2gqtekdj6p.b[4][0]++;
        cov_2gqtekdj6p.s[34]++;

        user.$destroy();
        cov_2gqtekdj6p.s[35]++;
        user = null;
      } else {
        cov_2gqtekdj6p.b[4][1]++;
      }
      cov_2gqtekdj6p.s[36]++;
      if (users) {
        cov_2gqtekdj6p.b[5][0]++;
        cov_2gqtekdj6p.s[37]++;

        users.$destroy();
        cov_2gqtekdj6p.s[38]++;
        users = null;
      } else {
        cov_2gqtekdj6p.b[5][1]++;
      }
    }

    cov_2gqtekdj6p.s[39]++;
    return service;
  }

  cov_2gqtekdj6p.s[40]++;
  angular.module('dc-user').factory('Users', ['Auth', '$db', '$firebaseArray', '$firebaseObject', '$http', '$q', Users]);
})();