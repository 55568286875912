'use strict';

var cov_2ba7uh3c5x = function () {
  var path = '/workdir/src/services/numbers.js',
      hash = 'a828419801519caf428597d2fc0e365b66489a2c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/services/numbers.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 114,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 23
        },
        end: {
          line: 5,
          column: 34
        }
      },
      '2': {
        start: {
          line: 7,
          column: 20
        },
        end: {
          line: 10,
          column: 5
        }
      },
      '3': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 52
        }
      },
      '4': {
        start: {
          line: 109,
          column: 4
        },
        end: {
          line: 109,
          column: 19
        }
      },
      '5': {
        start: {
          line: 112,
          column: 2
        },
        end: {
          line: 113,
          column: 70
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 114,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'Numbers',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 18
          }
        },
        loc: {
          start: {
            line: 4,
            column: 48
          },
          end: {
            line: 110,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: 'get',
        decl: {
          start: {
            line: 12,
            column: 13
          },
          end: {
            line: 12,
            column: 16
          }
        },
        loc: {
          start: {
            line: 12,
            column: 22
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 12
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2ba7uh3c5x.s[0]++;
(function () {
  cov_2ba7uh3c5x.f[0]++;

  // defines function Projects
  function Numbers($db, Utils, $firebaseObject) {
    cov_2ba7uh3c5x.f[1]++;

    var numbersRef = (cov_2ba7uh3c5x.s[1]++, $db.numbers);

    var service = (cov_2ba7uh3c5x.s[2]++, {
      get: get
      // create
    });

    function get(uid) {
      cov_2ba7uh3c5x.f[2]++;
      cov_2ba7uh3c5x.s[3]++;

      return $firebaseObject(numbersRef.child(uid));
    }

    // function create(projectID) {
    //   const defaults = {
    //     changeOrders: {
    //       displayOrder: 11,
    //       billed: 0,
    //       cost: 0,
    //       name: 'Change Orders',
    //       perComplete: 0
    //     },
    //     closeOut: {
    //       displayOrder: 9,
    //       billed: 0,
    //       cost: 0,
    //       estimated: 0,
    //       name: 'Close Out',
    //       perComplete: 0
    //     },
    //     commisioning: {
    //       displayOrder: 8,
    //       billed: 0,
    //       estimated: 0,
    //       name: 'Commisioning',
    //       perComplete: 0
    //     },
    //     databases: {
    //       displayOrder: 4,
    //       billed: 0,
    //       estimated: 0,
    //       name: 'Databases',
    //       perComplete: 0
    //     },
    //     graphics: {
    //       displayOrder: 5,
    //       billed: 0,
    //       estimated: 0,
    //       name: 'Graphics',
    //       perComplete: 0
    //     },
    //     material: {
    //       displayOrder: 2,
    //       billed: 0,
    //       cost: 0,
    //       estimated: 0,
    //       name: 'Materials',
    //       perComplete: 0
    //     },
    //     panels: {
    //       displayOrder: 6,
    //       billed: 0,
    //       estimated: 0,
    //       name: 'Panels',
    //       perComplete: 0
    //     },
    //     pm: {
    //       displayOrder: 3,
    //       billed: 0,
    //       cost: 0,
    //       estimated: 0,
    //       name: 'Project Management',
    //       perComplete: 0
    //     },
    //     sub: {
    //       displayOrder: 10,
    //       billed: 0,
    //       cost: 0,
    //       estimated: 0,
    //       name: 'SubContractor',
    //       perComplete: 0
    //     },
    //     submittal: {
    //       displayOrder: 1,
    //       billed: 0,
    //       cost: 0,
    //       estimated: 0,
    //       name: 'Submittals',
    //       perComplete: 0
    //     },
    //     tech: {
    //       displayOrder: 7,
    //       billed: 0,
    //       cost: 0,
    //       estimated: 0,
    //       name: 'Tech',
    //       perComplete: 0
    //     }
    //   };
    //
    //   return numbersRef.child(projectID).set(defaults,
    //     () => {
    //       // handle callback if needed
    //     });
    // }

    cov_2ba7uh3c5x.s[4]++;
    return service;
  }

  cov_2ba7uh3c5x.s[5]++;
  angular.module('app').factory('Numbers', ['$db', 'Utils', '$firebaseObject', Numbers]);
})();