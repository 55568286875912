'use strict';

var cov_115vujqgmp = function () {
  var path = '/workdir/src/app/common/customer/sites/helpers/site-name.component.js',
      hash = 'c7944e6ef43457a4490fc1c35ca71ed6d297f335',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/common/customer/sites/helpers/site-name.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 41,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 51
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 19
        }
      },
      '5': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 29,
          column: 6
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 28,
          column: 7
        }
      },
      '7': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 27,
          column: 13
        }
      },
      '8': {
        start: {
          line: 25,
          column: 12
        },
        end: {
          line: 25,
          column: 35
        }
      },
      '9': {
        start: {
          line: 26,
          column: 12
        },
        end: {
          line: 26,
          column: 27
        }
      },
      '10': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 35,
          column: 6
        }
      },
      '11': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 34,
          column: 7
        }
      },
      '12': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 29
        }
      },
      '13': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 40,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 41,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 42
          },
          end: {
            line: 36,
            column: 3
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 19
          },
          end: {
            line: 21,
            column: 20
          }
        },
        loc: {
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 29,
            column: 5
          }
        },
        line: 21
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 16
          },
          end: {
            line: 24,
            column: 17
          }
        },
        loc: {
          start: {
            line: 24,
            column: 24
          },
          end: {
            line: 27,
            column: 11
          }
        },
        line: 24
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 21
          },
          end: {
            line: 31,
            column: 22
          }
        },
        loc: {
          start: {
            line: 31,
            column: 27
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 31
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }, {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        }, {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        }],
        line: 32
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_115vujqgmp.s[0]++;
(function () {
  cov_115vujqgmp.f[0]++;

  var siteName = (cov_115vujqgmp.s[1]++, {
    bindings: {
      uid: '<'
    },
    template: '\n      <span class="site" ng-if="$ctrl.site && !$ctrl.showLink">{{$ctrl.site}}</span>\n      <a ui-sref="" ng-if="$ctrl.site && $ctrl.showLink"><span class="site">{{$ctrl.site}}</span>\n    ',
    controller: controller
  });

  cov_115vujqgmp.s[2]++;
  controller.$inject = ['$db', '$attrs', '$scope'];

  function controller($db, $attrs, scope) {
    cov_115vujqgmp.f[1]++;

    var self = (cov_115vujqgmp.s[3]++, this);
    cov_115vujqgmp.s[4]++;
    self.site = '';

    // $onChanges
    cov_115vujqgmp.s[5]++;
    self.$onInit = function () {
      cov_115vujqgmp.f[2]++;
      cov_115vujqgmp.s[6]++;

      if (angular.isDefined(self.uid)) {
        cov_115vujqgmp.b[0][0]++;
        cov_115vujqgmp.s[7]++;

        $db.sites.child(self.uid + '/name').once('value').then(function (data) {
          cov_115vujqgmp.f[3]++;
          cov_115vujqgmp.s[8]++;

          self.site = data.val();
          cov_115vujqgmp.s[9]++;
          scope.$apply();
        });
      } else {
        cov_115vujqgmp.b[0][1]++;
      }
    };
    // $postLink
    cov_115vujqgmp.s[10]++;
    self.$postLink = function () {
      cov_115vujqgmp.f[4]++;
      cov_115vujqgmp.s[11]++;

      if ('includeLink' in $attrs) {
        cov_115vujqgmp.b[1][0]++;
        cov_115vujqgmp.s[12]++;

        self.showLink = true;
      } else {
        cov_115vujqgmp.b[1][1]++;
      }
    };
  }

  cov_115vujqgmp.s[13]++;
  angular.module('dc-customer').component('siteName', siteName);
})();