'use strict';

var cov_yxn8jo7y8 = function () {
  var path = '/workdir/src/app/users/create-user/create-user.js',
      hash = 'd5cfa13e56b6dbcea708d4282d4cc1c5916a236e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/users/create-user/create-user.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 71,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 81
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 29,
          column: 6
        }
      },
      '5': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 38,
          column: 6
        }
      },
      '6': {
        start: {
          line: 32,
          column: 19
        },
        end: {
          line: 35,
          column: 7
        }
      },
      '7': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 37,
          column: 30
        }
      },
      '8': {
        start: {
          line: 42,
          column: 23
        },
        end: {
          line: 42,
          column: 36
        }
      },
      '9': {
        start: {
          line: 43,
          column: 18
        },
        end: {
          line: 43,
          column: 30
        }
      },
      '10': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 60,
          column: 11
        }
      },
      '11': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 40
        }
      },
      '12': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 46
        }
      },
      '13': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 42
        }
      },
      '14': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 106
        }
      },
      '15': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 76
        }
      },
      '16': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 33
        }
      },
      '17': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 54,
          column: 34
        }
      },
      '18': {
        start: {
          line: 57,
          column: 10
        },
        end: {
          line: 57,
          column: 26
        }
      },
      '19': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 26
        }
      },
      '20': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 20
        }
      },
      '21': {
        start: {
          line: 68,
          column: 2
        },
        end: {
          line: 70,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 71,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'createUserCtrl',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 25
          }
        },
        loc: {
          start: {
            line: 14,
            column: 67
          },
          end: {
            line: 66,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 31,
            column: 22
          },
          end: {
            line: 31,
            column: 23
          }
        },
        loc: {
          start: {
            line: 31,
            column: 30
          },
          end: {
            line: 38,
            column: 5
          }
        },
        line: 31
      },
      '3': {
        name: 'success',
        decl: {
          start: {
            line: 41,
            column: 13
          },
          end: {
            line: 41,
            column: 20
          }
        },
        loc: {
          start: {
            line: 41,
            column: 31
          },
          end: {
            line: 61,
            column: 5
          }
        },
        line: 41
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 45,
            column: 17
          },
          end: {
            line: 45,
            column: 18
          }
        },
        loc: {
          start: {
            line: 45,
            column: 28
          },
          end: {
            line: 52,
            column: 9
          }
        },
        line: 45
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 53,
            column: 14
          },
          end: {
            line: 53,
            column: 15
          }
        },
        loc: {
          start: {
            line: 53,
            column: 20
          },
          end: {
            line: 55,
            column: 9
          }
        },
        line: 53
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 55,
            column: 11
          },
          end: {
            line: 55,
            column: 12
          }
        },
        loc: {
          start: {
            line: 55,
            column: 20
          },
          end: {
            line: 58,
            column: 9
          }
        },
        line: 55
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 58,
            column: 17
          },
          end: {
            line: 58,
            column: 18
          }
        },
        loc: {
          start: {
            line: 58,
            column: 26
          },
          end: {
            line: 60,
            column: 9
          }
        },
        line: 58
      },
      '8': {
        name: 'error',
        decl: {
          start: {
            line: 63,
            column: 13
          },
          end: {
            line: 63,
            column: 18
          }
        },
        loc: {
          start: {
            line: 63,
            column: 24
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 63
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_yxn8jo7y8.s[0]++;
(function () {
  cov_yxn8jo7y8.f[0]++;

  var createUser = (cov_yxn8jo7y8.s[1]++, {
    bindings: {
      currentAuth: '=',
      User: '='
    },
    templateUrl: 'app/users/create-user/create-user.html',
    controller: createUserCtrl
  });

  cov_yxn8jo7y8.s[2]++;
  createUserCtrl.$inject = ['Users', 'Auth', '$http', '$state', '$log', 'Utils'];

  function createUserCtrl(Users, Auth, $http, $state, $log, Utils) {
    cov_yxn8jo7y8.f[1]++;

    var self = (cov_yxn8jo7y8.s[3]++, this);

    cov_yxn8jo7y8.s[4]++;
    self.user = {
      password: '',
      profile: {
        name: {
          first: '',
          last: ''
        },
        email: ''
      },
      roles: {
        admin: false
      }
    };

    cov_yxn8jo7y8.s[5]++;
    self.createUser = function (user) {
      cov_yxn8jo7y8.f[2]++;

      var body = (cov_yxn8jo7y8.s[6]++, {
        email: user.profile.email,
        password: user.password
      });
      cov_yxn8jo7y8.s[7]++;
      $http.post('api/add-user', body).then(success, error);
    };

    // success callback from firebase function $http
    function success(response) {
      cov_yxn8jo7y8.f[3]++;

      var userData = (cov_yxn8jo7y8.s[8]++, response.data);
      var uid = (cov_yxn8jo7y8.s[9]++, userData.uid);
      cov_yxn8jo7y8.s[10]++;
      Users.getProfile(uid).$loaded(function (userRef) {
        cov_yxn8jo7y8.f[4]++;
        cov_yxn8jo7y8.s[11]++;

        userRef.created = Utils.now();
        cov_yxn8jo7y8.s[12]++;
        userRef.profile = self.user.profile;
        cov_yxn8jo7y8.s[13]++;
        userRef.roles = self.user.roles;
        cov_yxn8jo7y8.s[14]++;
        userRef.profile.username = self.user.profile.name.first.charAt(0) + self.user.profile.name.last;
        cov_yxn8jo7y8.s[15]++;
        userRef.profile.username = userRef.profile.username.toLowerCase();
        cov_yxn8jo7y8.s[16]++;
        return userRef.$save();
      }).then(function () {
        cov_yxn8jo7y8.f[5]++;
        cov_yxn8jo7y8.s[17]++;

        $state.go('users.list');
      }, function (error) {
        cov_yxn8jo7y8.f[6]++;
        cov_yxn8jo7y8.s[18]++;

        // handle error
        $log.log(error);
      }).catch(function (error) {
        cov_yxn8jo7y8.f[7]++;
        cov_yxn8jo7y8.s[19]++;

        $log.log(error);
      });
    }
    // error callback from firebase function $http
    function error(err) {
      cov_yxn8jo7y8.f[8]++;
      cov_yxn8jo7y8.s[20]++;

      $log.log(err);
    }
  }

  cov_yxn8jo7y8.s[21]++;
  angular.module('app').component('createUser', createUser);
})();