'use strict';

var cov_1u71lwtfty = function () {
  var path = '/workdir/src/index.js',
      hash = 'd678524ef8cdd47337339c698d697a7b8f7b4292',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 24,
          column: 15
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1u71lwtfty.s[0]++;
angular.module('app', ['ui.router', 'firebase', 'dc-common', 'dc-user', 'dc-service', 'dc-products', 'ngAnimate', 'ngTouch', 'ngSanitize', 'ngMessages', 'ngPassword', 'ng-currency', 'angularMoment', 'angular-sortable-view', 'ngResponsiveBreakpoints', 'thatisuday.dropzone']).constant('appConfig', {
  clientID: 'divcon'
}).constant('Modernizr', Modernizr);